import React from 'react';
import '../editparentdetails/editparentdetails.component.css';
import CatDog from '../../assets/user.png';
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
let usertoken = readCookie('token');

export default class EditParentDetails extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            imageUrl:null,
            parent_imagelocation:null,
            parent_imagename:null,
            selectedImage:null,
            tag_number:this.props.match.params.tag_number,
            parent_firstname:"",
            parent_lastname:"",
            parent_phone:"",
            parent_phonecode:"",
            parent_altphone:"",
            parent_altphonecode:"",
            parent_email:"",
            parent_altemail:"",
            parent_address:"",
            parent_country:"",
            parent_state:"",
            parent_city:"",
            parent_pincode:"",
            parent_gender:"",
            message:"",
            redirecttomedicalpage:false
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(){
        $("#profile-image-uploadActi").on('click',function(){
            $("#choose-profile-imageActi").trigger('click');
        });

        $("#profile-image-uploadActi-1").on('click',function(){
            $("#choose-profile-imageActi").trigger('click');
        });

        axios.get('https://dashboard.tag8.co.in/api/pet/getPetParentDetails/'+this.props.match.params.tag_number).then(response =>{
            this.setState({
                uid: response.data.uid,
                parent_imagelocation: response.data.parent_imagelocation,
                parent_imagename:response.data.parent_imagename,
                tag_number:response.data.tag_number,
                parent_firstname:response.data.parent_firstname,
                parent_lastname:response.data.parent_lastname,
                parent_phone:response.data.parent_phone,
                parent_phonecode:response.data.parent_phonecode,
                parent_altphone:response.data.parent_altphone,
                parent_altphonecode:response.data.parent_altphonecode,
                parent_email:response.data.parent_email,
                parent_altemail:response.data.parent_altemail,
                parent_address:response.data.parent_address,
                parent_country:response.data.parent_country,
                parent_state:response.data.parent_state,
                parent_city:response.data.parent_city,
                parent_pincode:response.data.parent_pincode,
                parent_gender:response.data.parent_gender
            })
        }).catch(error =>{
            console.log(error)
        })

        axios.get('https://dashboard.tag8.co.in/api/pet/countMedicalDetails/'+this.props.match.params.tag_number).then(response =>{
            let count = response.data
            if(count.Vaccination === 0 && count.Allergy === 0 && count.Surgery === 0 && count.Medication === 0 && count.Medicalcondition === 0 && count.Measurement === 0){
                this.setState({redirecttomedicalpage:true})
            }
            else{
                this.setState({redirecttomedicalpage:false})
            }
        })
    }

    handleChange(event) {
        if(event.target.files[0]){
            var sizeinmb = event.target.files[0].size/(1024*1024)
            console.log(sizeinmb)
            if(sizeinmb > 2){
                this.setState({message:'File size should be less than 2 MB'})
            }
            else{
                this.setState({message:''})
                this.setState({
                    selectedImage: event.target.files[0],
                    imageUrl: URL.createObjectURL(event.target.files[0])
                })
            }
        }
    }

    
    snackbarClose = (event) => {
        this.setState({ snackbaropen: false })
         }

    changeHandler =e=>{
        this.setState({[e.target.name]:e.target.value})
    }

    deleteimage=e=>{
        axios.post('https://dashboard.tag8.co.in/api/pet/deleteimage',this.state).then(response =>{
            console.log(response)
            if(response.data === 'Parent Image removed'){
                this.setState({imageUrl:CatDog,parent_imagelocation:null,parent_imagename:null,})
            }
            else if(response.data === 'wrong data'){
                this.setState({snackbaropen:true,snackbarmsg:'Image could not be deleted'})
            }
        })
        .catch(error =>{
            this.setState({snackbaropen:true,snackbarmsg:'Unsuccessful'})
        })
    }

    submitHandler = e=>{
        e.preventDefault()
        console.log(this.state)
        axios.post("https://dashboard.tag8.co.in/api/pet/parentdetails/"+this.props.match.params.tag_number, this.state, {headers: {'Authorization': usertoken}})
        .then(response =>{
            if(response.data === 'Pet Parent Details Updated'){
                this.state.redirecttomedicalpage ? window.location.href='/medical-dashboard/'+this.props.match.params.tag_number :
                window.location.href='/dashboard/'+localStorage.getItem('uid')
                this.setState({snackbaropen:true,snackbarmsg:'Pet Parent Details Updated'})
                const fd = new FormData;
                fd.append('image',this.state.selectedImage)
                fd.append('tag_number',this.props.match.params.tag_number)
                axios.post('https://dashboard.tag8.co.in/api/pet/parentimage-upload',fd).then((res)=>{
                    console.log(res)
                    this.setState({snackbaropen:true,snackbarmsg:'Pet Parent Details Updated'})
                }).catch((error)=>{
                    console.log(error)
                })
            }
            else if(response.data === 'wrong data'){
                this.setState({snackbaropen:true,snackbarmsg:'Unsuccessful'})
            }
        })
        .catch(error =>{
            this.setState({snackbaropen:true,snackbarmsg:'Unsuccessful'})
        })
    }

    render(){
        const tagn = this.props.match.params.tag_number
        const {parent_imagelocation, imageUrl, parent_gender, parent_imagename, uid, tag_number, parent_firstname, parent_lastname, parent_phone, parent_phonecode, parent_altphone, parent_altphonecode, parent_email, parent_altemail, parent_address, parent_country, parent_state, parent_city, parent_pincode} = this.state
        return(
            <div className="background py-5 bg-warning" style={{height:'100vh'}}>
                <div className="row">
                    <div className="col-xl-3 col-lg mt-3 pt-5 mb-5 bg-dark dashboard__blacksidenav" style={{height:'90vh'}}>
                        <div className="ml-5" style={{height:120,width:120,textAlign:'center'}}>
                            <img alt="" src={CatDog}  className="image" />
                            <div className="overlay text-center">
                                <button className="button-image" id="profile-image-uploadActi"><i className="fa fa-edit fa-2x"></i></button>
                                <button className="text-white button-image" style={{position:'relative'}} onClick={this.deleteimage}><i className="fa fa-trash fa-2x"></i></button>
                            </div>
                            <input type="file" onChange={this.handleChange} id="choose-profile-imageActi"/>
                            <img alt="" id="uploadPreviewActi" src={imageUrl}/>
                        </div>
                        <div className="text-left ml-5 mt-2 navigate">
                            <button className="button-image text-white" id="profile-image-uploadActi-1">Add Image<i className="fa fa-plus ml-3"></i></button>
                        </div>
                        <div className="text-white text-left ml-5 mt-3">{this.state.message}</div>
                        <div className="row petparentlinks">
                            <div className="col text-left ml-5" style={{margin:'auto'}}>
                                <ul className="mt-5 text-left pd-leftside-navigation">
                                    <li>
                                        <a href={`https://dashboard.tag8.co.in/dashboard/`+localStorage.getItem('uid')}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>My Tags</Button></a><br/><br/>
                                    </li>
                                    <li>
                                        <a href={`/calendar/`+localStorage.getItem('uid')}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Calendar</Button></a><br/><br/>
                                    </li>
                                    <li>
                                        <a href={`/pet-details/${tagn}`}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Tracker Details</Button></a><br/><br/>
                                    </li>
                                    {/* <li>
                                        <a href={`/medical-dashboard/${tagn}`}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Pet Medical Details</Button></a><br/><br/>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg">
                        <div className="container body-login bg-white mb-5">
                            <div className="row" id="pet-details-fill-2">
                                <div className="col px-3 py-4">
                                    <div className="row text-center px-4">
                                        <div className="col row-underline">
                                            <p><strong>Owner Details</strong></p>
                                        </div>
                                    </div>
                                    <div className="mx-4 mt-4 mb-3">
                                        <form className="edit-parent" onSubmit={this.submitHandler}>
                                            <div className="row" hidden>
                                                <div className="col">
                                                    <span className="label-petdata">Owner ID</span><br/>
                                                    <input type="textbox" className="input-pet-details" value={tag_number} name="tag_number" readOnly/><br/>
                                                </div><br/>
                                            </div><br/>
                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Owners First Name *</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_firstname" value={parent_firstname} onChange={this.changeHandler} required/>
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Owners Last Name *</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_lastname" value={parent_lastname} onChange={this.changeHandler} required />
                                                </div><br/>
                                            </div><br/>

                                            {/* <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Gender *</span><br/>
                                                        <RadioGroup aria-label="parent_gender" name="parent_gender" value={parent_gender} onChange={this.changeHandler} required row>
                                                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                                                        </RadioGroup>
                                                </div><br/>
                                            </div><br/> */}

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Country Code *</span><br/>
                                                    <select className="input-pet-details-dropdown" name="parent_phonecode" value={parent_phonecode} onChange={this.changeHandler} required>
                                                        <option></option>
                                                        <option value="India [ +91 ]">India [ +91 ]</option>
                                                        <option value="Afghanistan [ +93 ]">Afghanistan [ +93 ]</option>
                                                        <option value="Albania [ +355 ]">Albania [ +355 ]</option>
                                                        <option value="Algeria [ +213 ]">Algeria [ +213 ]</option>
                                                        <option value="American Samoa [ +684 ]">American Samoa [ +684 ]</option>
                                                        <option value="Andorra [ +376 ]">Andorra [ +376 ]</option>
                                                        <option value="Angola [ +244 ]">Angola [ +244 ]</option>
                                                        <option value="Anguilla [ +264 ]">Anguilla [ +264 ]</option>
                                                        <option value="Antarctica [ +672 ]">Antarctica [ +672 ]</option>
                                                        <option value="Antigua and Barbuda [ +268 ]">Antigua and Barbuda [ +268 ]</option>
                                                        <option value="Argentina [ +54 ]">Argentina [ +54 ]</option>
                                                        <option value="Armenia [ +374 ]">Armenia [ +374 ]</option>
                                                        <option value="Aruba [ +297 ]">Aruba [ +297 ]</option>
                                                        <option value="Australia [ +61 ]">Australia [ +61 ]</option>
                                                        <option value="Austria [ +43 ]">Austria [ +43 ]</option>
                                                        <option value="Azerbaijan [ +994 ]">Azerbaijan [ +994 ]</option>
                                                        <option value="Bahamas [ +242 ]">Bahamas [ +242 ]</option>
                                                        <option value="Bahrain [ +973 ]">Bahrain [ +973 ]</option>
                                                        <option value="Bangladesh [ +880 ]">Bangladesh [ +880 ]</option>
                                                        <option value="Barbados [ +246 ]">Barbados [ +246 ]</option>
                                                        <option value="Belarus [ +375 ]">Belarus [ +375 ]</option>
                                                        <option value="Belgium [ +32 ]">Belgium [ +32 ]</option>
                                                        <option value="Belize [ +501 ]">Belize [ +501 ]</option>
                                                        <option value="Benin [ +229 ]">Benin [ +229 ]</option>
                                                        <option value="Bermuda [ +441 ]">Bermuda [ +441 ]</option>
                                                        <option value="Bhutan [ +975 ]">Bhutan [ +975 ]</option>
                                                        <option value="Bolivia [ +591 ]">Bolivia [ +591 ]</option>
                                                        <option value="Bosnia and Herzegovina [ +387 ]">Bosnia and Herzegovina [ +387 ]</option>
                                                        <option value="Botswana [ +267 ]">Botswana [ +267 ]</option>
                                                        <option value="Brazil [ +55 ]">Brazil [ +55 ]</option>
                                                        <option value="British Indian Ocean Territory [ +246 ]">British Indian Ocean Territory [ +246 ]</option>
                                                        <option value="Brunei [ +673 ]">Brunei [ +673 ]</option>
                                                        <option value="Bulgaria [ +359 ]">Bulgaria [ +359 ]</option>
                                                        <option value="Burkina Faso [ +226 ]">Burkina Faso [ +226 ]</option>
                                                        <option value="Burundi [ +257 ]">Burundi [ +257 ]</option>
                                                        <option value="Cambodia [ +855 ]">Cambodia [ +855 ]</option>
                                                        <option value="Cameroon [ +237 ]">Cameroon [ +237 ]</option>
                                                        <option value="Canada [ +1 ]">Canada [ +1 ]</option>
                                                        <option value="Cape Verde [ +238 ]">Cape Verde [ +238 ]</option>
                                                        <option value="Cayman Islands [ +345 ]">Cayman Islands [ +345 ]</option>
                                                        <option value="Central African Republic [ +236 ]">Central African Republic [ +236 ]</option>
                                                        <option value="Chad [ +235 ]">Chad [ +235 ]</option>
                                                        <option value="Chile [ +56 ]">Chile [ +56 ]</option>
                                                        <option value="China [ +86 ]">China [ +86 ]</option>
                                                        <option value="Christmas Island [ +53 ]">Christmas Island [ +53 ]</option>
                                                        <option value="Cocos Islands [ +61 ]">Cocos Islands [ +61 ]</option>
                                                        <option value="Colombia [ +57 ]">Colombia [ +57 ]</option>
                                                        <option value="Comoros [ +269 ]">Comoros [ +269 ]</option>
                                                        <option value="Congo [ +243 ]">Congo [ +243 ]</option>
                                                        <option value="Congo [ +242 ]">Congo [ +242 ]</option>
                                                        <option value="Cook Islands [ +682 ]">Cook Islands [ +682 ]</option>
                                                        <option value="Costa Rica [ +506 ]">Costa Rica [ +506 ]</option>
                                                        <option value="Cote DIvoire [ +225 ]">Cote DIvoire [ +225 ]</option>
                                                        <option value="Croatia [ +385 ]">Croatia [ +385 ]</option>
                                                        <option value="Cuba [ +53 ]">Cuba [ +53 ]</option>
                                                        <option value="Cyprus [ +357 ]">Cyprus [ +357 ]</option>
                                                        <option value="Czech Republic [ +420 ]">Czech Republic [ +420 ]</option>
                                                        <option value="Czechoslavakia [ +420 ]">Czechoslavakia [ +420 ]</option>
                                                        <option value="Denmark [ +45 ]">Denmark [ +45 ]</option>
                                                        <option value="Djibouti [ +253 ]">Djibouti [ +253 ]</option>
                                                        <option value="Dominica [ +767 ]">Dominica [ +767 ]</option>
                                                        <option value="Dominican Republic [ +1 ]">Dominican Republic [ +1 ]</option>
                                                        <option value="East Timor [ +670 ]">East Timor [ +670 ]</option>
                                                        <option value="Ecuador [ +593 ]">Ecuador [ +593 ]</option>
                                                        <option value="Egypt [ +20 ]">Egypt [ +20 ]</option>
                                                        <option value="El Salvador [ +503 ]">El Salvador [ +503 ]</option>
                                                        <option value="Equatorial Guinea [ +240 ]">Equatorial Guinea [ +240 ]</option>
                                                        <option value="Eritrea [ +291 ]">Eritrea [ +291 ]</option>
                                                        <option value="Estonia [ +372 ]">Estonia [ +372 ]</option>
                                                        <option value="Ethiopia [ +251 ]">Ethiopia [ +251 ]</option>
                                                        <option value="Falkland Islands [ +500 ]">Falkland Islands [ +500 ]</option>
                                                        <option value="Faroe Islands [ +298 ]">Faroe Islands [ +298 ]</option>
                                                        <option value="Fiji [ +679 ]">Fiji [ +679 ]</option>
                                                        <option value="Finland [ +358 ]">Finland [ +358 ]</option>
                                                        <option value="France [ +33 ]">France [ +33 ]</option>
                                                        <option value="French Guiana [ +594 ]">French Guiana [ +594 ]</option>
                                                        <option value="French Polynesia [ +689 ]">French Polynesia [ +689 ]</option>
                                                        <option value="Gabon [ +241 ]">Gabon [ +241 ]</option>
                                                        <option value="Gambia [ +220 ]">Gambia [ +220 ]</option>
                                                        <option value="Georgia [ +995 ]">Georgia [ +995 ]</option>
                                                        <option value="Germany [ +49 ]">Germany [ +49 ]</option>
                                                        <option value="Ghana [ +233 ]">Ghana [ +233 ]</option>
                                                        <option value="Gibraltar [ +350 ]">Gibraltar [ +350 ]</option>
                                                        <option value="Greece [ +30 ]">Greece [ +30 ]</option>
                                                        <option value="Greenland [ +299 ]">Greenland [ +299 ]</option>
                                                        <option value="Grenada [ +473 ]">Grenada [ +473 ]</option>
                                                        <option value="Guadeloupe [ +590 ]">Guadeloupe [ +590 ]</option>
                                                        <option value="Guam [ +671 ]">Guam [ +671 ]</option>
                                                        <option value="Guatemala [ +502 ]">Guatemala [ +502 ]</option>
                                                        <option value="Guinea [ +224 ]">Guinea [ +224 ]</option>
                                                        <option value="Guinea-Bissau [ +245 ]">Guinea-Bissau [ +245 ]</option>
                                                        <option value="Guyana [ +592 ]">Guyana [ +592 ]</option>
                                                        <option value="Haiti [ +509 ]">Haiti [ +509 ]</option>
                                                        <option value="Honduras [ +504 ]">Honduras [ +504 ]</option>
                                                        <option value="Hong Kong [ +852 ]">Hong Kong [ +852 ]</option>
                                                        <option value="Hungary [ +36 ]">Hungary [ +36 ]</option>
                                                        <option value="Iceland [ +354 ]">Iceland [ +354 ]</option>
                                                        <option value="Indonesia [ +62 ]">Indonesia [ +62 ]</option>
                                                        <option value="Iran [ +98 ]">Iran [ +98 ]</option>
                                                        <option value="Iraq [ +964 ]">Iraq [ +964 ]</option>
                                                        <option value="Ireland [ +353 ]">Ireland [ +353 ]</option>
                                                        <option value="Israel [ +972 ]">Israel [ +972 ]</option>
                                                        <option value="Italy [ +39 ]">Italy [ +39 ]</option>
                                                        <option value="Jamaica [ +876 ]">Jamaica [ +876 ]</option>
                                                        <option value="Japan [ +81 ]">Japan [ +81 ]</option>
                                                        <option value="Jordan [ +962 ]">Jordan [ +962 ]</option>
                                                        <option value="Kazakstan [ +7 ]">Kazakstan [ +7 ]</option>
                                                        <option value="Kenya [ +254 ]">Kenya [ +254 ]</option>
                                                        <option value="Kiribati [ +686 ]">Kiribati [ +686 ]</option>
                                                        <option value="Korea [ +850 ]">Korea [ +850 ]</option>
                                                        <option value="Korea [ +82 ]">Korea [ +82 ]</option>
                                                        <option value="Kuwait [ +965 ]">Kuwait [ +965 ]</option>
                                                        <option value="Kyrgyzstan [ +996 ]">Kyrgyzstan [ +996 ]</option>
                                                        <option value="Laos [ +856 ]">Laos [ +856 ]</option>
                                                        <option value="Latvia [ +371 ]">Latvia [ +371 ]</option>
                                                        <option value="Lebanon [ +961 ]">Lebanon [ +961 ]</option>
                                                        <option value="Lesotho [ +266 ]">Lesotho [ +266 ]</option>
                                                        <option value="Liberia [ +231 ]">Liberia [ +231 ]</option>
                                                        <option value="Libya [ +218 ]">Libya [ +218 ]</option>
                                                        <option value="Liechtenstein [ +423 ]">Liechtenstein [ +423 ]</option>
                                                        <option value="Lithuania [ +370 ]">Lithuania [ +370 ]</option>
                                                        <option value="Luxembourg [ +352 ]">Luxembourg [ +352 ]</option>
                                                        <option value="Macau [ +853 ]">Macau [ +853 ]</option>
                                                        <option value="Macedonia [ +389 ]">Macedonia [ +389 ]</option>
                                                        <option value="Madagascar [ +261 ]">Madagascar [ +261 ]</option>
                                                        <option value="Malawi [ +265 ]">Malawi [ +265 ]</option>
                                                        <option value="Malaysia [ +60 ]">Malaysia [ +60 ]</option>
                                                        <option value="Maldives [ +960 ]">Maldives [ +960 ]</option>
                                                        <option value="Mali [ +223 ]">Mali [ +223 ]</option>
                                                        <option value="Malta [ +356 ]">Malta [ +356 ]</option>
                                                        <option value="Marshall Islands [ +692 ]">Marshall Islands [ +692 ]</option>
                                                        <option value="Martinique [ +596 ]">Martinique [ +596 ]</option>
                                                        <option value="Mauritania [ +222 ]">Mauritania [ +222 ]</option>
                                                        <option value="Mauritius [ +230 ]">Mauritius [ +230 ]</option>
                                                        <option value="Mayotte [ +269 ]">Mayotte [ +269 ]</option>
                                                        <option value="Mexico [ +52 ]">Mexico [ +52 ]</option>
                                                        <option value="Micronesia [ +691 ]">Micronesia [ +691 ]</option>
                                                        <option value="Moldova [ +373 ]">Moldova [ +373 ]</option>
                                                        <option value="Monaco [ +377 ]">Monaco [ +377 ]</option>
                                                        <option value="Mongolia [ +976 ]">Mongolia [ +976 ]</option>
                                                        <option value="Montserrat [ +664 ]">Montserrat [ +664 ]</option>
                                                        <option value="Morocco [ +212 ]">Morocco [ +212 ]</option>
                                                        <option value="Mozambique [ +258 ]">Mozambique [ +258 ]</option>
                                                        <option value="Myanmar [ +95 ]">Myanmar [ +95 ]</option>
                                                        <option value="Namibia [ +264 ]">Namibia [ +264 ]</option>
                                                        <option value="Nauru [ +674 ]">Nauru [ +674 ]</option>
                                                        <option value="Nepal [ +977 ]">Nepal [ +977 ]</option>
                                                        <option value="Netherlands [ +31 ]">Netherlands [ +31 ]</option>
                                                        <option value="Netherlands Antilles [ +599 ]">Netherlands Antilles [ +599 ]</option>
                                                        <option value="New Caledonia [ +687 ]">New Caledonia [ +687 ]</option>
                                                        <option value="New Zealand [ +64 ]">New Zealand [ +64 ]</option>
                                                        <option value="Nicaragua [ +505 ]">Nicaragua [ +505 ]</option>
                                                        <option value="Niger [ +227 ]">Niger [ +227 ]</option>
                                                        <option value="Nigeria [ +234 ]">Nigeria [ +234 ]</option>
                                                        <option value="Niue [ +683 ]">Niue [ +683 ]</option>
                                                        <option value="Norfolk Island [ +672 ]">Norfolk Island [ +672 ]</option>
                                                        <option value="Northern Mariana Islands [ +670 ]">Northern Mariana Islands [ +670 ]</option>
                                                        <option value="Norway [ +47 ]">Norway [ +47 ]</option>
                                                        <option value="Oman [ +968 ]">Oman [ +968 ]</option>
                                                        <option value="Pakistan [ +92 ]">Pakistan [ +92 ]</option>
                                                        <option value="Palau [ +680 ]">Palau [ +680 ]</option>
                                                        <option value="Palestinian State [ +970 ]">Palestinian State [ +970 ]</option>
                                                        <option value="Panama [ +507 ]">Panama [ +507 ]</option>
                                                        <option value="Papua New Guinea [ +675 ]">Papua New Guinea [ +675 ]</option>
                                                        <option value="Paraguay [ +595 ]">Paraguay [ +595 ]</option>
                                                        <option value="Peru [ +51 ]">Peru [ +51 ]</option>
                                                        <option value="Philippines [ +63 ]">Philippines [ +63 ]</option>
                                                        <option value="Pitcairn Island [ +64 ]">Pitcairn Island [ +64 ]</option>
                                                        <option value="Poland [ +48 ]">Poland [ +48 ]</option>
                                                        <option value="Portugal [ +351 ]">Portugal [ +351 ]</option>
                                                        <option value="Puerto Rico [ +1 ]">Puerto Rico [ +1 ]</option>
                                                        <option value="Qatar [ +974 ]">Qatar [ +974 ]</option>
                                                        <option value="Reunion [ +262 ]">Reunion [ +262 ]</option>
                                                        <option value="Romania [ +40 ]">Romania [ +40 ]</option>
                                                        <option value="Russia [ +7 ]">Russia [ +7 ]</option>
                                                        <option value="Russian Federation [ +7 ]">Russian Federation [ +7 ]</option>
                                                        <option value="Rwanda [ +250 ]">Rwanda [ +250 ]</option>
                                                        <option value="Saint Helena [ +290 ]">Saint Helena [ +290 ]</option>
                                                        <option value="Saint Kitts and Nevis [ +869 ]">Saint Kitts and Nevis [ +869 ]</option>
                                                        <option value="Saint Lucia [ +758 ]">Saint Lucia [ +758 ]</option>
                                                        <option value="Saint Pierre and Miquelon [ +508 ]">Saint Pierre and Miquelon [ +508 ]</option>
                                                        <option value="Saint Vincent and the Grenadines [ +784 ]">Saint Vincent and the Grenadines [ +784 ]</option>
                                                        <option value="Samoa [ +685 ]">Samoa [ +685 ]</option>
                                                        <option value="San Marino [ +378 ]">San Marino [ +378 ]</option>
                                                        <option value="Sao Tome and Principe [ +239 ]">Sao Tome and Principe [ +239 ]</option>
                                                        <option value="Saudi Arabia [ +966 ]">Saudi Arabia [ +966 ]</option>
                                                        <option value="Serbia [ +381 ]">Serbia [ +381 ]</option>
                                                        <option value="Senegal [ +221 ]">Senegal [ +221 ]</option>
                                                        <option value="Seychelles [ +248 ]">Seychelles [ +248 ]</option>
                                                        <option value="Sierra Leone [ +232 ]">Sierra Leone [ +232 ]</option>
                                                        <option value="Singapore [ +65 ]">Singapore [ +65 ]</option>
                                                        <option value="Slovakia [ +421 ]">Slovakia [ +421 ]</option>
                                                        <option value="Slovenia [ +386 ]">Slovenia [ +386 ]</option>
                                                        <option value="Solomon Islands [ +677 ]">Solomon Islands [ +677 ]</option>
                                                        <option value="Somalia [ +252 ]">Somalia [ +252 ]</option>
                                                        <option value="South Africa [ +27 ]">South Africa [ +27 ]</option>
                                                        <option value="South Georgia [ +500 ]">South Georgia [ +500 ]</option>
                                                        <option value="Spain [ +34 ]">Spain [ +34 ]</option>
                                                        <option value="Sri Lanka [ +94 ]">Sri Lanka [ +94 ]</option>
                                                        <option value="Sudan [ +249 ]">Sudan [ +249 ]</option>
                                                        <option value="Suriname [ +597 ]">Suriname [ +597 ]</option>
                                                        <option value="Svalbard [ +47 ]">Svalbard [ +47 ]</option>
                                                        <option value="Swaziland [ +268 ]">Swaziland [ +268 ]</option>
                                                        <option value="Sweden [ +46 ]">Sweden [ +46 ]</option>
                                                        <option value="Switzerland [ +41 ]">Switzerland [ +41 ]</option>
                                                        <option value="Syria [ +963 ]">Syria [ +963 ]</option>
                                                        <option value="Taiwan [ +886 ]">Taiwan [ +886 ]</option>
                                                        <option value="Tajikistan [ +992 ]">Tajikistan [ +992 ]</option>
                                                        <option value="Tanzania [ +255 ]">Tanzania [ +255 ]</option>
                                                        <option value="Thailand [ +66 ]">Thailand [ +66 ]</option>
                                                        <option value="Togo [ +228 ]">Togo [ +228 ]</option>
                                                        <option value="Tokelau [ +690 ]">Tokelau [ +690 ]</option>
                                                        <option value="Tonga [ +676 ]">Tonga [ +676 ]</option>
                                                        <option value="Trinidad and Tobago [ +868 ]">Trinidad and Tobago [ +868 ]</option>
                                                        <option value="Tunisia [ +216 ]">Tunisia [ +216 ]</option>
                                                        <option value="Turkey [ +90 ]">Turkey [ +90 ]</option>
                                                        <option value="Turkmenistan [ +993 ]">Turkmenistan [ +993 ]</option>
                                                        <option value="Turks and Caicos Islands [ +649 ]">Turks and Caicos Islands [ +649 ]</option>
                                                        <option value="Tuvalu [ +688 ]">Tuvalu [ +688 ]</option>
                                                        <option value="Uganda [ +256 ]">Uganda [ +256 ]</option>
                                                        <option value="Ukraine [ +380 ]">Ukraine [ +380 ]</option>
                                                        <option value="United Arab Emirates [ +971 ]">United Arab Emirates [ +971 ]</option>
                                                        <option value="United Kingdom [ +44 ]">United Kingdom [ +44 ]</option>
                                                        <option value="United States [ +1 ]">United States [ +1 ]</option>
                                                        <option value="United States Minor Outlying Islands [ +246 ]">United States Minor Outlying Islands [ +246 ]</option>
                                                        <option value="Uruguay [ +598 ]">Uruguay [ +598 ]</option>
                                                        <option value="Uzbekistan [ +998 ]">Uzbekistan [ +998 ]</option>
                                                        <option value="Vanuatu [ +678 ]">Vanuatu [ +678 ]</option>
                                                        <option value="Vatican City [ +418 ]">Vatican City [ +418 ]</option>
                                                        <option value="Venezuela [ +58 ]">Venezuela [ +58 ]</option>
                                                        <option value="Vietnam [ +84 ]">Vietnam [ +84 ]</option>
                                                        <option value="Virgin Islands [ +284 ]">Virgin Islands [ +284 ]</option>
                                                        <option value="Virgin Islands [ +340 ]">Virgin Islands [ +340 ]</option>
                                                        <option value="Wallis and Futuna Islands [ +681 ]">Wallis and Futuna Islands [ +681 ]</option>
                                                        <option value="Western Sahara [ +212 ]">Western Sahara [ +212 ]</option>
                                                        <option value="Yemen [ +967 ]">Yemen [ +967 ]</option>
                                                        <option value="Yugoslavia [ +38 ]">Yugoslavia [ +38 ]</option>
                                                        <option value="Zaire [ +243 ]">Zaire [ +243 ]</option>
                                                        <option value="Zambia [ +260 ]">Zambia [ +260 ]</option>
                                                        <option value="Zimbabwe [ +263 ]">Zimbabwe [ +263 ]</option>
                                                    </select>
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Phone Number *</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_phone" value={parent_phone} onChange={this.changeHandler}  required/>
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Country Code</span><br/>
                                                    <select className="input-pet-details-dropdown" name="parent_altphonecode" value={parent_altphonecode} onChange={this.changeHandler}>
                                                        <option></option>
                                                        <option value="India [ +91 ]">India [ +91 ]</option>
                                                        <option value="Afghanistan [ +93 ]">Afghanistan [ +93 ]</option>
                                                        <option value="Albania [ +355 ]">Albania [ +355 ]</option>
                                                        <option value="Algeria [ +213 ]">Algeria [ +213 ]</option>
                                                        <option value="American Samoa [ +684 ]">American Samoa [ +684 ]</option>
                                                        <option value="Andorra [ +376 ]">Andorra [ +376 ]</option>
                                                        <option value="Angola [ +244 ]">Angola [ +244 ]</option>
                                                        <option value="Anguilla [ +264 ]">Anguilla [ +264 ]</option>
                                                        <option value="Antarctica [ +672 ]">Antarctica [ +672 ]</option>
                                                        <option value="Antigua and Barbuda [ +268 ]">Antigua and Barbuda [ +268 ]</option>
                                                        <option value="Argentina [ +54 ]">Argentina [ +54 ]</option>
                                                        <option value="Armenia [ +374 ]">Armenia [ +374 ]</option>
                                                        <option value="Aruba [ +297 ]">Aruba [ +297 ]</option>
                                                        <option value="Australia [ +61 ]">Australia [ +61 ]</option>
                                                        <option value="Austria [ +43 ]">Austria [ +43 ]</option>
                                                        <option value="Azerbaijan [ +994 ]">Azerbaijan [ +994 ]</option>
                                                        <option value="Bahamas [ +242 ]">Bahamas [ +242 ]</option>
                                                        <option value="Bahrain [ +973 ]">Bahrain [ +973 ]</option>
                                                        <option value="Bangladesh [ +880 ]">Bangladesh [ +880 ]</option>
                                                        <option value="Barbados [ +246 ]">Barbados [ +246 ]</option>
                                                        <option value="Belarus [ +375 ]">Belarus [ +375 ]</option>
                                                        <option value="Belgium [ +32 ]">Belgium [ +32 ]</option>
                                                        <option value="Belize [ +501 ]">Belize [ +501 ]</option>
                                                        <option value="Benin [ +229 ]">Benin [ +229 ]</option>
                                                        <option value="Bermuda [ +441 ]">Bermuda [ +441 ]</option>
                                                        <option value="Bhutan [ +975 ]">Bhutan [ +975 ]</option>
                                                        <option value="Bolivia [ +591 ]">Bolivia [ +591 ]</option>
                                                        <option value="Bosnia and Herzegovina [ +387 ]">Bosnia and Herzegovina [ +387 ]</option>
                                                        <option value="Botswana [ +267 ]">Botswana [ +267 ]</option>
                                                        <option value="Brazil [ +55 ]">Brazil [ +55 ]</option>
                                                        <option value="British Indian Ocean Territory [ +246 ]">British Indian Ocean Territory [ +246 ]</option>
                                                        <option value="Brunei [ +673 ]">Brunei [ +673 ]</option>
                                                        <option value="Bulgaria [ +359 ]">Bulgaria [ +359 ]</option>
                                                        <option value="Burkina Faso [ +226 ]">Burkina Faso [ +226 ]</option>
                                                        <option value="Burundi [ +257 ]">Burundi [ +257 ]</option>
                                                        <option value="Cambodia [ +855 ]">Cambodia [ +855 ]</option>
                                                        <option value="Cameroon [ +237 ]">Cameroon [ +237 ]</option>
                                                        <option value="Canada [ +1 ]">Canada [ +1 ]</option>
                                                        <option value="Cape Verde [ +238 ]">Cape Verde [ +238 ]</option>
                                                        <option value="Cayman Islands [ +345 ]">Cayman Islands [ +345 ]</option>
                                                        <option value="Central African Republic [ +236 ]">Central African Republic [ +236 ]</option>
                                                        <option value="Chad [ +235 ]">Chad [ +235 ]</option>
                                                        <option value="Chile [ +56 ]">Chile [ +56 ]</option>
                                                        <option value="China [ +86 ]">China [ +86 ]</option>
                                                        <option value="Christmas Island [ +53 ]">Christmas Island [ +53 ]</option>
                                                        <option value="Cocos Islands [ +61 ]">Cocos Islands [ +61 ]</option>
                                                        <option value="Colombia [ +57 ]">Colombia [ +57 ]</option>
                                                        <option value="Comoros [ +269 ]">Comoros [ +269 ]</option>
                                                        <option value="Congo [ +243 ]">Congo [ +243 ]</option>
                                                        <option value="Congo [ +242 ]">Congo [ +242 ]</option>
                                                        <option value="Cook Islands [ +682 ]">Cook Islands [ +682 ]</option>
                                                        <option value="Costa Rica [ +506 ]">Costa Rica [ +506 ]</option>
                                                        <option value="Cote DIvoire [ +225 ]">Cote DIvoire [ +225 ]</option>
                                                        <option value="Croatia [ +385 ]">Croatia [ +385 ]</option>
                                                        <option value="Cuba [ +53 ]">Cuba [ +53 ]</option>
                                                        <option value="Cyprus [ +357 ]">Cyprus [ +357 ]</option>
                                                        <option value="Czech Republic [ +420 ]">Czech Republic [ +420 ]</option>
                                                        <option value="Czechoslavakia [ +420 ]">Czechoslavakia [ +420 ]</option>
                                                        <option value="Denmark [ +45 ]">Denmark [ +45 ]</option>
                                                        <option value="Djibouti [ +253 ]">Djibouti [ +253 ]</option>
                                                        <option value="Dominica [ +767 ]">Dominica [ +767 ]</option>
                                                        <option value="Dominican Republic [ +1 ]">Dominican Republic [ +1 ]</option>
                                                        <option value="East Timor [ +670 ]">East Timor [ +670 ]</option>
                                                        <option value="Ecuador [ +593 ]">Ecuador [ +593 ]</option>
                                                        <option value="Egypt [ +20 ]">Egypt [ +20 ]</option>
                                                        <option value="El Salvador [ +503 ]">El Salvador [ +503 ]</option>
                                                        <option value="Equatorial Guinea [ +240 ]">Equatorial Guinea [ +240 ]</option>
                                                        <option value="Eritrea [ +291 ]">Eritrea [ +291 ]</option>
                                                        <option value="Estonia [ +372 ]">Estonia [ +372 ]</option>
                                                        <option value="Ethiopia [ +251 ]">Ethiopia [ +251 ]</option>
                                                        <option value="Falkland Islands [ +500 ]">Falkland Islands [ +500 ]</option>
                                                        <option value="Faroe Islands [ +298 ]">Faroe Islands [ +298 ]</option>
                                                        <option value="Fiji [ +679 ]">Fiji [ +679 ]</option>
                                                        <option value="Finland [ +358 ]">Finland [ +358 ]</option>
                                                        <option value="France [ +33 ]">France [ +33 ]</option>
                                                        <option value="French Guiana [ +594 ]">French Guiana [ +594 ]</option>
                                                        <option value="French Polynesia [ +689 ]">French Polynesia [ +689 ]</option>
                                                        <option value="Gabon [ +241 ]">Gabon [ +241 ]</option>
                                                        <option value="Gambia [ +220 ]">Gambia [ +220 ]</option>
                                                        <option value="Georgia [ +995 ]">Georgia [ +995 ]</option>
                                                        <option value="Germany [ +49 ]">Germany [ +49 ]</option>
                                                        <option value="Ghana [ +233 ]">Ghana [ +233 ]</option>
                                                        <option value="Gibraltar [ +350 ]">Gibraltar [ +350 ]</option>
                                                        <option value="Greece [ +30 ]">Greece [ +30 ]</option>
                                                        <option value="Greenland [ +299 ]">Greenland [ +299 ]</option>
                                                        <option value="Grenada [ +473 ]">Grenada [ +473 ]</option>
                                                        <option value="Guadeloupe [ +590 ]">Guadeloupe [ +590 ]</option>
                                                        <option value="Guam [ +671 ]">Guam [ +671 ]</option>
                                                        <option value="Guatemala [ +502 ]">Guatemala [ +502 ]</option>
                                                        <option value="Guinea [ +224 ]">Guinea [ +224 ]</option>
                                                        <option value="Guinea-Bissau [ +245 ]">Guinea-Bissau [ +245 ]</option>
                                                        <option value="Guyana [ +592 ]">Guyana [ +592 ]</option>
                                                        <option value="Haiti [ +509 ]">Haiti [ +509 ]</option>
                                                        <option value="Honduras [ +504 ]">Honduras [ +504 ]</option>
                                                        <option value="Hong Kong [ +852 ]">Hong Kong [ +852 ]</option>
                                                        <option value="Hungary [ +36 ]">Hungary [ +36 ]</option>
                                                        <option value="Iceland [ +354 ]">Iceland [ +354 ]</option>
                                                        <option value="Indonesia [ +62 ]">Indonesia [ +62 ]</option>
                                                        <option value="Iran [ +98 ]">Iran [ +98 ]</option>
                                                        <option value="Iraq [ +964 ]">Iraq [ +964 ]</option>
                                                        <option value="Ireland [ +353 ]">Ireland [ +353 ]</option>
                                                        <option value="Israel [ +972 ]">Israel [ +972 ]</option>
                                                        <option value="Italy [ +39 ]">Italy [ +39 ]</option>
                                                        <option value="Jamaica [ +876 ]">Jamaica [ +876 ]</option>
                                                        <option value="Japan [ +81 ]">Japan [ +81 ]</option>
                                                        <option value="Jordan [ +962 ]">Jordan [ +962 ]</option>
                                                        <option value="Kazakstan [ +7 ]">Kazakstan [ +7 ]</option>
                                                        <option value="Kenya [ +254 ]">Kenya [ +254 ]</option>
                                                        <option value="Kiribati [ +686 ]">Kiribati [ +686 ]</option>
                                                        <option value="Korea [ +850 ]">Korea [ +850 ]</option>
                                                        <option value="Korea [ +82 ]">Korea [ +82 ]</option>
                                                        <option value="Kuwait [ +965 ]">Kuwait [ +965 ]</option>
                                                        <option value="Kyrgyzstan [ +996 ]">Kyrgyzstan [ +996 ]</option>
                                                        <option value="Laos [ +856 ]">Laos [ +856 ]</option>
                                                        <option value="Latvia [ +371 ]">Latvia [ +371 ]</option>
                                                        <option value="Lebanon [ +961 ]">Lebanon [ +961 ]</option>
                                                        <option value="Lesotho [ +266 ]">Lesotho [ +266 ]</option>
                                                        <option value="Liberia [ +231 ]">Liberia [ +231 ]</option>
                                                        <option value="Libya [ +218 ]">Libya [ +218 ]</option>
                                                        <option value="Liechtenstein [ +423 ]">Liechtenstein [ +423 ]</option>
                                                        <option value="Lithuania [ +370 ]">Lithuania [ +370 ]</option>
                                                        <option value="Luxembourg [ +352 ]">Luxembourg [ +352 ]</option>
                                                        <option value="Macau [ +853 ]">Macau [ +853 ]</option>
                                                        <option value="Macedonia [ +389 ]">Macedonia [ +389 ]</option>
                                                        <option value="Madagascar [ +261 ]">Madagascar [ +261 ]</option>
                                                        <option value="Malawi [ +265 ]">Malawi [ +265 ]</option>
                                                        <option value="Malaysia [ +60 ]">Malaysia [ +60 ]</option>
                                                        <option value="Maldives [ +960 ]">Maldives [ +960 ]</option>
                                                        <option value="Mali [ +223 ]">Mali [ +223 ]</option>
                                                        <option value="Malta [ +356 ]">Malta [ +356 ]</option>
                                                        <option value="Marshall Islands [ +692 ]">Marshall Islands [ +692 ]</option>
                                                        <option value="Martinique [ +596 ]">Martinique [ +596 ]</option>
                                                        <option value="Mauritania [ +222 ]">Mauritania [ +222 ]</option>
                                                        <option value="Mauritius [ +230 ]">Mauritius [ +230 ]</option>
                                                        <option value="Mayotte [ +269 ]">Mayotte [ +269 ]</option>
                                                        <option value="Mexico [ +52 ]">Mexico [ +52 ]</option>
                                                        <option value="Micronesia [ +691 ]">Micronesia [ +691 ]</option>
                                                        <option value="Moldova [ +373 ]">Moldova [ +373 ]</option>
                                                        <option value="Monaco [ +377 ]">Monaco [ +377 ]</option>
                                                        <option value="Mongolia [ +976 ]">Mongolia [ +976 ]</option>
                                                        <option value="Montserrat [ +664 ]">Montserrat [ +664 ]</option>
                                                        <option value="Morocco [ +212 ]">Morocco [ +212 ]</option>
                                                        <option value="Mozambique [ +258 ]">Mozambique [ +258 ]</option>
                                                        <option value="Myanmar [ +95 ]">Myanmar [ +95 ]</option>
                                                        <option value="Namibia [ +264 ]">Namibia [ +264 ]</option>
                                                        <option value="Nauru [ +674 ]">Nauru [ +674 ]</option>
                                                        <option value="Nepal [ +977 ]">Nepal [ +977 ]</option>
                                                        <option value="Netherlands [ +31 ]">Netherlands [ +31 ]</option>
                                                        <option value="Netherlands Antilles [ +599 ]">Netherlands Antilles [ +599 ]</option>
                                                        <option value="New Caledonia [ +687 ]">New Caledonia [ +687 ]</option>
                                                        <option value="New Zealand [ +64 ]">New Zealand [ +64 ]</option>
                                                        <option value="Nicaragua [ +505 ]">Nicaragua [ +505 ]</option>
                                                        <option value="Niger [ +227 ]">Niger [ +227 ]</option>
                                                        <option value="Nigeria [ +234 ]">Nigeria [ +234 ]</option>
                                                        <option value="Niue [ +683 ]">Niue [ +683 ]</option>
                                                        <option value="Norfolk Island [ +672 ]">Norfolk Island [ +672 ]</option>
                                                        <option value="Northern Mariana Islands [ +670 ]">Northern Mariana Islands [ +670 ]</option>
                                                        <option value="Norway [ +47 ]">Norway [ +47 ]</option>
                                                        <option value="Oman [ +968 ]">Oman [ +968 ]</option>
                                                        <option value="Pakistan [ +92 ]">Pakistan [ +92 ]</option>
                                                        <option value="Palau [ +680 ]">Palau [ +680 ]</option>
                                                        <option value="Palestinian State [ +970 ]">Palestinian State [ +970 ]</option>
                                                        <option value="Panama [ +507 ]">Panama [ +507 ]</option>
                                                        <option value="Papua New Guinea [ +675 ]">Papua New Guinea [ +675 ]</option>
                                                        <option value="Paraguay [ +595 ]">Paraguay [ +595 ]</option>
                                                        <option value="Peru [ +51 ]">Peru [ +51 ]</option>
                                                        <option value="Philippines [ +63 ]">Philippines [ +63 ]</option>
                                                        <option value="Pitcairn Island [ +64 ]">Pitcairn Island [ +64 ]</option>
                                                        <option value="Poland [ +48 ]">Poland [ +48 ]</option>
                                                        <option value="Portugal [ +351 ]">Portugal [ +351 ]</option>
                                                        <option value="Puerto Rico [ +1 ]">Puerto Rico [ +1 ]</option>
                                                        <option value="Qatar [ +974 ]">Qatar [ +974 ]</option>
                                                        <option value="Reunion [ +262 ]">Reunion [ +262 ]</option>
                                                        <option value="Romania [ +40 ]">Romania [ +40 ]</option>
                                                        <option value="Russia [ +7 ]">Russia [ +7 ]</option>
                                                        <option value="Russian Federation [ +7 ]">Russian Federation [ +7 ]</option>
                                                        <option value="Rwanda [ +250 ]">Rwanda [ +250 ]</option>
                                                        <option value="Saint Helena [ +290 ]">Saint Helena [ +290 ]</option>
                                                        <option value="Saint Kitts and Nevis [ +869 ]">Saint Kitts and Nevis [ +869 ]</option>
                                                        <option value="Saint Lucia [ +758 ]">Saint Lucia [ +758 ]</option>
                                                        <option value="Saint Pierre and Miquelon [ +508 ]">Saint Pierre and Miquelon [ +508 ]</option>
                                                        <option value="Saint Vincent and the Grenadines [ +784 ]">Saint Vincent and the Grenadines [ +784 ]</option>
                                                        <option value="Samoa [ +685 ]">Samoa [ +685 ]</option>
                                                        <option value="San Marino [ +378 ]">San Marino [ +378 ]</option>
                                                        <option value="Sao Tome and Principe [ +239 ]">Sao Tome and Principe [ +239 ]</option>
                                                        <option value="Saudi Arabia [ +966 ]">Saudi Arabia [ +966 ]</option>
                                                        <option value="Serbia [ +381 ]">Serbia [ +381 ]</option>
                                                        <option value="Senegal [ +221 ]">Senegal [ +221 ]</option>
                                                        <option value="Seychelles [ +248 ]">Seychelles [ +248 ]</option>
                                                        <option value="Sierra Leone [ +232 ]">Sierra Leone [ +232 ]</option>
                                                        <option value="Singapore [ +65 ]">Singapore [ +65 ]</option>
                                                        <option value="Slovakia [ +421 ]">Slovakia [ +421 ]</option>
                                                        <option value="Slovenia [ +386 ]">Slovenia [ +386 ]</option>
                                                        <option value="Solomon Islands [ +677 ]">Solomon Islands [ +677 ]</option>
                                                        <option value="Somalia [ +252 ]">Somalia [ +252 ]</option>
                                                        <option value="South Africa [ +27 ]">South Africa [ +27 ]</option>
                                                        <option value="South Georgia [ +500 ]">South Georgia [ +500 ]</option>
                                                        <option value="Spain [ +34 ]">Spain [ +34 ]</option>
                                                        <option value="Sri Lanka [ +94 ]">Sri Lanka [ +94 ]</option>
                                                        <option value="Sudan [ +249 ]">Sudan [ +249 ]</option>
                                                        <option value="Suriname [ +597 ]">Suriname [ +597 ]</option>
                                                        <option value="Svalbard [ +47 ]">Svalbard [ +47 ]</option>
                                                        <option value="Swaziland [ +268 ]">Swaziland [ +268 ]</option>
                                                        <option value="Sweden [ +46 ]">Sweden [ +46 ]</option>
                                                        <option value="Switzerland [ +41 ]">Switzerland [ +41 ]</option>
                                                        <option value="Syria [ +963 ]">Syria [ +963 ]</option>
                                                        <option value="Taiwan [ +886 ]">Taiwan [ +886 ]</option>
                                                        <option value="Tajikistan [ +992 ]">Tajikistan [ +992 ]</option>
                                                        <option value="Tanzania [ +255 ]">Tanzania [ +255 ]</option>
                                                        <option value="Thailand [ +66 ]">Thailand [ +66 ]</option>
                                                        <option value="Togo [ +228 ]">Togo [ +228 ]</option>
                                                        <option value="Tokelau [ +690 ]">Tokelau [ +690 ]</option>
                                                        <option value="Tonga [ +676 ]">Tonga [ +676 ]</option>
                                                        <option value="Trinidad and Tobago [ +868 ]">Trinidad and Tobago [ +868 ]</option>
                                                        <option value="Tunisia [ +216 ]">Tunisia [ +216 ]</option>
                                                        <option value="Turkey [ +90 ]">Turkey [ +90 ]</option>
                                                        <option value="Turkmenistan [ +993 ]">Turkmenistan [ +993 ]</option>
                                                        <option value="Turks and Caicos Islands [ +649 ]">Turks and Caicos Islands [ +649 ]</option>
                                                        <option value="Tuvalu [ +688 ]">Tuvalu [ +688 ]</option>
                                                        <option value="Uganda [ +256 ]">Uganda [ +256 ]</option>
                                                        <option value="Ukraine [ +380 ]">Ukraine [ +380 ]</option>
                                                        <option value="United Arab Emirates [ +971 ]">United Arab Emirates [ +971 ]</option>
                                                        <option value="United Kingdom [ +44 ]">United Kingdom [ +44 ]</option>
                                                        <option value="United States [ +1 ]">United States [ +1 ]</option>
                                                        <option value="United States Minor Outlying Islands [ +246 ]">United States Minor Outlying Islands [ +246 ]</option>
                                                        <option value="Uruguay [ +598 ]">Uruguay [ +598 ]</option>
                                                        <option value="Uzbekistan [ +998 ]">Uzbekistan [ +998 ]</option>
                                                        <option value="Vanuatu [ +678 ]">Vanuatu [ +678 ]</option>
                                                        <option value="Vatican City [ +418 ]">Vatican City [ +418 ]</option>
                                                        <option value="Venezuela [ +58 ]">Venezuela [ +58 ]</option>
                                                        <option value="Vietnam [ +84 ]">Vietnam [ +84 ]</option>
                                                        <option value="Virgin Islands [ +284 ]">Virgin Islands [ +284 ]</option>
                                                        <option value="Virgin Islands [ +340 ]">Virgin Islands [ +340 ]</option>
                                                        <option value="Wallis and Futuna Islands [ +681 ]">Wallis and Futuna Islands [ +681 ]</option>
                                                        <option value="Western Sahara [ +212 ]">Western Sahara [ +212 ]</option>
                                                        <option value="Yemen [ +967 ]">Yemen [ +967 ]</option>
                                                        <option value="Yugoslavia [ +38 ]">Yugoslavia [ +38 ]</option>
                                                        <option value="Zaire [ +243 ]">Zaire [ +243 ]</option>
                                                        <option value="Zambia [ +260 ]">Zambia [ +260 ]</option>
                                                        <option value="Zimbabwe [ +263 ]">Zimbabwe [ +263 ]</option>
                                                    </select>
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Altername Phone Number</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_altphone" value={parent_altphone} onChange={this.changeHandler} />
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Email *</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_email" value={parent_email} onChange={this.changeHandler}  required/>
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Altername Email</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_altemail" value={parent_altemail} onChange={this.changeHandler} />
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Country *</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_country" value={parent_country} onChange={this.changeHandler}  required/>
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">State</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_state" value={parent_state} onChange={this.changeHandler} />
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">City</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_city" value={parent_city} onChange={this.changeHandler} />
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Pin Code</span><br/>
                                                    <input type="textbox" className="input-pet-details" name="parent_pincode" value={parent_pincode} onChange={this.changeHandler} />
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Address</span><br/>
                                                    <input type="textbox" className="input-pet-details-1" name="parent_address" value={parent_address} onChange={this.changeHandler} />
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <Button variant="contained" type="submit" style={{width:'100%'}} className="submit-login bg-warning text-white mt-2">{this.state.redirecttomedicalpage ? 'NEXT' : 'SUBMIT'}</Button>
                                                </div>
                                                <div className="col">
                                                    <Button variant="contained" style={{width:'100%'}} className="bg-white submit-login mt-2 cancel-btn" type="button" onClick={()=>{window.location.href="/dashboard/"+localStorage.getItem('uid')}}>CANCEL</Button>
                                                </div>
                                            </div>

                                            <Snackbar anchorOrigin={{vertical: 'bottom',horizontal: 'center' }} open={this.state.snackbaropen} autoHideDuration={8000} onClose={this.snackbarClose} message={this.state.snackbarmsg} action={[
                                            <IconButton arial-label="Close" color="inherit" onClick={this.snackbarClose}>X</IconButton>
                                                ]} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
