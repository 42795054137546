import React from 'react'
import './Compare.css';

function Compare() {
    return (
        <div className="compare">
            <table>
                <tr>
                    <th>Parameters</th>
                    <th>Genie Smart Tag</th>
                    <th>Microchip Implant</th>
                    <th>Traditional Pet Tag</th>
                </tr>
                <tr>
                    <th>Find Missing Pet</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <td>Helps Finder connect with the pet parent through primary contact number</td>
                    <td><i className="fa fa-check-circle"></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-check-circle'></i></td>
                </tr>
                <tr>
                    <td>Provides Finder with alternate contact details, if primary contact is unavailable</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Access the Finder’s GPS location by logging into our website</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Access to emergency information</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>One-click creation of missing pet poster</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Once pet is found, authenticating the pet identity, if necessary</td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <th>Manage Pet Profile Digitally</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <td>Medical history/dietary needs available</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Get important alerts & reminders</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>No need to carry documents to vet/caretaker</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Can be digitally updated with new information</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <th>Other Factors</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <td>Affordable cost (i.e., below Rs. 500) </td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                    <td><i className='fa fa-check-circle'></i></td>
                </tr>
                <tr>
                    <td>Less invasion of privacy</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Durability</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
            </table>
        </div >
    )
}

export default Compare
