import React, { useState } from 'react';
import Compare from '../compare/Compare'
import Award from '../../../assets/400x300.png';
import './Features.css'

function Features() {
    const [feature, setFeature] = useState('flex');
    const [feature1, setFeature1] = useState('none');
    const [feature2, setFeature2] = useState('none');
    const [feature3, setFeature3] = useState('none');
    const [feature4, setFeature4] = useState('none');
    const [feature5, setFeature5] = useState('none');
    const [feature6, setFeature6] = useState('none');
    return (
               <>
             <div className="features row">
             <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                 <img src={Award} style={{marginLeft:'20px'}} className="bestinclass mt-3"></img>
             </div>
             <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                 <div className="features__buttons">
                     <button className="feature-button" onClick={() => setFeature('flex')}>Features</button>
                     <button className="compare-button" onClick={() => setFeature('none')} >Compare</button>
                 </div>
                 <div className="list-of-features" style={{ display: feature }}>

                     <ul className="mt-3" style={{display:'flex',flexDirection:'column'}}>
                         <li onClick={() => setFeature1(feature1 === 'none' ? 'block' : 'none')}>
                             <div className="feature__header">
                                 <i className="fa fa-check-circle list-bullets bullet-btn" />
                                 <h3>Finding missing pet</h3>
                             </div>
                             <div className="feature__description" style={{ display: feature1 }}>
                                 <p>Get instant notification, including Finder GPS co-ordinates, on scan of the QR code on the tag</p>
                             </div>
                         </li>

                         <li onClick={() => setFeature3(feature3 === 'none' ? 'block' : 'none')}>
                             <div className="feature__header">
                                 <i className="fa fa-check-circle list-bullets bullet-btn" />
                                 <h3>Missing pet poster</h3>
                             </div>
                             <div className="feature__description" style={{ display: feature3 }}>
                                 <p>Generate on a click lost pet poster to share with friends and neighbours via social media</p>
                             </div>
                         </li>
                         <li onClick={() => setFeature5(feature5 === 'none' ? 'block' : 'none')}>
                             <div className="feature__header">
                                 <i className="fa fa-check-circle list-bullets bullet-btn" />
                                 <h3>Access emergency information</h3>
                             </div>
                             <div className="feature__description" style={{ display: feature5 }}>
                                 <p> On scan of QR code, access pet's digital profile and pet's medical information </p>
                             </div>
                         </li>
                     </ul>
                     {/* new_left_list */}
                     <ul className="mt-3 list-2" style={{display:'flex',flexDirection:'column'}}>
                         <li onClick={() => setFeature2(feature2 === 'none' ? 'block' : 'none')}>
                             <div className="feature__header">
                                 <i className="fa fa-check-circle list-bullets bullet-btn" />
                                 <h3>Create digital profile</h3>
                             </div>
                             <div className="feature__description" style={{ display: feature2 }}>
                                 <p>Create a full digital profile of your pet in the genie smart tag website including photos, your contact details, vaccinations and behavioural or medical details. The tag can also be temporarily disabled. No need to carry any other physical documentation to vet/caretaker since all relevant information is available on phone </p>
                             </div>
                         </li>
                         <li onClick={() => setFeature4(feature4 === 'none' ? 'block' : 'none')}>
                             <div className="feature__header">
                                 <i className="fa fa-check-circle list-bullets bullet-btn" />
                                 <h3>Important alerts & reminders</h3>
                             </div>
                             <div className="feature__description" style={{ display: feature4 }}>
                                 <p>Set vaccination or deworming alerts and reminders or any other pet related alerts</p>
                             </div>
                         </li>
                         <li onClick={() => setFeature6(feature6 === 'none' ? 'block' : 'none')}>
                             <div className="feature__header">
                                 <i className="fa fa-check-circle list-bullets bullet-btn" />
                             <h3>Multiple contacts</h3>
                             </div>
                             <div className="feature__description" style={{ display: feature6 }}>
                                 <p>Store multiple emergency contact details</p>
                             </div>
                         </li>
                     </ul>
                 </div>
                 <div style={{ display: feature === 'flex' ? 'none' : 'block' }}>
                     <Compare />
                 </div>
             </div>
         </div >
               
               </>
            )
}

export default Features









       