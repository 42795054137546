import React, { useState } from 'react'
import Faq from 'react-faq-component'
import './faq.component.css';
const rows = [
    {
        title: "Why do I need the genie Smart Tag?",
        content: "A pet tag is the primary and a must have, source of identification. Traditional pet tags tend to be noisy and bulky; they fade quickly and are at a risk of getting caught on things or getting damaged or lost. genie Smart Tag overcomes these drawbacks with its permanent grade epoxy tags.   It is the most durable pet tag available anywhere globally.<br><br>"
        +" In addition, the genie Smart Tag can store multiple contact details of the pet parent, the pet’s veterinarian details and medical history, allergies and dietary habits.  You can set vaccination or other routine alerts."+
        "In case your pet goes missing, the Finder simply needs to scan the QR code with any smartphone to access this information; you will simultaneously receive an email that your pet’s tag has been scanned. By logging into our website, you will be able to access the Finder’s GPS location. You can also generate an instant e-poster in case your pet is missing. All of this at a one-time affordable price. To buy, please visit our <a target='_blank' href='https://tag8.in/tag8-store-new/public/products/genieSmartTag.php'>Store</a>"
    },
    {
        title: "How does the genie Smart Tag help recover and/or return lost pets?",
        content: "<div style='display:block'>The Finder scans the QR code on the genie Smart Tag, to directly connect with the pet parent. When the tag is scanned, the pet parent also receives an email with the scanned date, time and the tag number. The GPS location where the scan took place, is available in the notification section on the website. <br><img style='height:auto; width:70%; margin:auto; display:flex' src='https://i.ibb.co/D4SDJMM/faq-image-q2.png' alt='faq-image-q2' border='0'></div>"
    },
    {
        title: "The time shown on the notification is not as per the standard time in my country?",
        content: "<div style='display:block'>genie Smart Tag caters to pet parents across the globe.  For notifications, we use UTC, i.e., Universal Time Coordinated (earlier know as GMT, i.e., Greenwich Mean Time).  Based on your country, you need to add or subtract hours from the stated time.  For instance, India’s standard time is UTC + 5:30 hours. <br><img style='height:auto; width:70%; margin:auto; display:flex' src='https://i.ibb.co/D4SDJMM/faq-image-q2.png' alt='faq-image-q2' border='0'></div>"
    },
    {
        title: "Is there a monthly fee once I have paid for the tag?",
        content: "No, there are no hidden fees, only the one-time cost of purchasing the tag."
    },
    // {
    //     title: "What’s the GPS notification feature?",
    //     content: "When the Finder reports a found pet, we capture the GPS location of the Finder. An email with the scanned date, time and the tag number of the Finder is sent to the pet parent. To receive the GPS location where the scan took place, the pet parent must log into our website."
    // },
    {
        title: "What is a QR code?",
        content: "A QR code, which stands for Quick Response code, is a two-dimensional bar code. In this sense it is similar to the traditional barcodes we see on products, except that it can store a lot more information per unit of area. Any smartphone can read a QR code through the camera lens with a QR code reader app. Once a QR code is scanned, you’re led to a web page or media content, designed exclusively for mobile users. The media content could be text, images, contact details or even videos.<br><br>"+
        "A QR code makes our activities, both at home and at work, convenient. It can be used in our daily life such as in printed fliers, name cards and even payment systems. It can also be used for business purposes at factories and in logistics operations. QR codes have now become an indispensable tool in our lives."
    },
    {
        title: "How does genie utilise QR code?",
        content: "Smartphones feature a standard QR reader app that allows your pet’s Finder to easily scan your pet’s ID tag and get instant access to your contact information.<br><br> "+
        "Your pet’s profile page includes not just your pet’s name, pictures and medical conditions, but also your contact details, such as multiple phone numbers and alternative contacts, so that the Finder can directly contact you.<br><br>"+
        "In the unlikely event that the individual who finds your pet doesn’t own a smartphone, our tags also have a unique Owner ID that will lead them to your pet’s digital profile and your contact details by simply typing our website’s URL address on any internet-connected device, ie, a computer, laptop or tablet, to see the information and contact you immediately."
    },
    {
        title: "Which QR code reader apps should we use?",
        content: "Most QR code reader apps, no matter which platform, should read the genie Smart Tag with no problem. Some popular QR code reader apps available are:<br><br>For iOS: i-nigma QR Code Reader, QR Code Reader and Scanner and QuickMark Barcode Scanner <br><br>For Android: i-nigma Barcode Scanner, QR Droid and QR Code Reader and Scanner"
    },
    {
        title: "Does the genie Smart Tag need to be activated?",
        content: "Yes, it is essential to register the genie Smart Tag in order to activate it."
    },
    {
        title: "How do I register and activate the genie Smart Tag?",
        content: "First, sign up with genie through our website: <br><br><ul className='ulli'><li>Go to the top of the home page, click on sign up, and follow the steps. </li></ul><br>Second, to register, you need to provide your basic information, your pet’s information and your unique Owner ID to tag8:<br><br><ul className='ulli'><li>You need to provide your complete name, country code, phone number and email ID</li><li>You will then be sent an OTP for verification on your email ID</li><li>After registration, you can login to genie with your registered email ID and password</li></ul><br>Once you’ve done that, you will be able to create a profile for your pet in your account through the following steps:<br><br><ul className='ulli'><li>Click on activate tag</li><li>Enter the unique Owner ID on the genie Smart Tag</li><li>Click on activate tag</li><li>You will be redirected to create your pet’s profile</li><li>Select the type of pet (e.g., dog or cat)</li><li>Enter the pet’s information (e.g., breed, colour, etc.)</li><li>Enter your pet’s medical details</li><li>You will be sent an email notifying you that the tag has been activated</li><li>You will be redirected to the dashboard where you can fill in the pet parent’s details and the pet’s medical details</li> </ul>"
    },
    {
        title: "I have not got the tag activation confirmation email, what do I do",
        content: "Please wait for 2 working days from the time of registration to receive a confirmation email for tag activation on your registered email ID. <br><br>"+
        "In case you have not received the email within 2 working days, please check if you put your email address correctly and also your emails under the ‘Spam’ folder. It may be blocked by your email provider. If you would like to have another confirmation email sent, please contact us at support@tag8.in and we will get back to you within 2 working days."
    },
    {
        title: "Do I need to add my pet’s medical records and other information?",
        content: "<div style='display:block'>We recommend you add and regularly update this data.<br><br>The genie Smart Tag can store the pet’s veterinarian details and medical history, allergies and dietary habits, medication and weight. In case your pet goes missing, the Finder simply needs to scan the QR code with any smartphone to access this information. It also does away with the need to carry any other physical documentation to the vet/caretaker, since all relevant information is available on the phone. You can also set vaccination or other routine alerts and reminders (e.g., deworming, medication and grooming).  <br><br><img style='height:100%; width:80%; margin:auto; display:flex' src='https://i.ibb.co/pnWbB6p/faq-image-q11.png'></img></div>"
    },
    {
        title: "My tag can’t be scanned?",
        content: "All our tags are tested for code clarity and operation. Code readers also rely on the ability of your smartphone to get a clear image of the code. Please clean your phone’s camera lens or make sure there is sufficient light. If this does not solve the problem please contact us at support@tag8.in and we will get back to you within 2 working days."
    },
    {
        title: "Why should I use the genie Smart Tag compared to other tags and microchip implants?",
        content: "For a detailed comparison between the benefits of the genie Smart Tag versus traditional tags and microchip implants, refer to the table below: <br><br>\
        <div class='text-left faq-compare'>\
        <table>\
            <tr>\
                <th>Parameters</th>\
                <th>Genie Smart Tag</th>\
                <th>Microchip Implant</th>\
                <th>Traditional Per Tag</th>\
            </tr>\
            <tr>\
                <th>Find Missing Pet</th>\
                <th></th>\
                <th></th>\
                <th></th>\
            </tr>\
            <tr>\
                <td>Helps Finder connect with the pet parent through primary contact number</td>\
                <td><i class='fa fa-check-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
                <td><i class='fa fa-check-circle'></i></td>\
            </tr>\
            <tr>\
                <td>Provides Finder with alternate contact details, if primary contact is unavailable</td>\
                <td><i class='fa fa-check-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
            </tr>\
            <tr>\
                <td>Access the Finder’s GPS location by logging into our website</td>\
                <td><i class='fa fa-check-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
            </tr>\
            <tr>\
                <td>Access to emergency information</td>\
                <td><i class='fa fa-check-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
            </tr>\
            <tr>\
                <td>One-click creation of missing pet poster</td>\
                <td><i class='fa fa-check-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
            </tr>\
            <tr>\
                <td>Once pet is found, authenticating the pet identity, if necessary</td>\
                <td><i class='fa fa-times-circle'></i></td>\
                <td><i class='fa fa-check-circle'></i></td>\
                <td><i class='fa fa-times-circle'></i></td>\
            </tr>\
            <tr>\
                <th>Manage Pet Profile Digitally</th>\
                <th></th>\
                <th></th>\
                <th></th>\
            </tr>\
            <tr>\
                <td>Medical history/dietary needs available</td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
            </tr>\
            <tr>\
                <td>Get important alerts & reminders</td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
            </tr>\
            <tr>\
                <td>No need to carry documents to vet/caretaker</td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
            </tr>\
            <tr>\
                <td>Can be digitally updated with new information</td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
            </tr>\
            <tr>\
                <th>Other Factors</th>\
                <th></th>\
                <th></th>\
                <th></th>\
            </tr>\
            <tr>\
                <td>Affordable cost (i.e., below Rs. 500) </td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
            </tr>\
            <tr>\
                <td>Less invasion of privacy</td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
            </tr>\
            <tr>\
                <td>Durability</td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-check-circle\"></i></td>\
                <td><i class=\"fa fa-times-circle\"></i></td>\
            </tr>\
        </table>\
    </div>"
    },
    {
        title: "What if the Finder doesn't have a smartphone or doesn’t know QR code?",
        content: "Each of the genie Smart Tags comes with a unique Owner ID. If the Finder doesn’t have a smartphone or doesn’t know how to scan a QR code, she or he can simply type the URL address on any internet-connected device (computer, laptop, tablet) to see the information and contact you immediately."
    },
    {
        title: "How many pet profiles can I add to my account?",
        content: "You can add as many pet profiles as you have pets in your account."
    },
    {
        title: "Can I change my pets’ and my information once its registered?",
        content: "Yes, you may change your pets’ and your information as often as you like.<br><br>\
        In fact, as a registered user, we encourage you to sign into and update your account and contact information regularly. You can also add and update your pet’s medical information and schedule alerts. We would urge you to regularly visit our pages on policy and product update. This will allow you to maximise your benefits with us."
    },
    {
        title: "What is a unique Owner ID, where will I find it and what is its relevance?",
        content: "Each genie Smart Tag comes with a unique Owner ID. The Owner ID is a seven-character alphanumeric code (for example, TGUD5MB), which is printed on the tag, just below the QR code. We link your pet with us through this Owner ID."
    },
    {
        title: "I am having problems activating my Owner ID.",
        content: "Your Owner ID should have seven characters in an alphanumeric combination. Make sure the characters are entered correctly. A common occurrence is to enter ‘0’ instead of ‘O’ or ‘1’ instead of ‘I’. If you are still having problems activating your Owner ID, please email us at support@tag8.in or contact our Customer Support Desk at (+91) 9029008248 (on weekdays, between 10 a.m. and 6 p.m.) and we will get back to you within 2 working days."
    },
    {
        title: "How long does it take for the activation of the genie Smart Tag after I have signed up and submitted the necessary details?",
        content: "On registration, the tag gets activated immediately and you can affix it to your pet’s collar. <br><br>\
        You will also receive a confirmation email on your registered email ID. Typically, the email confirmation should reach you within 2 working days from the time of registration. "
    },
    {
        title: "Do I have to replace my pet’s tag each year?",
        content: "No, it does not need to be replaced annually. "
    },
    {
        title: "For how long is the genie Smart Tag valid?",
        content: "The tag is valid for its lifetime."
    },
    {
        title: "What is the warranty on my genie Smart Tag?",
        content: "genie Smart Tag has been manufactured and thoroughly tested to meet strict quality standards.  There is a 1-year warranty for any manufacturing defect, i.e., any fading away of the tag.  tag8 will, on submission of the existing tag, replace the damaged tag with a new one. <br><br>\
        It does not cover normal wear and tear. Consumer-inflicted damage or improper use of the product will void the warranty."
    },
    {
        title: "What if my genie Smart Tag is lost?",
        content: "Don’t worry, lost tags can be easily replaced. Your pet’s information is still safe and active on their online profile page and the replacement tag for them will link right to it. <br><br>\
        If your tag is lost, you may purchase a new tag and link it to your pet’s profile just like you did before.  You can do this by logging into your account and clicking on ‘Replace Tag’. Please note that your old tag ID will be deactivated from our system once you request a replacement."
    },
]



function Faqs() {
    const [data, setData] = useState({
        rows: rows
    });
    const [search, setSearch] = useState(' ');
    function updateSearch(e) {
        const filteredRows = rows.filter(
            (row) => {
                return (row.title.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) !== -1) || (row.content.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) !== -1);
            }
        );
        setSearch(e.target.value);
        setData({ rows: filteredRows })
    }

    return (
        <div className="faqs">
            <h1>Frequently Asked Questions</h1>
            <p>Type keywords to find answers</p>
            <input type="text" value={search} onChange={updateSearch}></input>
            <div className="faqs__padding">
                <Faq data={data} className="faqs_dropdown"
                    styles={{
                        display:'flex',
                        rowTitleTextSize: 'medium',
                        rowTitlePaddingLeft: '700px',
                        rowTitleColor: "#272727",
                        rowContentColor: "rgba(0,0,0,.87)",
                        rowContentTextSize: '14px',
                        rowContentPaddingTop: '10px',
                        rowContentPaddingBottom: '10px',
                        rowContentPaddingLeft: '30px',
                        rowContentPaddingRight: '10px',
                        arrowColor: "gray",
                        innerWidth: '80%',
                    }} />
            </div>
        </div>
    )
}

export default Faqs
