import React, {Component} from 'react';
import { render } from 'react-dom';
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, ViewsDirective, ViewDirective } from '@syncfusion/ej2-react-schedule';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import '../calendar/calendar.component.css';
 import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-schedule/styles/material.css";
export default class Calendar extends Component {
    constructor() {
        super(...arguments);
        this.dataManger = new DataManager({
            url: 'https://dashboard.tag8.co.in/api/pet/getMedicalNotification/'+arguments[0].match.params.uid,
            adaptor: new WebApiAdaptor,
            crossDomain: true
        });
    }
    OnEventRendered(args) { 
        args.element.style.marginLeft = '-15px';
        args.element.style.marginTop = '15px';
        var date1 = new Date(args.data.StartTime);
        var date2 = new Date(Date.now());
        console.log(date1)
        console.log(date2)
        if((date1 - date2) >= 0){
          args.element.style.backgroundColor = 'white';
          args.element.style.color = 'blue';
      }
      else{
          args.element.style.backgroundColor = 'lightgrey';
          args.element.style.color = 'black';
      }
    } 
    render() {
        return (<div className='schedule-control-section mb-5' style={{backgroundColor:'chocolate'}}>
        <div className='control-section'>
          <div className='control-wrapper'>
            <ScheduleComponent className="myschedule" style={{}} currentView="Month" height="900px" eventRendered={this.OnEventRendered} style={{paddingLeft:'0px'}} col eventSettings={{ dataSource: this.dataManger }} readonly={true}>
            <ViewsDirective style={{marginLeft:'0px',backgroundColor:'blue'}} className="myviewdiective">
                <ViewDirective className="innerdirective" option='Month'/>
            </ViewsDirective>
              <Inject services={[Day, Week, WorkWeek, Month, Agenda]}/>
            </ScheduleComponent>
          </div>
        </div>
      </div>);
    }
}