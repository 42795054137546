import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../editpetdetails/editpetdetails.component.css';
// import CatDog from '../../assets/beaconPoster.png';
import Beacon from '../../assets/beaconPoster.png';
import 'font-awesome/css/font-awesome.min.css';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
let usertoken = readCookie('token');

export default class EditPetDetails extends React.Component{
    fileObj = [];
    fileArray = [];
    constructor(props){
        super(props)
        this.onImgChange = this.onImgChange.bind(this);
        this.state = {
            imagesArray: '',
            imageUrl:null,
            pet_imagelocation:null,
            pet_imagename:null,
            selectedImage:null,
            tag_number:this.props.match.params.tag_number,
            tag_status:"active",
            valuable_type:"",
            pet_name:'',
            pet_breed:'',
            pet_color:'',
            pet_gender:'',
            pet_dob:'',
            pet_age:'',
            pet_ident:'',
            pet_microchip:'',
            pet_bloodgrp:'',
            pet_colorother:'',
            pet_breedother:'',
            pet_bloodgrpother:'',
            snackbaropen:false,
            snackbarmsg:'',
            message:'',
            redirecttopetparent:false,
            file: [null]

        }

        this.handleChange = this.handleChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    componentDidMount(){
        $("#profile-image-uploadActi").on('click',function(){
            $("#choose-profile-imageActi").trigger('click');
        });

        $("#profile-image-uploadActi-1").on('click',function(){
            $("#choose-profile-imageActi").trigger('click');
        });

        $("#profile-image-uploadActi-2").on('click',function(){
            $("#choose-profile-imageActi-2").trigger('click');
        });

        axios.get('https://dashboard.tag8.co.in/api/pet/getPetParentDetails/'+this.props.match.params.tag_number).then(response =>{
            this.setState({
                pet_imagelocation: response.data.pet_imagelocation,
                pet_imagename:response.data.pet_imagename,
                uid: response.data.uid,
                tag_number:response.data.tag_number,
                tag_status:response.data.tag_status,
                valuable_type:response.data.valuable_type,
                pet_name:response.data.pet_name,
                pet_breed:response.data.pet_breed,
                pet_color:response.data.pet_color,
                pet_gender:response.data.pet_gender,
                pet_dob:response.data.pet_dob,
                pet_age:response.data.pet_age,
                pet_ident:response.data.pet_ident,
                pet_microchip:response.data.pet_microchip,
                pet_bloodgrp:response.data.pet_bloodgrp,
                pet_colorother:response.data.pet_colorother,
                pet_breedother:response.data.pet_breedother,
                pet_bloodgrpother:response.data.pet_bloodgrpother
            })
            console.log("name",response.data.parent_firstname)
            if((response.data.parent_firstname === undefined || '')){
                this.setState({redirecttopetparent:true})
            }
            else{
                this.setState({redirecttopetparent:false})
            }
        }).catch(error =>{
            console.log(error)
        })
    }

    
      onImgChange(event) {
        this.setState({imagesArray: '',file: [null]})
        this.fileObj = [];
        this.fileArray = [];
        this.setState(
            { 
                imagesArray: [...this.state.imagesArray, ...event.target.files] 
            }
        )
        this.fileObj.push(event.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        }
        this.setState({ file: this.fileArray })
    }

    handleDropdownChange(event){
        this.setState({valuable_type: event.target.value});
    }

    handleChange(event) {
        if(event.target.files[0]){
            var sizeinmb = event.target.files[0].size/(1024*1024)
            console.log(sizeinmb)
            if(sizeinmb > 2){
                this.setState({message:'File size should be less than 2 MB'})
            }
            else{
                this.setState({message:''})
                this.setState({
                    selectedImage: event.target.files[0],
                    imageUrl: URL.createObjectURL(event.target.files[0])
                })
            }
        }
    }

    changeHandlerdate =e=>{
        this.setState({[e.target.name]:e.target.value})
        const birthDate = new Date(e.target.value); 
        const difference = Date.now() - birthDate.getTime();
        const age = new Date(difference);
        const ageyear = Math.abs(age.getUTCFullYear() - 1970);
        this.setState({pet_age : ageyear})
    }
    
    snackbarClose = (event) => {
        this.setState({ snackbaropen: false })
         }

    changeHandler =e=>{
        this.setState({[e.target.name]:e.target.value})
    }

    deleteimage=e=>{
        axios.post('https://dashboard.tag8.co.in/api/pet/deleteimage',this.state).then(response =>{
            console.log(response)
            if(response.data === 'Pet Image removed'){
                this.setState({imageUrl:Beacon,pet_imagelocation:null,pet_imagename:null,})
            }
            else if(response.data === 'wrong data'){
                this.setState({snackbaropen:true,snackbarmsg:'Image could not be deleted'})
            }
        })
        .catch(error =>{
            this.setState({snackbaropen:true,snackbarmsg:'Unsuccessful'})
        })
    }

    submitHandler = e=>{
        e.preventDefault()
        console.log(this.state.imagesArray)
        
        axios.post("https://dashboard.tag8.co.in/api/pet/editpetdetails/"+this.props.match.params.tag_number, this.state, {headers: {'Authorization': usertoken}})
        .then(response =>{
            console.log(response)
            if(response.data === 'Pet Details Updated'){
                this.state.redirecttopetparent ? window.location.href='/pet-parent-details/'+this.props.match.params.tag_number :
                window.location.href='/dashboard/'+localStorage.getItem('uid')
                const fd = new FormData;
                fd.append('image',this.state.selectedImage)
                fd.append('tag_number',this.props.match.params.tag_number)
                axios.post('https://dashboard.tag8.co.in/api/pet/petimage-upload',fd).then((res)=>{
                    console.log(res)
                    this.setState({snackbaropen:true,snackbarmsg:'Pet Details Updated'})
                    let formData = new FormData();

                    for (const key of Object.keys(this.state.imagesArray)) {
                        formData.append('images', this.state.imagesArray[key])
                    }
                    axios.post('https://dashboard.tag8.co.in/api/pet/uploadMultipleImage/'+ this.state.tag_number,formData).then((res)=>{
                        console.log("response",res);
                    }).catch((error)=>{
                        console.log(error);
                    })
                }).catch((error)=>{
                    console.log(error)
                })
            }
            else if(response.data === 'wrong data'){
                this.setState({snackbaropen:true,snackbarmsg:'Unsuccessful'})
            }
        })
        .catch(error =>{
            this.setState({snackbaropen:true,snackbarmsg:'Unsuccessful'})
        })
    }

    render(){

        const {pet_imagelocation, imageUrl, uid, pet_imagename, tag_number, valuable_type, pet_name, pet_breed, pet_color, pet_gender, pet_dob, pet_age, pet_ident, pet_microchip, pet_bloodgrp, pet_colorother, pet_breedother, pet_bloodgrpother} = this.state
        return(
            <div className="background py-5 bg-warning" style={{height:'100vh'}}>
                <div className="row">
                    <div className="col-xl-3 col-lg mt-3 pt-5 bg-dark dashboard__blacksidenav" style={{height:'90vh'}}>
                        <div className="ml-5" style={{height:120,width:120,textAlign:'center'}}>
                            <img alt="" src={Beacon}  className="image" />
                            <div className="overlay text-center">
                                <button className="button-image" id="profile-image-uploadActi"><i className="fa fa-edit fa-2x"></i></button>
                                <button className="text-white button-image" style={{position:'relative'}} onClick={this.deleteimage}><i className="fa fa-trash fa-2x"></i></button>
                            </div>
                            <input className="bg-dark text-white mb-3" id="choose-profile-imageActi-2" type="file" multiple name="imagesArray" onChange={this.onImgChange} />
                            <input type="file" onChange={this.handleChange} id="choose-profile-imageActi"/>
                            <img alt="" id="uploadPreviewActi" src={imageUrl}/>
                        </div>
                        <div className="text-left mt-2">
                            <div className="dropdown hide-on-responsive ml-5 topmargin-responsive mt-2">
                                <Link to="#" className="dropbtn navbar__options">Add Image<i className="fa fa-caret-down ml-2" aria-hidden="true"></i></Link>
                                <div className="dropdown-content-1" style={{marginTop:'0px'}}>
                                    <button className="text-dark bg-white dropdown-item" id="profile-image-uploadActi-1">Add Image<i className="fa fa-plus ml-3"></i></button>
                                    <button className="text-dark bg-white dropdown-item" id="profile-image-uploadActi-2">Add Gallery<i className="fa fa-plus ml-3"></i></button>
                                </div>
                            </div>
                            
                        </div>
                        <div className="text-white text-left ml-4">{this.state.message}</div>  
                        <div className="row petparentlinks">
                            <div className="col text-left ml-5" style={{margin:'auto'}}>
                                <ul className="mt-5 text-left pd-leftside-navigation">
                                    <li>
                                        <a href={`https://dashboard.tag8.co.in/dashboard/`+localStorage.getItem('uid')}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>My Tags</Button></a><br/><br/>
                                    </li>
                                    <li>
                                        <a href={`/calendar/`+localStorage.getItem('uid')}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Calendar</Button></a><br/><br/>
                                    </li>
                                    <li>
                                        <a href={`/pet-parent-details/`+this.props.match.params.tag_number}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Owners Details</Button></a><br/><br/>
                                    </li>
                                    {/* <li>
                                        <a href={`/medical-dashboard/`+this.props.match.params.tag_number}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Pet Medical Details</Button></a><br/><br/>
                                    </li> */}
                                </ul>
                            </div>
                        </div>  
                        <div className="form-group multi-preview">
                            {(this.fileArray || []).map(url => (
                                <img src={url} className="ml-4" alt="..." height="70px" width="70px" className="mr-2"/>
                            ))}
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg">
                        <div className="container body-login bg-white mb-5">
                            <div className="row" id="pet-details-fill-2">
                                <div className="col px-3 py-4 text-left">
                                    <div className="row text-center px-4">
                                        <div className="col row-underline">
                                            <p><strong>Dolphin Tracker Details</strong></p>
                                        </div>
                                    </div>
                                    <div className="mx-4 mt-4 mb-3" style={{textAlign:'left'}}>
                                        <form className="myform" onSubmit={this.submitHandler}>
                                            <div className="row" style={{textAlign:'left'}}>
                                                <div className="col">
                                                    <span className="label-petdata">Owner ID *</span><br/>
                                                    <input type="textbox" className="input-pet-details" value={tag_number} name="tag_number" onChange={this.changeHandler} required readOnly/><br/>
                                                </div><br/><br/>
                                                {/* <div className="col text-left marginheight" id="select-pet-type">
                                                    <span className="label-petdata label-petdata-1">Select Tracker Type *</span><br/>
                                                    <select className="input-pet-details-dropdown text-left" value={valuable_type} onChange={this.handleDropdownChange}>
                                                        <option selected disabled hidden></option>
                                                        <option value="Cat">Dolphin Smart Tracker</option>
                                                        <option value="Dog">Dolphin Smart Padlock</option>
                                                    </select>
                                                </div><br/> */}
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Tracker Name *</span><br/>
                                                    <input type="textbox" className="input-pet-details" maxLength="20" name="pet_name" value={pet_name} onChange={this.changeHandler} required/>
                                                </div><br/><br/>

                                                <div className="col">
                                                    <span className="label-petdata">Color *</span><br/>
                                                    <select  className="input-pet-details-dropdown" name="pet_color" value={pet_color} onChange={this.changeHandler} required>
                                                        <option hidden></option>
                                                        <option value="white">White</option>
                                                        <option value="brown">Brown</option>
                                                        <option value="black">Black</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    {
                                                        pet_color === 'Other' ? <input type="text" className="input-pet-details mt-3 pl-1" maxlength="20" value={pet_colorother} onChange={this.changeHandler} name="pet_colorother"/> : <div></div>
                                                    }
                                                </div><br/><br/>
                                                
                                            </div><br/>


                                            <div className="row">
                                                {/* <div className="col">
                                                    <span className="label-petdata">Color *</span><br/>
                                                    <select  className="input-pet-details-dropdown" name="pet_color" value={pet_color} onChange={this.changeHandler} required>
                                                        <option hidden></option>
                                                        <option value="white">White</option>
                                                        <option value="brown">Brown</option>
                                                        <option value="black">Black</option>
                                                        <option value="grey">Grey</option>
                                                        <option value="silver">Silver</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    {
                                                        pet_color === 'Other' ? <input type="text" className="input-pet-details mt-3 pl-1" maxlength="20" value={pet_colorother} onChange={this.changeHandler} name="pet_colorother"/> : <div></div>
                                                    }
                                                </div><br/><br/> */}

                                            </div><br/>

                                            {/* <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Gender *</span><br/>
                                                    <RadioGroup aria-label="pet_gender" name="pet_gender" value={pet_gender} onChange={this.changeHandler} required row>
                                                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                                                        </RadioGroup>
                                                </div><br/>
                                            </div><br/> */}

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Date of Activation</span><br/>
                                                    <input type="date" className="input-pet-details" value={pet_dob} onChange={this.changeHandlerdate} name="pet_dob"/>
                                                </div><br/>
                                                <div className="col marginheight" id="select-pet-micno">
                                                    <span className="label-petdata label-petdata-1">Microchip Number</span><br/>
                                                    <input type="number" className="input-pet-details" value={pet_microchip} onChange={this.changeHandler} name="pet_microchip"/>
                                                </div><br/>
                                                {/* <div className="col marginheight" id="select-pet-age">
                                                    <span className="label-petdata label-petdata-1">Age</span><br/>
                                                    <input type="number" className="input-pet-details" value={pet_age} onChange={this.changeHandler} name="pet_age"/>
                                                </div><br/> */}
                                            </div><br/>

                                            {/* <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Identification Mark *</span><br/>
                                                    <input type="text" className="input-pet-details" maxLength="20" value={pet_ident} onChange={this.changeHandler} name="pet_ident" required/>
                                                </div><br/>
                                                <div className="col marginheight" id="select-pet-micno">
                                                    <span className="label-petdata label-petdata-1">Microchip Number</span><br/>
                                                    <input type="number" className="input-pet-details" value={pet_microchip} onChange={this.changeHandler} name="pet_microchip"/>
                                                </div><br/>
                                            </div><br/><br/> */}

                                            <div className="row">
                                                <div className="col">
                                                    <Button variant="contained" type="submit" style={{'width': '100%'}} className="submit-login bg-warning text-white mt-2">{this.state.redirecttopetparent ? 'NEXT' : 'SUBMIT'}</Button>
                                                </div>
                                                <div className="col">
                                                    <Button type="button" variant="contained" style={{'width': '100%'}} className="bg-white mt-2 cancel-btn submit-login " onClick={
                                                        ()=>{
                                                            window.location.href="/dashboard/"+localStorage.getItem('uid')
                                                        }
                                                    }>CANCEL</Button>
                                                </div>
                                            </div>
                                            <Snackbar anchorOrigin={{vertical: 'bottom',horizontal: 'center' }} open={this.state.snackbaropen} autoHideDuration={8000} onClose={this.snackbarClose} message={this.state.snackbarmsg} action={[
                                            <IconButton arial-label="Close" color="inherit" onClick={this.snackbarClose}>X</IconButton>
                                            ]} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )
    }
}