import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../petposter/petposter.component.css';
import Logo from '../../assets/genielogofinal.png';
import CatDog from '../../assets/catdog1.jpg'
import Beacon from '../../assets/beaconPoster.png';
import 'font-awesome/css/font-awesome.min.css';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import '../petposter/petposter.component.css';
import axios from 'axios';
import { Link } from "react-router-dom";
import html2canvas from 'html2canvas';
import Navbar from '../header/header.component.jsx';

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie('token');

export default class PetPoster extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tag_number: "",
      tag_status: "",
      uid: "",
      pet_breed: "",
      pet_gender: "",
      pet_age: "",
      pet_color: "",
      address: "",
      valuable_type: "",
      showAge: "",
      pet_ident: "",
      lost_date: "",
      addressloc: "",
      pet_name: "",
      rewardcurrency: "",
      phone_one: "",
      reward: "",
      pet_imagelocation: null,
      pet_imagename: null,
      parent_firstname:'',
      parent_lastname:'',
    };
  }


  downloadposter() {
    let elem = document.getElementById('contentToConvert');
    html2canvas(elem).then(function (canvas) {
      let generatedImage = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      let a = document.createElement('a');
      a.href = generatedImage;
      a.download = `pet-poster.png`;
      a.click();
    });
  }

  componentDidMount() {
    const access_token = usertoken

    const getURL = 'https://dashboard.tag8.co.in/api/pet/getreportlocation/' + this.props.match.params.tag_number;
    axios.get(getURL, {
      headers: {
        'Authorization': access_token,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      this.setState({

        address: response.data.data[0].address
      })
    }).catch(error => {
      console.log(error)
    })
    
    axios.get('https://dashboard.tag8.co.in/api/pet/getPetImagePoster/'+this.props.match.params.tag_number).then(response =>{
      this.setState({
          pet_imagelocation: response.data.pet_imagelocation,
      })
    }).catch(error =>{
        console.log(error)
    })

    const getURL1 = 'https://dashboard.tag8.co.in/api/pet/getLostDetails/' + this.props.match.params.tag_number;
    axios.get(getURL1, {
      headers: {
        'Authorization': usertoken,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      console.log(response)
      this.setState({
        valuable_type:response.data[0].valuable_type,
        pet_breed:response.data[0].pet_breed,
        pet_gender:response.data[0].pet_gender,
        pet_age:response.data[0].pet_age,
        pet_color:response.data[0].pet_color,
        pet_ident:response.data[0].pet_ident,
        pet_name:response.data[0].pet_name,
        lost_date:response.data[0].lost_date,
        phone_one:response.data[0].phone_one,
        reward:response.data[0].reward,
        rewardcurrency:response.data[0].rewardcurrency,
        parent_firstname:response.data[0].parent_firstname,
        parent_lastname:response.data[0].parent_lastname,
      })
    }).catch(error => {
      console.log(error)
    })

  }
  render() {
    const { parent_firstname, parent_lastname, pet_imagelocation, pet_breed, pet_gender, pet_age, pet_color, pet_ident, pet_name, lost_date, phone_one, reward, rewardcurrency, address, valuable_type } = this.state;
    return (
      <div>
        <div className="row text-center pt-4" style={{'width':'60%','marginLeft':'20%'}}>
            <div className="col">
                <Button variant="contained" className="bg-warning postar-res button-styling mt-3" onClick={this.downloadposter} style={{'width':'80%'}}>Download</Button>
            </div>
            <div className="col">
                <a href={"/edit-report-lost/"+this.props.match.params.tag_number} style={{'textDecoration':'none'}}><Button variant="contained" className="bg-warning postar-res button-styling mt-3" style={{'width':'80%'}}>Edit</Button></a>
            </div>
            <div className="col">
                <Button variant="contained" onClick={()=>{window.location.href = "/dashboard/"+localStorage.getItem('uid')}} className="bg-warning postar-res button-styling mt-3" style={{'width':'80%'}}>Go To My Tags</Button>
            </div>
        </div>
        <div id="contentToConvert" className="py-3">
          <Card className="mb-5 poster-mat-card">
            <div className="row text-center">
              {valuable_type ? <p className="header-font lost-valuable header-text mt-5">LOST {valuable_type.toUpperCase()} !</p> :""}
            </div>
            <div className="row">
              <div className="col-md-5">
              {
                  pet_imagelocation ? <img alt="" src={`data:image/png;base64,${pet_imagelocation}`}  className="mat-card-image pt-5"  width="100%" height="400px" /> : <img alt="" className="mat-card-image pt-5" alt="" src={Beacon} width="100%" height="400px" />
              }
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  
                  <div className="col text-center">
                      <p className="header-font lost-info-header">Valuable Name</p>
                      <p className="text-center lost-info-text "></p>
                  </div>
                  
                  <div className="col text-center">
                      <p className="header-font lost-info-header">Valuable Discription</p>
                      <p className="text-center lost-info-text"></p>
                  </div>
                  
                  <div className="col text-center">
                      <p className="header-font lost-info-header">Owner Name</p>
                      <p className="text-center lost-info-text"></p>
                  </div>
                </div>
                <hr />

                <div className="row">
                  
                  <div className="col text-center">
                      <p className="header-font lost-info-header lost-info-header-res">Owner Address</p>
                      <p className="text-center lost-info-text"></p>
                  </div>
                  
                    <div className="col text-center">
                      <p className="header-font lost-info-header lost-info-header-res">Owner Phone No.</p>
                      <p className="text-center lost-info-text"></p>
                  </div>
                </div>
                <hr />

                
                <div className="row">
                  <div className="col text-center">
                  <p className="header-font lost-info-header">LAST SEEN</p>
                  <p className="text-center lost-info-text"></p>
                  <p className="text-center lost-info-text-address"></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-3 text-center answers-to-name mt-3 mb-3">
              <h1 className="answers-to-name-header" style={{ 'wordWrap': 'break-word' }}>ANSWERS TO THE NAME OF</h1>
              <h1 className="answers-to-name-header mt-3" style={{ 'wordWrap': 'break-word' }}>DPT111</h1>
            </div>

            <div className="row text-center mb-4">
              <div className="col-md">
                <p className="bottom-text-1 mt-4">PLEASE</p>
                <p className="bottom-text-1"><span className="bottom-help-text">HELP US &nbsp; </span>FIND</p>
                <p className="bottom-text-1">DPT111</p>
                
                  <div>
                      <p className="answers-to-name-text-1 header-font text-center">Call : Owner Name</p>
                      <p className="answers-to-name-text-1 header-font text-center">9604451209</p>
                  </div> 
                
              </div>
              
                <div className="col-md mt-4">
                  <p className="header-font reward-header bottom-text-res">REWARD</p>
                  <p className="bottom-text bottom-text-res mt-5">Rupees<br/> <br/>1000</p>
                </div> 
            </div>
            <hr />
            <div className="row text-center">
                {/* <img src={Logo} className="mb-3 text-center" width="100px" height="50px" alt="" style={{margin:'auto'}}/> */}
            </div>
          </Card>
        </div>
      </div>
    )
  }
}