import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../medicaldashboard/medicaldashboard.component.css';
import 'font-awesome/css/font-awesome.min.css';
import Vaccination from '../../assets/vaccine.png';
import Allergy from '../../assets/allergy.png';
import Surgery from '../../assets/surgery.png';
import Medication from '../../assets/prescription.png';
import MedicalCondition from '../../assets/heart-rate.png';
import Measurement from '../../assets/weight.png';
import Add from '../../assets/add.png';
import Button from '@material-ui/core/Button';
import CatDog from '../../assets/catdog1.jpg';
import Vaccinationcard from './cards/vaccination.component.jsx';
import Allergycard from './cards/allergy.component.jsx';
import Surgerycard from './cards/surgery.component.jsx';
import Medicationcard from './cards/medication.component.jsx';
import Medicalconditioncard from './cards/medicalcondition.component.jsx';
import Measurementcard from './cards/measurement.component.jsx';
import axios from 'axios';
import AddNewRecord from './add-new-record/addnewrecord.component.jsx';
import Dialog from '@material-ui/core/Dialog';
import is from 'date-fns/esm/locale/is/index.js';
import { FormatListNumberedSharp } from '@material-ui/icons';

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
let usertoken = readCookie('token');

export default class MedicalDashboard extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            AllergyList:[],
            MedicationList:[],
            MedicalConList:[],
            MeasureList:[],
            SurgeryList:[],
            VaccineList:[],
            AllergyDateList:[],
            MedicationDateList:[],
            MedicalConDateList:[],
            MeasureDateList:[],
            SurgeryDateList:[],
            VaccineDateList:[],
            medicaldetails:null,
            vaccinationcount:null,
            allergycount:null,
            surgerycount:null,
            medicationcount:null,
            medicalconditioncount:null,
            measurementcount:null,
            MainPopup :'',
            filteroption:'filter',
            addnewrecord: false,
            displaydefaultmsg:false,
            sortType:'',
            selected:false,
            sortbyAD:false,
            togglebtn:false,
            togglebtnname:false,
            pet_imagelocation:null,
            pet_imagename: null
            
        }
    }

    onNameSort = sortType=>
        {
                this.setState({sortType});
                this.setState({selected:true});
                this.setState({sortbyAD:!this.state.sortbyAD})
                this.setState({togglebtnname:!this.state.togglebtnname})
                
        }

    onAppointSort = sortType=>
        {
                this.setState({sortType});
                this.setState({selected:true});
                this.setState({sortbyAD:!this.state.sortbyAD})
                this.setState({togglebtn:!this.state.togglebtn})
               
        }    

    

    changeHandler =e=>{
        this.setState({[e.target.name]:e.target.value})
        this.setState({selected:false})
    }

    handleCloseRecord=e=>{
        this.setState({addnewrecord:false})
    }

     date_sort_asc = function (date1, date2) {
                 if (date1 > date2) return 1;
                 if (date1 < date2) return -1;
                 return 0;
               };


     
    componentDidMount(){
        
        
        console.log(this.state.filteroption)
        localStorage.setItem('tag_number',this.props.match.params.tag_number)
        console.log(this.props.match.params.tag_number)
        axios.get('https://dashboard.tag8.co.in/api/pet/getMedicalDetails/'+this.props.match.params.tag_number).then(response =>{
            console.log(response)
            response.data.map(res=>{
                                
                this.setState({medicaldetails:res})
                // fOr Allergy---------------------------------
                res.Allergy.sort((a,b)=>{
                    let Aa = a.Allergy_name.toLowerCase();
                    let Ab = b.Allergy_name.toLowerCase();

                    if(Aa < Ab)
                    {
                        return -1;
                    }

                    if(Aa > Ab)
                    {
                        return 1;
                    }
                    return 0;
                })
                this.setState({AllergyList:res.Allergy})
                console.log(this.state.AllergyList)

                // date sorting start

                const temp_AllergyDateList = [];
                res.Allergy.map((res)=>{
                   
                   temp_AllergyDateList.push(res)

                })

                temp_AllergyDateList.map((resposi)=>{
                    resposi.AllergyDiagnosis_date = new Date(resposi.AllergyDiagnosis_date);
                })

                temp_AllergyDateList.map((repd)=>{
                    console.log(repd)
                })


                
    
                temp_AllergyDateList.sort((date1,date2)=>{

                    if (date1.AllergyDiagnosis_date > date2.AllergyDiagnosis_date) return 1;
                    if (date1.AllergyDiagnosis_date < date2.AllergyDiagnosis_date) return -1;
                 return 0;

                })

                this.setState({AllergyDateList:temp_AllergyDateList})


                //date sorting end
            //   Allergy End--------------------------------

            // For Medicaton-------------------------------
                res.Medication.sort((a,b)=>{
                    let Aa = a.Medication_name.toLowerCase();
                    let Ab = b.Medication_name.toLowerCase();



                    if(Aa < Ab)
                    {
                        return -1;
                    }

                    if(Aa > Ab)
                    {
                        return 1;
                    }
                    return 0;
                })
                this.setState({MedicationList:res.Medication})

                
                  // Sorting for date

                const temp_MedicationDateList = [];
                res.Medication.map((res)=>{
                   
                   temp_MedicationDateList.push(res)

                })

                temp_MedicationDateList.map((resposi)=>{
                    resposi.Medication_startdate = new Date(resposi.Medication_startdate);
                })

                temp_MedicationDateList.map((repd)=>{
                    console.log(repd)
                })


                
    
                temp_MedicationDateList.sort((date1,date2)=>{

                    if (date1.Medication_startdate > date2.Medication_startdate) return 1;
                    if (date1.Medication_startdate < date2.Medication_startdate) return -1;
                 return 0;

                })

                this.setState({MedicationDateList:temp_MedicationDateList})

                console.log(this.state.MedicationDateList)


                   // Sort Date end





                  
                //Medication end ------------------------------------  

                // For Medical Condition--------------------------
                res.Medicalcondition.sort((a,b)=>{
                    let Aa = a.MedicalconditionTitle.toLowerCase();
                    let Ab = b.MedicalconditionTitle.toLowerCase();



                    if(Aa < Ab)
                    {
                        return -1;
                    }

                    if(Aa > Ab)
                    {
                        return 1;
                    }
                    return 0;
                })
                this.setState({MedicalConList:res.Medicalcondition})

                const temp_MedicalConDateList = [];
                res.Medicalcondition.map((res)=>{
                   
                    temp_MedicalConDateList.push(res)

                })

                temp_MedicalConDateList.map((resposi)=>{
                    resposi.MedicalconditionDiagnosed_date = new Date(resposi.MedicalconditionDiagnosed_date);
                })

                temp_MedicalConDateList.map((repd)=>{
                    console.log(repd)
                })


                
    
                temp_MedicalConDateList.sort((date1,date2)=>{

                    if (date1.MedicalconditionDiagnosed_date > date2.MedicalconditionDiagnosed_date) return 1;
                    if (date1.MedicalconditionDiagnosed_date < date2.MedicalconditionDiagnosed_date) return -1;
                 return 0;

                })

                this.setState({MedicalConDateList:temp_MedicalConDateList})


                //date sorting end

                // For Medical Condition End--------------------------


                // For Measurement --------------------------
                
                res.Measurement.sort((a,b)=>{
                    let Aa = a.MeasurementTitle.toLowerCase();
                    let Ab = b.MeasurementTitle.toLowerCase();



                    if(Aa < Ab)
                    {
                        return -1;
                    }

                    if(Aa > Ab)
                    {
                        return 1;
                    }
                    return 0;
                })
                this.setState({MeasureList:res.Measurement})


                


                const temp_MeasureDateList = [];
                res.Measurement.map((res)=>{
                   
                   temp_MeasureDateList.push(res)

                })

                temp_MeasureDateList.map((resposi)=>{
                    resposi.Measurement_date = new Date(resposi.Measurement_date);
                })

                temp_MeasureDateList.map((repd)=>{
                    console.log(repd)
                })


                
    
                temp_MeasureDateList.sort((date1,date2)=>{

                    if (date1.Measurement_date > date2.Measurement_date) return 1;
                    if (date1.Measurement_date < date2.Measurement_date) return -1;
                 return 0;

                })

                this.setState({MeasureDateList:temp_MeasureDateList})

             // For Measurement End --------------------------



            // For Surgery --------------------------

                res.Surgery.sort((a,b)=>{
                    let Aa = a.Surgery_name.toLowerCase();
                    let Ab = b.Surgery_name.toLowerCase();



                    if(Aa < Ab)
                    {
                        return -1;
                    }

                    if(Aa > Ab)
                    {
                        return 1;
                    }
                    return 0;
                })
                this.setState({SurgeryList:res.Surgery})

                
                
                 // date sorting start

                 const temp_SurgeryDateList = [];
                    res.Surgery.map((res)=>{
                                   
                        temp_SurgeryDateList.push(res)
                
                     })
                
                        temp_SurgeryDateList.map((resposi)=>{
                        resposi.Surgery_date = new Date(resposi.Surgery_date);
                        })
                
                    temp_SurgeryDateList.map((repd)=>{
                        console.log(repd)
                    })
                
                
                                
                    
                    temp_SurgeryDateList.sort((date1,date2)=>{
                
                     if (date1.Surgery_date > date2.Surgery_date) return 1;
                     if (date1.Surgery_date < date2.Surgery_date) return -1;
                     return 0;
                
                   })
                
                     this.setState({SurgeryDateList:temp_SurgeryDateList})
                
                
                                //date sorting end

                 // For Surgery End--------------------------


                 // For Vaccination--------------------------
                res.Vaccination.sort((a,b)=>{
                    let Aa = a.Vaccination_name.toLowerCase();
                    let Ab = b.Vaccination_name.toLowerCase();



                    if(Aa < Ab)
                    {
                        return -1;
                    }

                    if(Aa > Ab)
                    {
                        return 1;
                    }
                    return 0;
                })
                this.setState({VaccineList:res.Vaccination})

                
                // date sorting start

                const temp_VaccineDateList = [];
                res.Vaccination.map((res)=>{
                                   
                   temp_VaccineDateList.push(res)
                
                })
                
                temp_VaccineDateList.map((resposi)=>{
                     resposi.VaccinationAdministration_date = new Date(resposi.VaccinationAdministration_date);
                })
                
                temp_VaccineDateList.map((repd)=>{
                    console.log(repd)
                })
                    
                temp_VaccineDateList.sort((date1,date2)=>{
                
                    if (date1.VaccinationAdministration_date > date2.VaccinationAdministration_date) return 1;
                   if (date1.VaccinationAdministration_date < date2.VaccinationAdministration_date) return -1;
                      return 0;
                
                })
                
                this.setState({VaccineDateList:temp_VaccineDateList})
                
                
                 //date sorting end

                 // For Vaccination End--------------------------







                
                console.log(this.state.medicaldetails)
                
            })
        })
        axios.get('https://dashboard.tag8.co.in/api/pet/countMedicalDetails/'+this.props.match.params.tag_number).then(response =>{
            let count = response.data
            console.log(count)
            this.setState({vaccinationcount:count.Vaccination, allergycount:count.Allergy, surgerycount:count.Surgery, medicationcount:count.Medication, medicalconditioncount:count.Medicalcondition, measurementcount:count.Measurement})
            if(count.Vaccination === 0 && count.Allergy === 0 && count.Surgery === 0 && count.Medication === 0 && count.Medicalcondition === 0 && count.Measurement === 0){
                this.setState({displaydefaultmsg:true})
            }
            else{
                this.setState({displaydefaultmsg:false})
            }
        })

        axios.get('https://dashboard.tag8.co.in/api/pet/getPetParentDetails/'+this.props.match.params.tag_number).then(response =>{
            this.setState({
                pet_imagelocation: response.data.pet_imagelocation,
                pet_imagename:response.data.pet_imagename
            })
        }).catch(error =>{
            console.log(error)
        })

    }

    render(){
        const {pet_imagelocation, pet_imagename,togglebtnname,togglebtn,sortbyAD,selected,AllergyDateList,MedicationDateList,MedicalConDateList,MeasureDateList,SurgeryDateList,VaccineDateList,MedicationList,MedicalConList,MeasureList,SurgeryList,VaccineList,mysort,sortType,AllergyList,medicaldetails, vaccinationcount, allergycount, surgerycount, medicationcount, medicalconditioncount, measurementcount, filteroption} = this.state
        return(
            <div className="overallbody bg-warning" style={{'padding': '30px 30px 30px 0px'}}>
                <div className="row">
                    <div className="col-xl-3 col-lg mt-3 pt-5 bg-dark dashboard__blacksidenav" style={{height:'90vh'}}>
                        <div className="image-upload-med text-left ml-5 mt-5"  style={{marginLeft:'50px'}}>
                            {
                                pet_imagelocation ? <img alt="" src={pet_imagelocation}  className="image"  style={{marginLeft:'50px'}} /> : <img alt="" src={CatDog}  className="image"  style={{marginLeft:'50px'}} />
                            }
                        </div>                  
                        <div className="row medpetparentlinks">
                            <div className="col text-left ml-5">
                                <ul className="mt-5 text-left leftside-navigation">
                                    <li>
                                        <a href={`https://dashboard.tag8.co.in/dashboard/`+localStorage.getItem('uid')}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>My Tags</Button></a><br/><br/>
                                    </li>
                                    <li>
                                        <a href={`/calendar/`+localStorage.getItem('uid')}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Calendar</Button></a><br/><br/>
                                    </li>
                                    <li>
                                        <a href={`/pet-details/`+this.props.match.params.tag_number}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Pet Details</Button></a><br/><br/>
                                    </li>
                                    <li>
                                        <a href={`/pet-parent-details/`+this.props.match.params.tag_number}><Button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Pet Parent Details</Button></a><br/><br/>
                                    </li>
                                </ul>
                            </div>
                        </div>  
                    </div>
                    <div className="col-xl-9 col-lg pl-4">
                        <div className="row text-center" style={{'margin': 'auto', 'text-align': 'center', 'align-items': 'center', 'width': '100%'}}>
                            <div className="col-lg rightside-blocks mt-3 mr-5">
                                <img src={Vaccination} width="25px" height="25px" className="rightside-block mt-3s-image"/><span className="ml-2 rightside-vaccinations">Vaccinations</span><br/><br/>
                                {
                                    vaccinationcount ? <p className="text-center rightside-blocks-middle">{vaccinationcount}</p> : <p className="text-center rightside-blocks-middle">0</p>
                                }
                            </div>

                            <div className="col-lg rightside-blocks mr-5 mt-3">
                                <img src={Allergy} width="25px" height="25px" className="rightside-blocks-image"/><span className="ml-2 rightside-vaccinations">Allergies</span><br/><br/>
                                {
                                    allergycount ? <p className="text-center rightside-blocks-middle">{allergycount}</p> : <p className="text-center rightside-blocks-middle">0</p>
                                }
                            </div>

                            <div className="col-lg rightside-blocks mr-5 mt-3">
                                <img src={Surgery} width="25px" height="25px" className="rightside-blocks-image"/><span className="ml-2 rightside-vaccinations">Surgeries</span><br/><br/>
                                {
                                surgerycount ? <p className="text-center rightside-blocks-middle">{surgerycount}</p> : <p className="text-center rightside-blocks-middle">0</p>
                                }
                            </div>

                            <div className="col-lg rightside-blocks mr-5 mt-3">
                                <img src={Medication} width="25px" height="25px" className="rightside-blocks-image"/><span className="ml-2 rightside-vaccinations">Medication</span><br/><br/>
                                {
                                    medicationcount ? <p className="text-center rightside-blocks-middle">{medicationcount}</p> : <p className="text-center rightside-blocks-middle">0</p>
                                }
                            </div>

                            <div className="col-lg rightside-blocks mr-5 mt-3">
                                <img src={MedicalCondition} width="25px" height="25px" className="rightside-blocks-image"/><span className="ml-2 rightside-vaccinations">Medical Condition</span><br/><br/>
                                {
                                    medicalconditioncount ? <p className="text-center rightside-blocks-middle">{medicalconditioncount}</p> : <p className="text-center rightside-blocks-middle">0</p>
                                }
                            </div>

                            <div className="col-lg rightside-blocks rightside-blocks-measurement measurement-block mt-3 mr-2">
                                <img src={Measurement} width="27px" height="27px" className="rightside-blocks-image"/><span className="ml-2 rightside-vaccinations">Measurement</span><br/><br/>
                                {
                                    measurementcount ? <p className="text-center rightside-blocks-middle">{measurementcount}</p> : <p className="text-center rightside-blocks-middle">0</p>
                                }
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col menu">
                                <div className="row row-sort-filter float-right" style={{width:'1000px'}}>
                                    <div className="col-lg col-md col-sm col-xs text-center add-div">
                                        <Button style={{'margin-top': '7px', 'textTransform': 'none'}} onClick={()=>{this.setState({addnewrecord:true})}}><img src={Add} width="25px" height="25px"/><span className="ml-2">Add New Record</span></Button>
                                    </div>
                                    <div className="col-lg col-md col-sm col-xs text-center sort-div">
                                        <div className="dropdown" style={{'margin-top': '18px'}}>
                                            <a style={{'text-decoration': 'none', fontSize:'16px'}} className="text-dark text-center">Sort<i className="fa fa-caret-down ml-3" aria-hidden="true"></i></a>
                                            <div className="dropdown-content py-1 px-2" style={{'margin-top': '-0px', marginLeft:'-40px',padding:'10px',width:'250px'}}>
                                                <button onClick={()=>{this.onNameSort('name')}} className="btn dropdown-item-1 text-center" style={{'height': '30px','font-size': '15px'}}>By Name<span><i className={!this.state.togglebtnname ? "fa fa-arrow-up ml-2":""}></i><i className={this.state.togglebtnname ? "fa fa-arrow-down ml-2":""}></i></span></button>
                                                <button onClick={()=>{this.onAppointSort('appointment')}} className="btn dropdown-item-1  text-center" style={{'height': '30px','font-size': '15px'}}>By Appointment Date<span style={{display:'inline'}}><i className={this.state.togglebtn ? "fa fa-arrow-down ml-2":""}></i><i className={!this.state.togglebtn ?"fa fa-arrow-up ml-2":""}></i></span></button>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg col-md col-sm col-xs text-center filter-div">
                                        <select name="filteroption" style={{'height': '30px','font-size': '16px', 'font-weight': '450'}} value={filteroption} onChange={this.changeHandler} className="rightside-button bg-warning form-control mt-3"> 
                                            <option value="filter" selected className="bg-white text-dark">Filter</option>
                                            <option className="bg-white" value="Vaccination">Vaccination</option>
                                            <option className="bg-white" value="Allergy">Allergy</option>
                                            <option className="bg-white" value="Surgery">Surgery</option>
                                            <option className="bg-white" value="Medication">Medication</option>
                                            <option className="bg-white" value="Medicalcondition">Medical Condition</option>
                                            <option className="bg-white" value="Measurement">Measurement</option>
                                        </select>
                                    </div>
                                    <div className="col-lg col-md col-sm col-xs text-center"></div>
                                </div>

                                {
                                    !this.state.displaydefaultmsg ?
                                    <div>
                                        {
                                            filteroption === 'Vaccination' ? 
                                            <div>
        
                                                {
                                                    this.state.selected === true && this.state.sortType === 'name' ?
                                                    <div>

                                                    {
                                                        

                                                            this.state.sortbyAD === true ?
                                                            <div>
                                                                {
                                                                    VaccineList.map((res)=>{
                                                                    return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)
                                                                        })
                                                                }
                                                            </div>:
                                                            <div>
                                                                {
                                                                    VaccineList.reverse().map((res)=>{
                                                                        return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)  

                                                                    })
                                                                }
                                                            </div>
                                                            
                                                            
                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    this.state.selected === true && this.state.sortType === 'appointment' ?
                                                    <div>
                                                        
                                                    {
                                                        //    VaccineDateList.map((res)=>{
                                                        //     return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)

                                                        //    })
                                                            
                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            VaccineDateList.map((res)=>{
                                                            return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                                VaccineDateList.reverse().map((res)=>{
                                                                return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)  

                                                                })
                                                            }
                                                        </div>

                                                    }

                                                    </div>:""
                                                }



                                                {
                                                    medicaldetails && this.state.selected === false ?
                                                    <div>
                                                        {
                                                            medicaldetails.Vaccination.map((res)=>{
                                                                return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)
                                                            })
                                                        }
                                                    </div>:""
                                                }

                                            </div>:""
                                        }
                                        {
                                            filteroption === 'Surgery' ? 
                                            <div>
                                                {
                                                    this.state.selected === true && this.state.sortType === 'name' ?
                                                    <div>

                                                    {
                                                        //    SurgeryList.map((res)=>{
                                                        //     return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)
                                                        //    })

                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            SurgeryList.map((res)=>{
                                                                return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                                SurgeryList.reverse().map((res)=>{
                                                                    return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)  

                                                                })
                                                            }
                                                        </div>

                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    this.state.selected === true && this.state.sortType === 'appointment' ?
                                                    <div>
                                                        
                                                    {
                                                        //    SurgeryDateList.map((res)=>{
                                                        //     return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)

                                                        //    })

                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            SurgeryDateList.map((res)=>{
                                                                return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                                SurgeryDateList.reverse().map((res)=>{
                                                                    return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)

                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    medicaldetails && this.state.selected === false ?
                                                    <div>
                                                        {
                                                            medicaldetails.Surgery.map((res)=>{
                                                                return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)
                                                            })
                                                        }
                                                    </div>:""
                                                }

                                            </div> : ""
                                        }
                                        {
                                            filteroption === 'Allergy' ? 
                                            <div>
                                                {
                                                    this.state.selected === true && this.state.sortType === 'name' ?
                                                    <div>

                                                    {
                                                        //    AllergyList.map((res)=>{
                                                        //     return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)
                                                        //    })

                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            AllergyList.map((res)=>{
                                                                return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                            AllergyList.reverse().map((res)=>{
                                                                return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)

                                                                })
                                                            }
                                                        </div>

                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    this.state.selected === true && this.state.sortType === 'appointment' ?
                                                    <div>
                                                        
                                                    {
                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            AllergyDateList.map((res)=>{
                                                                return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                                AllergyDateList.reverse().map((res)=>{
                                                                    return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)

                                                                })
                                                            }
                                                        </div>

                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    medicaldetails && this.state.selected === false ?
                                                    <div>
                                                        {
                                                            medicaldetails.Allergy.map((res)=>{
                                                                return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)
                                                            })
                                                        }
                                                    </div>:""
                                                }

                                            </div> : ""
                                        }
                                        {
                                            filteroption === 'Medication' ? 
                                            <div>
                                                {
                                                    this.state.selected === true && this.state.sortType === 'name' ?
                                                    <div>

                                                    {
                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            MedicationList.map((res)=>{
                                                                return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                                MedicationList.reverse().map((res)=>{
                                                                    return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)  

                                                                })
                                                            }
                                                        </div>

                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    this.state.selected === true && this.state.sortType === 'appointment' ?
                                                    <div>
                                                        
                                                    {
                                                        //    MedicationDateList.map((res)=>{
                                                        //     return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)

                                                        //    })

                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            MedicationDateList.map((res)=>{
                                                                return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                                MedicationDateList.reverse().map((res)=>{
                                                                    return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)

                                                                })
                                                            }
                                                        </div>

                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    medicaldetails && this.state.selected === false ?
                                                    <div>
                                                        {
                                                            medicaldetails.Medication.map((res)=>{
                                                                return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)
                                                            })
                                                        }
                                                    </div>:""
                                                }
                                            </div> : ""
                                        }
                                        {
                                            filteroption === 'Medicalcondition' ? 
                                            <div>
                                                {
                                                    this.state.selected === true && this.state.sortType === 'name' ?
                                                    <div>

                                                    {
                                                        //    MedicalConList.map((res)=>{
                                                        //     return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)
                                                        //    })

                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            MedicalConList.map((res)=>{
                                                                return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                            MedicalConList.reverse().map((res)=>{
                                                                return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)  

                                                                })
                                                            }
                                                        </div>

                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    this.state.selected === true && this.state.sortType === 'appointment' ?
                                                    <div>
                                                        
                                                    {
                                                        //    MedicalConDateList.map((res)=>{
                                                        //     return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)

                                                        //    })
                                                        
                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            MedicalConDateList.map((res)=>{
                                                                return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                                MedicalConDateList.reverse().map((res)=>{
                                                                    return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)

                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    medicaldetails && this.state.selected === false ?
                                                    <div>
                                                        {
                                                            medicaldetails.Medicalcondition.map((res)=>{
                                                                return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)
                                                            })
                                                        }
                                                    </div>:""
                                                }
                                            </div> : ""
                                        }
                                        {
                                            filteroption === 'Measurement' ? 
                                            <div>
                                                {
                                                    this.state.selected === true && this.state.sortType === 'name' ?
                                                    <div>

                                                    {
                                                        
                                                            
                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            MeasureList.map((res)=>{
                                                                return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                                MeasureList.reverse().map((res)=>{
                                                                    return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)

                                                                })
                                                            }
                                                        </div>

                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    this.state.selected === true && this.state.sortType === 'appointment' ?
                                                    <div>
                                                        
                                                    {
                                                        

                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                            {
                                                            MeasureDateList.map((res)=>{
                                                                return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)
                                                                })
                                                            }
                                                        </div>:
                                                        <div>
                                                            {
                                                            MeasureDateList.reverse().map((res)=>{
                                                                return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)

                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    </div>:""
                                                }
                                                {
                                                    medicaldetails && this.state.selected === false ?
                                                    <div>
                                                        {
                                                            medicaldetails.Measurement.map((res)=>{
                                                                return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)
                                                            })
                                                        }
                                                    </div>:""
                                                }
                                            </div> : ""
                                        }
                                        {
                                            filteroption === 'filter' ?
                                            <div>

                                            {
                                                this.state.selected === true && this.state.sortType === 'name'  ?
                                                <div>

                                                    {
                                                        this.state.sortbyAD === true ?
                                                        <div>
                                                    {
                                                        AllergyList ?
                                                        <div>
                                                            {
                                                                AllergyList.map((res)=>{
                                                                    return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        MedicationList ?
                                                        <div>
                                                            {
                                                                MedicationList.map((res)=>{
                                                                    return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        MedicalConList ?
                                                        <div>
                                                            {
                                                                MedicalConList.map((res)=>{
                                                                    return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        MeasureList ?
                                                        <div>
                                                            {
                                                                MeasureList.map((res)=>{
                                                                    return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        SurgeryList ?
                                                        <div>
                                                            {
                                                                SurgeryList.map((res)=>{
                                                                    return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        VaccineList ?
                                                        <div>
                                                            {
                                                                VaccineList.map((res)=>{
                                                                    return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>) 

                                                                })
                                                            }
                                                        </div>:""         
                                                    }

                                                        </div>:
                                                        <div>
                                                            {/* ReverseOne */}
                                                    {
                                                        AllergyList ?
                                                        <div>
                                                            {
                                                                AllergyList.reverse().map((res)=>{
                                                                    return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        MedicationList ?
                                                        <div>
                                                            {
                                                                MedicationList.reverse().map((res)=>{
                                                                    return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        MedicalConList ?
                                                        <div>
                                                            {
                                                                MedicalConList.reverse().map((res)=>{
                                                                    return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        MeasureList ?
                                                        <div>
                                                            {
                                                                MeasureList.reverse().map((res)=>{
                                                                    return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        SurgeryList ?
                                                        <div>
                                                            {
                                                                SurgeryList.reverse().map((res)=>{
                                                                    return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)

                                                                })
                                                            }
                                                        </div>:""         
                                                    }
                                                    {
                                                        VaccineList ?
                                                        <div>
                                                            {
                                                                VaccineList.reverse().map((res)=>{
                                                                    return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>) 

                                                                })
                                                            }
                                                        </div>:""         
                                                    }

                                                        </div>
                                                    }
                                                </div>:""
                                            }    

                                                
                                            {
                                                medicaldetails && this.state.selected === false ?
                                                <div>

                                                {
                                                    medicaldetails ?
                                                    <div>
                                                    {
                                                        medicaldetails.Vaccination.map((res)=>{
                                                            return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    medicaldetails ?
                                                    <div>
                                                    {
                                                        medicaldetails.Surgery.map((res)=>{
                                                            return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    medicaldetails ?
                                                    <div>
                                                    {
                                                        medicaldetails.Allergy.map((res)=>{
                                                            return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    medicaldetails ?
                                                    <div>
                                                    {
                                                        medicaldetails.Medication.map((res)=>{
                                                            return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    medicaldetails ?
                                                    <div>
                                                    {
                                                        medicaldetails.Medicalcondition.map((res)=>{
                                                            return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    medicaldetails ?
                                                    <div>
                                                    {
                                                        medicaldetails.Measurement.map((res)=>{
                                                            return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                

                                                </div>:""
                                            }
                                                
                                            </div> : ""
                                        }
                                        {
                                            this.state.selected === true && this.state.sortType === 'appointment' && filteroption === 'filter'  ?
                                            <div>

                                                {
                                                    this.state.sortbyAD === true ?
                                                    <div>

        {
                                                    VaccineDateList ?
                                                    <div>
                                                    {
                                                        VaccineDateList.map((res)=>{
                                                            return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    SurgeryDateList ?
                                                    <div>
                                                    {
                                                        SurgeryDateList.map((res)=>{
                                                            return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    AllergyDateList ?
                                                    <div>
                                                    {
                                                        AllergyDateList.map((res)=>{
                                                            return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    MedicationDateList ?
                                                    <div>
                                                    {
                                                        MedicationDateList.map((res)=>{
                                                            return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    MedicalConDateList ?
                                                    <div>
                                                    {
                                                        MedicalConDateList.map((res)=>{
                                                            return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    MeasureDateList ?
                                                    <div>
                                                    {
                                                        MeasureDateList.map((res)=>{
                                                            return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }


                                                    </div>:
                                                    <div>
                                                        {/* ReverseOne */}

                                                        {
                                                    VaccineDateList ?
                                                    <div>
                                                    {
                                                        VaccineDateList.reverse().map((res)=>{
                                                            return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    SurgeryDateList ?
                                                    <div>
                                                    {
                                                        SurgeryDateList.reverse().map((res)=>{
                                                            return(<Surgerycard tag_number={this.props.match.params.tag_number} Surgery_Reportlocation={res.Surgery_Reportlocation} Surgery_Reportname={res.Surgery_Reportname} surgery_id={res.surgery_id} Surgery_name={res.Surgery_name} SurgeryDoctor_name={res.SurgeryDoctor_name} Surgery_date={res.Surgery_date} SurgeryNotes={res.SurgeryNotes} SurgeryTitle={res.SurgeryTitle} SurgeryNext={res.SurgeryNext}></Surgerycard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    AllergyDateList ?
                                                    <div>
                                                    {
                                                        AllergyDateList.reverse().map((res)=>{
                                                            return(<Allergycard tag_number={this.props.match.params.tag_number} Allergy_Reportlocation={res.Allergy_Reportlocation} Allergy_Reportname={res.Allergy_Reportname} allergy_id={res.allergy_id} Allergy_name={res.Allergy_name} AllergyDoctor_name={res.AllergyDoctor_name} AllergyDiagnosis_date={res.AllergyDiagnosis_date} AllergyNotes={res.AllergyNotes} AllergyTitle={res.AllergyTitle} AllergyNext={res.AllergyNext}></Allergycard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    MedicationDateList ?
                                                    <div>
                                                    {
                                                        MedicationDateList.reverse().map((res)=>{
                                                            return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    MedicalConDateList ?
                                                    <div>
                                                    {
                                                        MedicalConDateList.reverse().map((res)=>{
                                                            return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }
                                                {
                                                    MeasureDateList ?
                                                    <div>
                                                    {
                                                        MeasureDateList.reverse().map((res)=>{
                                                            return(<Measurementcard tag_number={this.props.match.params.tag_number} Measurement_Reportlocation={res.Measurement_Reportlocation} Measurement_Reportname={res.Measurement_Reportname} measurement_id={res.measurement_id} Weight={res.Weight} Measuredby={res.Measuredby} Measurement_date={res.Measurement_date} MeasurementNotes={res.MeasurementNotes} MeasurementTitle={res.MeasurementTitle} MeasurementNext={res.MeasurementNext}></Measurementcard>)   
                                                        })
                                                    }
                                                    </div> : ""
                                                }

                                                    </div>
                                                }
                                            </div> : ""
                                        }
                                    </div> :
                                    <div style={{ color: 'lightgray', textAlign: 'center', marginLeft: '-20%' }}>
                                        <p style={{ font: '400 14px/20px Roboto,Helvetica Neue,sans-serif', fontSize: '25px', marginTop: 50 }}>No medical records found</p>
                                        <br></br>
                                        <p style={{ font: '400 14px/20px Roboto,Helvetica Neue,sans-serif', fontSize: '25px' }}>Want to add a medical record?</p>
                                        <Button className="mt-3" style={{color:'lightgray', fontSize:'20px', textTransform:'none'}} onClick={()=>{this.setState({addnewrecord:true})}}>Click Here</Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog id="AddRecordDialog" disableBackdropClick open={this.state.addnewrecord} onClose={this.handleCloseRecord} aria-labelledby="form-dialog-title">
                    <AddNewRecord className="Add_New_Record_Dialog" closeDialog={this.handleCloseRecord}/>
                </Dialog>
            </div>
        )
    }
}