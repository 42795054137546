import React from 'react';
import Logo from '../../assets/genielogofinal.png';
import './footer.component.css';

function Footer() {
    return (
        <div>
            <footer className="pattern1">
   <div className="container-fluid p-5"  style={{background: 'linear-gradient(90deg, #d6d6d6 0%, #ebe9e6 80%)'}}>
      <div className="row">
         <div className="col-lg-1 text-center">
         <div className="row">
            <a className="navbar-brand brand-image" href="#"><img src={Logo} height="50px" width="90px" className="mb-5" /></a>
            </div>
         </div>
         <div className="col-lg-3 rightside-footer pr-5">
            <h5>About us</h5>
            <hr className="small-divider left"/>
            <p className="mt-3 about-us" style={{fontSize: '14px'}}>genie Smart Tag is part of tag8 that is globally known for its Track & Trace solutions. The endeavour of genie is to keep pets & their vital information safe and available at a click. genie pet community is an integral initiative to encourage exchange between pet lovers and to simply have fun and spread the joy of being a pet lover.</p>
         </div>
         <div className="col-lg-3 geniepetcommunity">
            <h5>Contact Us</h5>
            <hr className="small-divider left"/>
            <ul className="list-unstyled mt-3 ">
               <li className="mb-1" style={{fontSize:'14px'}}><i className="fa fa-phone margin-icon"></i>(+91) 9029008248</li>
               <li className="mb-1" style={{fontSize:'14px'}}><i className="fa fa-envelope margin-icon text-dark"></i><a href="mailto:support@tag8.in">support@tag8.in</a></li>
               <li className="mb-1" style={{fontSize:'14px'}}><i className="fa fa-map-marker margin-icon"></i>3Y Ventures LLP. 301, A to Z Industrial Estate, Ganapatrao Kadam Marg, Lower Parel, Mumbai, Maharashtra 400013 </li>
            </ul>
         </div>
         <div className="col-lg-2 geniepetcommunity">
            <h5>Working Hours</h5>
            <hr className="small-divider left"/>
            <ul className="list-unstyled mt-3">
               <li className="mb-1" style={{fontSize:'14px'}}>Weekdays - 10 am - 6 pm</li>
            </ul>
         </div>
         <div className="col-lg-3 text-center geniepetcommunity">
            <h5>Join genie pet community</h5>
            <hr className="small-divider left"/>
            <ul className="social-list list-unstyled text-center list-inline mt-4">
            <li className="list-inline-item"><a title="Facebook" target="_blank"  href="https://facebook.com/profile.php?id=103790224867732&ref=content_filter"><i className="fa fa-facebook fa-2x"></i></a></li>
            <li className="list-inline-item ml-2"><a title="Instagram" target="_blank" href="https://www.instagram.com/geniepetcommunity/?igshid=183d4ojaqqu9g"><i className="fa fa-instagram fa-2x"></i></a></li>

           </ul>
        </div>
      </div>
      <hr/>
   </div>
   <div className="page-scroll hidden-sm hidden-xs">
      <a href="#top" className="back-to-top"><i className="fa fa-angle-up"></i></a>
   </div>
   
</footer>
<div className="row p-3 bg-dark text-white text-center">
   <p className="text-center copyrighttext" style={{margin: 'auto', 'letter-spacing': '1px'}}>Copyright 2019 tag8 - 3Y Ventures LLP | All Rights Reserved | <a href="/legal-terms-and-conditions" className="text-white" style={{textDecoration:'none'}}>Legal Terms & Conditions</a> | <a href="/faq" className="text-white" style={{textDecoration:'none'}}>FAQ</a> </p>
</div>

        </div>
    )
}

export default Footer
