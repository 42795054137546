import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel'
import Features from './features/Features'
import Navbar from '../header/header.component.jsx'
import Started from './started/Started';
import Footer from '../footer/footer.component.jsx';
import item1 from '../../assets/pet_banners/Pet Banner_1.jpg';
import item2 from '../../assets/pet_banners/Pet Banner_2.jpg';
import item3 from '../../assets/pet_banners/Pet Banner_3.jpg';
import item4 from '../../assets/pet_banners/Pet Banner_4.jpg';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Video from '../../assets/pet-how-it-works-video.mp4'
import './home.component.css';
import Play from '../../assets/play.png';

function Home() {
    const carouselRef = useRef(null);
    const totalPages = 4;
    let resetTimeout;
    const [playvideo, setplayvideo] = useState(false);
    const handleNotFound = () => setplayvideo(false);
    const playvideopopup = () =>{
        setplayvideo(true)
    }
    return (
        <div className="home">
            <div className="home__landing">
                <div className="home__para new_para_home">
                    <h2>Why Dolphin Smart Tracker?</h2>
                    <p>When it comes to our pet, we only want the BEST. genie Smart Tag is the BEST IN CLASS pet tag with unparalleled features to keep pets & their vital information safe, and available on a click, all at an affordable price.</p>
                    <div style={{ display: 'flex', marginLeft: "40px" }} className="col-md-12 col-sm-12 col-12 play-btn">
                        <div _ngcontent-xsi-c153="" className="boxplay" style={{position:'relative'}}>
                            <Button onClick={playvideopopup} className="playbutton" >
                                <img src={Play} height="50px" className="playbuttonimage" weight="50px" style={{ 'border-radius': '50%', 'z-index': '1' }} />
                            </Button>
                            <span className="span1"></span>
                            <span className="span2"></span>
                            <span className="span3"></span>
                        </div>
                    </div>
                </div>
                <Carousel className="home__carousel" ref={carouselRef} onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout)
                    if (index + 1 === totalPages) {
                        resetTimeout = setTimeout(() => {
                            carouselRef.current.goTo(0)
                        }, 5000) // same time
                    }
                }} enableMouseSwipe={false} infinite showArrows={false} itemsToShow={1} enableAutoPlay autoPlaySpeed={5000}>
                    <img className="carousel__image" src={item1} />
                    
                    <img className="carousel__image" src={item2} />
                    <img className="carousel__image" src={item3} />
                    <img className="carousel__image" src={item4} />
                </Carousel>
            </div>
            <Features />
            <Started />
            <Footer />
            <Dialog disableBackdropClick open={playvideo} onClose={handleNotFound} aria-labelledby="form-dialog-title">
                <DialogContent >
                    <video className="videoplayer" height="100%" width="100%" controls autoplay="true">
                        <source src={Video} type="video/mp4" />
                    </video>
                    <div className="row text-center">
                        <div className="col">
                            <button className="mt-4 video-play-btn" style={{'width':'200px', 'letter-spacing': '1px'}} onClick={handleNotFound}>CLOSE</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default Home
