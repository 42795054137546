import React, {useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../medicaldashboard.component.css';
import 'font-awesome/css/font-awesome.min.css';
import Vaccination from '../../../assets/vaccine.png';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Calendar from '../../../assets/calendar.png';
import paperclip from '../../../assets/paper-clip.png';
import File from '../../../assets/attached-file.png';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import axios from 'axios';
import './cards.component.css';
import VaccinationUpdate from '../update-record/vaccinationupdate.component.jsx';

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
let usertoken = readCookie('token');
let token = usertoken

function Vaccinationcard({ tag_number, Vaccination_Reportlocation, Vaccination_Reportname, vaccination_id, Vaccination_name, VaccinationDoctor_name, VaccinationAdministration_date, VaccinationNotes, VaccinationTitle, VaccinationNext}){
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseUpdate = () => {
        setShowUpdate(false)
    }
    const convertISOStringToMonthDay = date => {
        const tempDate = new Date(date).toString().split(' ');
        const formattedDate = `${tempDate[2]} ${tempDate[1]}, ${tempDate[3]}`;
        return formattedDate;
    }
    const handleClose1 = () => {
        setShow(false);
    }
    function deletedetails() {
        const state = {
            tag_number: tag_number,
            vaccination_id: vaccination_id
        }

        axios.post("https://dashboard.tag8.co.in/api/pet/deleteMedicalDetails", state, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res);
            window.location.reload();
        }).catch(error => {
            console.log(error);
        })
    }

    function capitalize(str){
        if(str){
            const word = str.charAt(0).toUpperCase() + str.slice(1)
            return word
        }
    }

    return(
        <div>
            <div className="pet-information-active pt-3">
                <div className="row inside-info">
                    <div className="col-2 text-center top-row" style={{margin:'auto'}}>
                        <img className="main-logo-img" src={Vaccination} width="100px" height="100px"/><br/><br/>
                        <span className="font-weight-bold med-card-title" style={{fontSize:"18px"}}>Vaccination</span><br/>
                    </div>
                    <div className="col-10">
                    <div className="second-row">
                        <div className="float-left name-tile">
                            <p style={{'font-weight': '550'}}>{capitalize(Vaccination_name)}</p>
                            <p hidden>{vaccination_id}</p>
                        </div>
                        <div className="menu-options">
                            <i aria-controls='menu-list-grow' onClick={handleClick} className="fa fa-ellipsis-v menu-drop mr-3" style={{ color: 'black', fontSize: '20px', cursor:"pointer"}}></i>
                            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} transformOrigin={{ vertical: "top", horizontal: "center" }} open={Boolean(anchorEl)} onClose={handleClose}>
                                <MenuItem onClick={() => { setShowUpdate(true); handleClose()}}>Update</MenuItem>
                                <MenuItem onClick={() => { setShow(true); handleClose() }}>Delete</MenuItem>
                            </Menu>
                        </div>
                    </div>   
                        <br/><br/>

                        <div className="row text-left remaining-info-div">

                        <div className="col col-sm" style={{display:'flex',flexDirection:'column'}}>
                        <div className="inner-remaining-info-div">
                            
                                <p className="rightside-medical-details-vaccination-info">Vaccinated on:<br className="breaking"/>
                                <span className="darkgray">{convertISOStringToMonthDay(VaccinationAdministration_date)}</span></p>
                            
                           
                                <p className="rightside-medical-details-vaccination-info">Vaccinated by:<br className="breaking"/>
                                <span className="darkgray">{(VaccinationDoctor_name)}</span></p>
                            
                        </div>
                        </div>
                        <div className="col col-sm reminder-doc" style={{display:'flex',flexDirection:'column'}}>
                                <div className="inner-reminder-doc" style={{display:'flex'}}>
                                <img src={Calendar} width="25px" height="25px"/><span className="rightside-medical-details-vaccination-info ml-2">Reminder :</span>
                                </div>
                                {VaccinationNext ? <p className="darkgray" style={{fontSize:"14px", marginLeft:"30px"}}>{convertISOStringToMonthDay(VaccinationNext)} <br/> {(VaccinationTitle)}</p> : <div className="darkgray" style={{fontSize:"14px", marginLeft:"30px"}}>No reminders</div>}
                                
                            </div>
                            <div className="col col-sm reminder-doc" style={{display:'flex',flexDirection:'column'}}>
                                <div className="inner-reminder-doc" style={{display:'flex'}}>
                                <img src={paperclip} width="20px" height="20px"/><span className="rightside-medical-details-vaccination-info ml-2">Documents :</span><br/>
                                </div>
                                {Vaccination_Reportlocation ? <Tooltip title={(Vaccination_Reportname)}><a href={Vaccination_Reportlocation} style={{'font-size': '14px'}}><img className="ml-4" src={File} width="25px" height="25px"/></a></Tooltip> :<div className="darkgray docs-no-upload" style={{fontSize:"14px", marginLeft:"30px",display:'block'}}>No documents uploaded</div>}
                            </div>
                        </div>
                        <p className="rightside-medical-details-vaccination-info my-notes">Notes:</p>
                        <p className="darkgray" style={{'word-wrap': 'break-word', 'word-break': 'break-all', 'margin-top': '-15px','font-size': '14px'}}>{(VaccinationNotes)}</p><br/>
                    </div>
                </div>
            </div>
            <Dialog disableBackdropClick open={show} onClose={handleClose1} aria-labelledby="form-dialog-title">
                <DialogContent className="delete__dialog">
                    <h1 style={{ margin: "20px", marginTop: "10px", font: "500 20px/32px Roboto,Helvetica Neue,sans-serif", letterSpacing: "normal" }}>Are you sure you want to delete this vaccination?</h1>
                    <div className="major__dialog_btn" style={{ display: "flex" }}>

                        <button onClick={deletedetails} className="card__confirm" style={{ margin: "10px 20px" }} >
                            CONFIRM
                        </button>
                        <button onClick={() => setShow(false)} className="card__cancel" style={{ margin: "10px 20px" }} >
                            CANCEL
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog disableBackdropClick open={showUpdate} onClose={handleCloseUpdate} aria-labelledby="form-dialog-title">
                <VaccinationUpdate vaccination_id={vaccination_id} closeDialog={handleCloseUpdate}/>
            </Dialog>
        </div>
    )
}

export default Vaccinationcard