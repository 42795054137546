import React, { useEffect, useState } from 'react'
import Buy from '../../../assets/Post_11.png'
import SignUp from '../../../assets/Post_22.png'
import How from '../../../assets/Pet How It Works Blacka_1.png'
import './Started.css';
import axios from 'axios';

function Started() {
    const [pettag,setpettag] = useState('')
    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    let usertoken = readCookie('token');
    const token = usertoken;
    useEffect(()=>{
        axios.get("https://dashboard.tag8.co.in/api/getDashboardDetails/" + localStorage.getItem('uid'), {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        }).then(res => {
        console.log("alltags",res);
        if(res.data[0].tag_number){
            setpettag(res.data[0].tag_number)
        }
        }).catch(error => {
            console.log(error);
        })
    },[])
    return (
        <div className="started mb-5" style={{backgroundColor:'white !important'}}>
            {
                pettag ? "" :
                <div className="started">
                    <h1 style={{ textAlign:'center' }}>How to get Started? </h1>
                    <div className="started__tiles">
                        <div className="started__tile" style={{width:'400px',height:'400px' ,textAlign:'center'}}>
                            <img src={Buy}></img>
                            <p>Order your <strong>genie Smart Tag</strong></p>
                            <a className="text-white font-weight-bold" href="https://tag8.in/tag8-store-new/public/products/genieSmartTag.php">BUY NOW</a>
                        </div>
                        <div className="started__tile" style={{width:'400px',height:'400px',textAlign:'center'}}>
                            <img src={SignUp}></img>
                            <p>Sign-up for a genie account</p>
                            <a className="text-white font-weight-bold" href="/signup">SIGN-UP</a>
                        </div>
                    </div>
                </div>
            }
            <h1 style={{ padding: '50px',textAlign:'center' }}>How it works?</h1>
            <center>
            <img className="started__how" src={How} style={{textAlign:'center'}}></img>
            </center>
        </div >
    )
}

export default Started
