import React from 'react'
import Footer from '../footer/footer.component.jsx'
import Navbar from '../header/header.component.jsx'
import './legalterms.component.css'
import Privacypolicy from './privacypolicy.component.jsx';
function Legalterms() {
    return (
        <div>
            <div className="legalterms">
                <h1 style={{ marginTop: '0px' }}>Terms of Use</h1>
                <p>3Y Ventures LLP owns and operates www.genie.tag8.in hereafter referred to as ‘the website’. All information including documents, content, materials, software and services, hereafter referred to as ‘the content’, is available to you only for your personal, non-commercial use subject to the conditions of use set forth in the present agreement, referred to as ‘Conditions of Use’. By accessing, browsing and/or using the website, you acknowledge that you have read, understood and agreed to be bound by the Conditions of Use and to comply with all applicable laws and regulations, including but not limited to all applicable export and re-export control laws and regulations.

                    <br></br> <br></br>
                    <div></div>
                     Your use of the website will be subject to the most recently updated version of the Conditions of Use posted on the website. It is your responsibility to review the Conditions of Use every time you intend to use the website. If you do not agree to the Conditions of Use, we urge you not to use the website.

                    <br></br>
                    <br></br>
                    <div></div>
                    By purchasing, registering or activating genie Smart Tag, the Customer acknowledges that they have read and agree with the present Conditions of Use and our Privacy Notice.

                    <br></br>
                    <br></br>
                    <div></div>
                    A user of the website is hereinafter referred to as the ‘User’ or ‘You’. A person activating and/or purchasing genie Smart Tag is hereinafter defined as the ‘Customer’. The User who finds a registered and/or activated product and reports their find to us is defined as the ‘Finder’.</p>
                <h1>Intellectual Property Right</h1>
                <p>You acknowledge and accept that all content and material available on this site are protected by copyrights, trademarks, service marks, patents, trade secrets and/or other proprietary rights and laws of the Government of India. Except as expressly authorised by us, you do not have the right to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit, or create derivative works from such materials or content. You may print or download the content from the website for your personal, non-commercial use provided you keep intact all copyright and other proprietary notices.

                    <br></br>
                    <br></br>
                    <div></div>
                    3Y Ventures LLP owns all the trademarks and service marks, hereafter referred to as ‘Trademarks’, displayed on this site. Nothing on this site should be construed as a license to use the Trademarks. Your use of the Trademarks displayed on this site is strictly prohibited and construed as a punishable offense. </p>
                <h1 style={{ lineHeight: '35px' }}>Ownership of products and services registered on www.genie.tag8.in</h1>
                <p>By purchasing, registering or activating genie Smart Tag, the Customer acknowledges and accepts that:
                    <ul>
                        <li>The Customer is the legal owner of the registered pet or has the authority from the owner to do so</li><br></br>
                        <li>The Customer is over the age of 18, the age of majority as mandated by the Government of India</li><br></br>
                        <li>The mailing address, contact number and email address provided are indeed that of Customer and are valid</li><br></br>
                        <li>The email address indicated is valid and belongs to the Customer, or in the alternative, that the Customer has permission to use the provided email address</li><br></br>
                        <li>The Customer is the owner of the credit card or debit card used to purchase items on the website, or has the owner’s permission to purchase items from the website site by using the owner’s credit card or debit card.</li><br></br>
                    </ul>
                </p>
                <h1>Service Length</h1>
                <p>In general, genie Smart Tag is valid for the lifetime of the tag. </p>
                <h1>Communication</h1>
                <p>Occasionally, we may wish to contact you about its services.
                    <ul>
                        <li> If you wish to receive marketing information at this time but change your mind later, you will have the option to have your name removed by clicking on the link in the email or contacting us at support@tag8.in</li>
                        <li>You can unsubscribe by clicking on the link available in the information e-mails sent to you.</li>
                        <li>We reserve the right to contact you regarding its normal services.</li>
                    </ul>
                </p>
                <h1>Communication between the Finder and Owner</h1>
                <p>The Owner and Finder communicate directly in order to have the pet returned. We will not be held liable for any communication, security or theft-related incidents. When attempting to contact you as part of providing service, we will use the means of contact pertaining to your subscription and the profile information you provided. Contacting you is on a best effort only basis and we are not liable for situations which arise because you did not receive notifications, whether that’s due to our performance, a third party’s or your own.</p>
                <h1>Indemnification</h1>
                <p>You agree to defend, indemnify, and hold harmless 3Y Ventures LLP and its subsidiary and other affiliated companies, and their employees, contractors, officers, and directors from all liabilities, claims, and expenses, including legal fees, which arise from your use or misuse of this website. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses. </p>
                <h1>Securing your account</h1>
                <p>You are responsible for maintaining the confidentiality of your account and its password, and are fully responsible for all activities that occur under your password or account. You agree to:
                    <ul>
                        <li>Immediately notify us of any unauthorised use of your password or account or any other breach of security,</li>
                        <li>Ensure that you exit from your account at the end of each session. We cannot and will not be liable for any loss or damage arising from your failure to comply with this Section.</li>
                    </ul>
                    You understand that the technical processing and transmission of the Service, including your Content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. You understand that the Service and software embodied within the Service may include security components that permit digital materials to be protected, and that use of these materials is subject to usage rules set by us and/or content providers who provide content to the Service. You agree not to attempt to override or circumvent any of the usage rules embedded into the Service. Any unauthorised reproduction, publication, further distribution or public exhibition of the materials provided on the Service, in whole or in part, is strictly prohibited. </p>
                <h1>Disclaimer of Warranty and Exclusion of Liability</h1>
                <p>3Y Ventures LLP makes reasonable efforts to provide you with the most current and accurate information on the website. However, we do not promise that all content on the website is error free or updated. Neither we nor any of our consultants for the website will be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of information on the website.

                    <br></br>Notwithstanding the foregoing, and to the fullest extent permissible by applicable Law, everything on this site and all information, content, materials, products (including software) and services included or otherwise made available to you through this site are provided to you ‘as is’, without warranty of any kind either expressed or implied, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose or non-infringement. We make no representations or warranties of any kind, express or implied as to the operation of this site or the information, content, materials, products (including software) or services included on or otherwise made available to you through this site, unless otherwise specified in writing. You expressly agree that your use of this site is at your own risk.

                    <br></br>Any Customer using the services agrees that, in connection with any and all products provided and services performed by 3Y Ventures LLP shall not be liable for a failure to recover any pet of the Customer bearing genie Smart Tag.

                    <br></br>3Y Ventures LLP is not an insurance company and does not offer any insurance coverage for any losses, costs or expenses relating to the lost or recovered items or animals or pets.

                    <br></br>3Y Ventures LLP does not guarantee that the lost pet will be found. genie Smart Tag is not a GPS system, RIFD, implanted chip, or electronic tracking or locating device of any kind.

                    <br></br>Under no circumstances shall 3Y Ventures LLP, its affiliates or its subsidiaries be liable for any direct, indirect, punitive, incidental, special, or consequential damages that result from the use of, or inability to use, this website, genie Smart Tag or any and all loss that result from or arise out of the services provided by us to a Customer. This limitation applies whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if 3Y Ventures LLP has been advised of the possibility of such damage. Because some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, our liability in such jurisdictions shall be limited to the extent permitted by law.

                    <br></br>The Customer further acknowledges that activating their genie Smart Tag ID number and creating their online account on the website database is the sole responsibility of the Customer, and not of 3Y Ventures LLP. It is also the responsibility of the Customer to ensure that information is accurate and updated. 3Y Ventures LLP is not responsible for any of the information contained in the Customer's account. 3Y Ventures LLP does not warrant the authenticity or accuracy of the information contained in the Customer's account. The maintenance of accurate and current information in the Customer's account is the sole responsibility of the Customer. The Customer will hold 3Y Ventures LLP harmless for any lapse in service or access to the Customer account which results from any force outside of 3Y Ventures LLP’s reasonable control or influence including, but not limited to, power outages, website hosting interruptions, telecommunication problems, call centre interruption, software problems or 3Y Ventures LLP’s termination and/or dissolution.</p>
                <h1>External links</h1>
                <p>This website may be linked to, or have included within, other websites on the Internet. 3Y Ventures LLP is not responsible for any links to this site or their content. Your linking to any sites from this site is at your own risk. By linking to a site or permitting a link to this site, 3Y Ventures LLP does not endorse the site operator or the content of the linked site.</p>
                <h1>Monitoring the Website</h1>
                <p>The Customer acknowledges and agrees that 3Y Ventures LLP has the right to monitor the website, its website-related services, and to disclose any information necessary to operate the website, to protect itself and to comply with legal obligations or governmental requests. 3Y Ventures LLP also reserves the right to prohibit any User from using the website and related services who, as determined by 3Y Ventures LLP, in its sole discretion, violates these Conditions of Use. Such prohibition may occur with or without notice to the User. </p>
                <h1>International Use</h1>
                <p>3Y Ventures LLP makes no representation that Content and materials on this site are appropriate or available for use in locations outside of India. Accessing Content and materials from territories where said Content and materials are illegal is prohibited. Those who choose to access this site from other locations do so of their own volition and are responsible for compliance with local laws. </p>
                <h1>Termination</h1>
                <p>3Y Ventures LLP reserves the right, in its sole discretion, to terminate your access to all or part of this site, with or without notice. </p>
                <h1>Choice of Law</h1>
                <p>If any disputes arise regarding your use of this website and this agreement, such disputes shall be resolved according to the laws of India, and you consent to the exclusive jurisdiction and venue of the courts, tribunals, agencies and other dispute resolution organisations in Mumbai, India in all disputes (1) arising out of, relating to, or concerning this site and/or this agreement, (2) in which this website and/or this agreement is an issue or a material fact, or (3) in which this website and/or this agreement is referenced in a document filed in a court or other dispute resolution organisation. Use of this web site is unauthorised in any jurisdiction that does not give full effect to all provisions of this agreement between you and 3Y Ventures LLP.

                    <br></br>Furthermore, in the unfortunate event of any claim or dispute arising out of or in connection with this website and/or these terms and conditions and/or any contract between you and 3Y Ventures LLP, you agree to engage in good faith with us in a process of mediation before commencing any arbitration or litigation. Any such mediation will take place in Mumbai, India.</p>
                <h1>Severed Provisions and Entire Agreement</h1>
                <p>If any provision of this agreement is unenforceable, that provision shall be deemed severed from this agreement and shall not affect the validity and enforceability of the remaining provisions.

                    <br></br>This Agreement, and the Privacy Policy, set forth the entire agreement and understanding between 3Y Ventures LLP and the User, the Finder and the Customer of the Site regarding the subject matter hereof and supersedes any prior representations, advertisements, statements, proposals, negotiations, discussions, understandings, or agreements regarding the same subject matter.

                    <br></br>If we decide to change or update our Conditions of Use, we will post those changes here. Please refer to this page on a continual basis. </p>
            </div>
            <Privacypolicy/>
            <Footer></Footer>
        </div>
    )
}

export default Legalterms
