import React, { Component } from "react";
import './reportlost.component.css';
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie('token');


const xyz = {
  lat: 18.978354799999998,
  lng: 72.8397202,
};

export class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "React",
      latitude: "",
      longitude: "",
    };

    this.getCoordinates = this.getCoordinates.bind(this);
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getCoordinates(position) {
    // console.log("======================>", position);
    this.setState({
      latitude: parseFloat(position.coords.latitude),
      longitude: parseFloat(position.coords.longitude)
    });
  }

  render() {
    const coords = { lat: this.state.latitude, lng: this.state.longitude };
    return (
      <div>
        <Map
          google={this.props.google}
          zoom={15}
          initialCenter={coords}
          center={coords}
          className="googlemaps"
        >
          <Marker position={coords} />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAAwak05wJ9r_l4XUfs468Zu1CmF-It0y8"
})(MapContainer);


// const mapStyles = {
//   width: "60%",
//   height: "20%",
// };

// const xyz = {
//   lat: 18.978354799999998,
//   lng: 72.8397202,
// };

// let token = usertoken

// export class MapContainer extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: "React",
//       latitude: "",
//       longitude: "",
//     };
//     this.getCoordinates = this.getCoordinates.bind(this);
//   }

//   componentDidMount() {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(this.getCoordinates);
//     } else {
//       alert("Geolocation is not supported by this browser.");
//     }
//   }

//   getCoordinates(position) {
//     this.setState({
//       latitude: parseFloat(position.coords.latitude),
//       longitude: parseFloat(position.coords.longitude)
//     });
//   }

//   render() {
//     const coords = { lat: this.state.latitude, lng: this.state.longitude };
//     return (
//       <div>
//         <Map
           
//           google={this.props.google}
//           zoom={15}
//           style={mapStyles}
//           initialCenter={coords}
//           center={coords}
//         >
//           <Marker position={coords} />
//         </Map>
//       </div>
//     );
//   }
// }

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyAAwak05wJ9r_l4XUfs468Zu1CmF-It0y8"
// })(MapContainer);