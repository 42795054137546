import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import './addnewrecord.component.css';
import Button from '@material-ui/core/Button';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AlarmIcon from '@material-ui/icons/Alarm';
import LoopIcon from '@material-ui/icons/Loop';
import axios from 'axios';

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
let usertoken = readCookie('token');

export default class AddNewRecord extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            imageUrl:null,
            selectedImage:null,
            vaccination_reminder:false,
            surgery_reminder:false,
            allergy_reminder:false,
            medication_reminder:false,
            medicalcondition_reminder:false,
            measurement_reminder:false,
            tag_number: localStorage.getItem('tag_number'),
            Type:'',
            vaccination_id:'',
            Vaccination_name:'',
            VaccinationDoctor_name : '',
            VaccinationAdministration_date: '',
            VaccinationNotes: '',
            VaccinationTitle : '',
            VaccinationEverynumber:'',
            VaccinationEvery : '',
            VaccinationNext : '',
            VaccinationRemind_me : '', 
            VaccinationReminderNotes: '',

            allergy_id:'',
            Allergy_name : '',
            AllergyDoctor_name : '',
            AllergyDiagnosis_date: '',
            AllergySeverity : '', 
            AllergyNotes: '',
            AllergyTitle : '', 
            AllergyEverynumber:'',
            AllergyEvery : '', 
            AllergyNext : '',
            AllergyRemind_me : '',
            AllergyReminderNotes: '',

            surgery_id:'',
            Surgery_name : '',
            SurgeryDoctor_name : '',
            Surgery_date: '',
            SurgerySeverity : '',
            SurgeryNotes: '',
            SurgeryTitle : '',
            SurgeryEverynumber:'',
            SurgeryEvery : '',
            SurgeryNext : '',
            SurgeryRemind_me : '',
            SurgeryReminderNotes: '',

            medication_id:'',
            Medication_name : '', 
            MedicationDoctor_name : '', 
            Medication_startdate: '', 
            Medication_enddate: '', 
            MedicationDosage : '', 
            MedicationNotes: '',
            MedicationTitle : '', 
            MedicationEverynumber:'',
            MedicationEvery : '', 
            MedicationNext : '', 
            MedicationRemind_me : '', 
            MedicationReminderNotes: '',

            medicalcondition_id:'',
            Medicalcondition_type : '', 
            MedicalconditionDoctor_name : '', 
            MedicalconditionDiagnosed_date: '', 
            Medicalcondition_enddate: '', 
            MedicalconditionSeverity : '', 
            MedicalconditionNotes: '',
            MedicalconditionTitle : '', 
            MedicalconditionEverynumber:'',
            MedicalconditionEvery : '', 
            MedicalconditionNext : '', 
            MedicalconditionRemind_me : '', 
            MedicalconditionReminderNotes: '',

            measurement_id:'',
            Height : '', 
            Weight : '', 
            Measurement_date: '', 
            Measuredby: '', 
            MeasurementNotes: '',
            MeasurementTitle : '', 
            MeasurementEverynumber:'',
            MeasurementEvery : '', 
            MeasurementNext : '', 
            MeasurementRemind_me : '', 
            MeasurementReminderNotes: '',

            Vaccination_Reportname : '',
            Vaccination_Reportlocation : '',
            Allergy_Reportname : '',
            Allergy_Reportlocation : '',
            Surgery_Reportname : '',
            Surgery_Reportlocation : '',
            Medication_Reportname : '',
            Medication_Reportlocation : '',
            Medicalcondition_Reportname : '',
            Medicalcondition_Reportlocation : '',
            Measurement_Reportname : '',
            Measurement_Reportlocation : '',
            message:'',
            
        }
    }

    changeHandler =e=>{
        this.setState({[e.target.name]:e.target.value})
    }

    handleChange=event=> {
        if(event.target.files[0]){
            var sizeinmb = event.target.files[0].size/(1024*1024)
            console.log(sizeinmb)
            if(sizeinmb > 2){
                this.setState({message:'File size should be less than 2 MB'})
            }
            else{
                this.setState({message:''})
                this.setState({
                    selectedImage: event.target.files[0],
                    imageUrl: URL.createObjectURL(event.target.files[0])
                })
            }
        }
    }

    submitHandler=e=>{
        e.preventDefault()
        console.log(this.state)
        axios.post('https://dashboard.tag8.co.in/api/pet/MedicalActivation/'+localStorage.getItem('tag_number'), this.state, {headers: {'Authorization': usertoken}}).then((res)=>{
            console.log(res)
            window.location.reload()
            const fd = new FormData;
            fd.append('image', this.state.selectedImage);   
            fd.append('tag_number', this.state.tag_number);
            fd.append('Type', this.state.Type);
            if(res.data.Type === "Vaccination"){
                fd.append('vaccination_id', res.data.vaccination_id);
            }
            else if(res.data.Type === "Allergy"){
                fd.append('allergy_id', res.data.allergy_id);
            }
            else if(res.data.Type === "Surgery"){
                fd.append('surgery_id', res.data.surgery_id);
            }
            else if(res.data.Type === "Medication"){
                fd.append('medication_id', res.data.medication_id);
            }
            else if(res.data.Type === "Medicalcondition"){
                fd.append('medicalcondition_id', res.data.medicalcondition_id);
            }
            else if(res.data.Type === "Measurement"){
                fd.append('measurement_id', res.data.measurement_id);
            }
            console.log(fd)
            axios.post('https://dashboard.tag8.co.in/api/pet/report-upload',fd,{
                'Accept': 'application/json'
            }).then((response)=>{
                console.log(response)
            }).catch((error)=>{
                console.log(error)
            })
        }).catch((error)=>{
            console.log(error)
            this.props.closeDialog()
        })
    }

    render(){
        const {Type, vaccination_reminder, surgery_reminder, allergy_reminder, medication_reminder, medicalcondition_reminder, measurement_reminder } = this.state
        return(
            <div style={{width:'550px', border:'2px solid black'}} className="p-4 outer-med-add-record-div mq-med-con-add-record">
                <form onSubmit={this.submitHandler} className="p-2">
                    <div className="row">
                        <div className="col-6 select-type-div">
                            <span className="label-petdata">Type</span><br/>
                            <select style={{backgroundColor:'white'}} name="Type" className="input-pet-medical-dropdown" value={Type} required onChange={this.changeHandler}>
                                <option hidden></option>
                                <option value="Vaccination">Vaccination</option>
                                <option value="Surgery">Surgery</option>
                                <option value="Allergy">Allergy</option>
                                <option value="Medication">Medication</option>
                                <option value="Medicalcondition">Medical Condition</option>
                                <option value="Measurement">Measurement</option>
                            </select>
                        </div>
                    </div>
                    {
                        Type === 'Vaccination' ?
                        <div className="upper-form-update" style={{width:'100%'}}>
                            <div className="row mt-3 first-line-update" style={{display:'flex', flexDirection:'row'}}>
                                <div className="col">
                                    <span className="label-petdata">Vaccination Name *</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="20" name="Vaccination_name" required onChange={this.changeHandler}/>
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Doctor's Name</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="25" name="VaccinationDoctor_name" onChange={this.changeHandler}/><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="label-petdata">Administration Date *</span><br/>
                                    <input style={{backgroundColor:'white'}} type="date" className="input-pet-medical" name="VaccinationAdministration_date" required onChange={this.changeHandler} />
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 mr-3">
                                    <span className="label-petdata">Notes</span><br/>
                                    <textarea className="txt_notes" rows="2" name="VaccinationNotes" style={{width:'100%',border:'2px solid #e4e4e4'}} onChange={this.changeHandler}></textarea><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col mr-3  med-rem-div">
                                    <Button onClick={()=>{this.setState({vaccination_reminder:!vaccination_reminder})}} className="bg-white" style={{'padding': '0px', 'font-size': '12px', 'color': '#b4b4b4', 'font-weight': '400', textTransform:'none'}}><AddAlertIcon style={{'font-size': '16px'}}/><span className="ml-2">Set Reminder</span></Button><br/>
                                    {
                                        vaccination_reminder ?
                                        <div className="inner-med-rem-div mq-remdiv" style={{width:'350px'}}>
                                            <div className="row ml-1 reminder_title mr-1 mt-3 p-1 bg-warning">
                                                <p className="ml-2 mb-0 font-weight-bold">Vaccination</p>
                                                <Button  className="p-0 myexp-btn" style={{marginLeft:'80px'}} onClick={()=>{this.setState({vaccination_reminder:!vaccination_reminder})}}><ExpandMoreIcon /></Button>
                                            </div>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Reminder Title</p>
                                        <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="VaccinationTitle" onChange={this.changeHandler}/><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Next Appointment Date</p>
                                        <SkipNextIcon className="ml-1"/><input style={{backgroundColor:'white'}} type="date" className="input-pet-detail-nextdate ml-2" name="VaccinationNext" onChange={this.changeHandler}/><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Remind Me</p>
                                        <AlarmIcon className="ml-1"/>
                                        <select style={{backgroundColor:'white'}} className="input-pet-detail-nextdate ml-2" value={this.state.VaccinationRemind_me} name="VaccinationRemind_me" onChange={this.changeHandler}>
                                            <option hidden></option>
                                            <option value="On The Same Day">On The Same Day</option>
                                            <option value="1 Day Before">1 Day Before</option>
                                            <option value="2 Days Before">2 Days Before</option>
                                            <option value="3 Days Before">3 Days Before</option>
                                            <option value="15 Days Before">15 Days Before</option>
                                            <option value="1 Month Before">1 Month Before</option>
                                        </select><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Notes</p>
                                        <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="VaccinationReminderNotes" onChange={this.changeHandler}/><br/>
                                        <p className="ml-2 mt-1 label-petdata rem-cal-para">*Reminders can be viewed in calendar section.</p><br/>
                                    </div> : ""
                                    }
                                </div>
                            </div>
                            <div className="mt-3">
                            <span className="label-petdata">Report</span><br/>
                            <input type="file" onChange={this.handleChange} style={{'padding': '0px', 'height': '100%', 'width': '100%'}} className="input-pet-details-reports"/><br/>
                            <div className="text-left mt-1">{this.state.message}</div>
                            
                        </div>
                        </div> : ""
                    }
                    {
                        Type === 'Surgery' ?
                        <div className="upper-form-update" style={{width:'100%'}}>
                            <div className="row mt-3 first-line-update" style={{display:'flex', flexDirection:'row'}}>
                                <div className="col">
                                    <span className="label-petdata">Surgery Name *</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="20" name="Surgery_name" required onChange={this.changeHandler} />
                                </div>
                                <div className="col">
                                    <span className="label-petdata">Doctor's Name</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="25" name="SurgeryDoctor_name" onChange={this.changeHandler}/><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="label-petdata">Surgery Date *</span><br/>
                                    <input style={{backgroundColor:'white'}} style={{backgroundColor:'white'}} type="date" className="input-pet-medical" name="Surgery_date" required onChange={this.changeHandler} />
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Severity</span><br/>
                                    <select style={{backgroundColor:'white'}} style={{backgroundColor:'white'}} className="input-pet-medical-dropdown" name="SurgerySeverity" value={this.state.SurgerySeverity} onChange={this.changeHandler}>
                                        <option hidden></option>
                                        <option value="Minor">Minor</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Major">Major</option>
                                        <option value="Critical">Critical</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 mr-3">
                                    <span className="label-petdata">Notes</span><br/>
                                    <textarea className="txt_notes" rows="2" name="SurgeryNotes" style={{width:'95%',border:'2px solid #e4e4e4'}} onChange={this.changeHandler}></textarea><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col mr-3  med-rem-div">
                                    <Button onClick={()=>{this.setState({surgery_reminder:!surgery_reminder})}} className="bg-white" style={{'padding': '0px', 'font-size': '12px', 'color': '#b4b4b4', 'font-weight': '400', textTransform:'none'}}><AddAlertIcon style={{'font-size': '16px'}}/><span className="ml-2">Set Reminder</span></Button><br/>
                                    {
                                        surgery_reminder ?
                                        <div className="inner-med-rem-div mq-remdiv" style={{width:'350px'}}>
                                            <div className="row ml-1 reminder_title mr-1 mt-3 p-1 bg-warning">
                                                <p className="ml-2 mb-0 font-weight-bold">Surgery</p>
                                                <Button className="p-0 myexp-btn" style={{marginLeft:'80px'}} onClick={()=>{this.setState({surgery_reminder:!surgery_reminder})}}><ExpandMoreIcon /></Button>
                                            </div>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Reminder Title</p>
                                        <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="SurgeryTitle" onChange={this.changeHandler}/><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Next Appointment Date</p>
                                        <SkipNextIcon className="ml-1"/><input style={{backgroundColor:'white'}} type="date" className="input-pet-detail-nextdate ml-2" name="SurgeryNext" onChange={this.changeHandler}/><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Remind Me</p>
                                        <AlarmIcon className="ml-1"/>
                                        <select style={{backgroundColor:'white'}} className="input-pet-detail-nextdate ml-2" name="SurgeryRemind_me" value={this.state.SurgeryRemind_me} onChange={this.changeHandler}>
                                            <option hidden></option>
                                            <option value="On The Same Day">On The Same Day</option>
                                            <option value="1 Day Before">1 Day Before</option>
                                            <option value="2 Days Before">2 Days Before</option>
                                            <option value="3 Days Before">3 Days Before</option>
                                            <option value="15 Days Before">15 Days Before</option>
                                            <option value="1 Month Before">1 Month Before</option>
                                        </select><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Notes</p>
                                        <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="SurgeryReminderNotes" onChange={this.changeHandler}/><br/>
                                        <p className="ml-2 mt-1 label-petdata rem-cal-para">*Reminders can be viewed in calendar section.</p><br/>
                                    </div> : ""
                                    }
                                </div>
                            </div>
                            <div className="mt-3">
                                <span className="label-petdata">Report</span><br/>
                                <input type="file" onChange={this.handleChange} style={{'padding': '0px', 'height': '100%', 'width': '100%'}} className="input-pet-details-reports"/><br/>
                                <div className="text-left mt-1">{this.state.message}</div>
                            </div>
                        </div> : ""
                    }
                    {
                        Type === 'Allergy' ?
                        <div className="upper-form-update" style={{width:'100%'}}>
                            <div className="row mt-3 first-line">
                                <div className="col">
                                    <span className="label-petdata">Allergy Name *</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="20" name="Allergy_name" required onChange={this.changeHandler} />
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Doctor's Name</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="25" name="AllergyDoctor_name" onChange={this.changeHandler}/><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="label-petdata">Diagnosis Date *</span><br/>
                                    <input style={{backgroundColor:'white'}} type="date" className="input-pet-medical" name="AllergyDiagnosis_date" required onChange={this.changeHandler} />
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Severity</span><br/>
                                    <select style={{backgroundColor:'white'}} className="input-pet-medical-dropdown" name="AllergySeverity" value={this.state.AllergySeverity} onChange={this.changeHandler}>
                                        <option hidden></option>
                                        <option value="RAST 0 - Undetectable">RAST 0 - Undetectable</option>
                                        <option value="RAST 1 - Low">RAST 1 - Low</option>
                                        <option value="RAST 2- Moderate">RAST 2- Moderate</option>
                                        <option value="RAST 3 - High">RAST 3 - High</option>
                                        <option value="RAST 4 - Very High">RAST 4 - Very High</option>
                                        <option value="RAST 5 - Ultra High">RAST 5 - Ultra High</option>
                                        <option value="RAST 6 - Extremely High">RAST 6 - Extremely High</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 mr-3">
                                    <span className="label-petdata">Notes</span><br/>
                                    <textarea className="txt_notes" rows="2" name="AllergyNotes" style={{width:'95%',border:'2px solid #e4e4e4'}} onChange={this.changeHandler}></textarea><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col mr-3 med-rem-div">
                                    <Button onClick={()=>{this.setState({allergy_reminder:!allergy_reminder})}} className="bg-white" style={{'padding': '0px', 'font-size': '12px', 'color': '#b4b4b4', 'font-weight': '400', textTransform:'none'}}><AddAlertIcon style={{'font-size': '16px'}}/><span className="ml-2">Set Reminder</span></Button><br/>
                                    {
                                        allergy_reminder ?
                                        <div className="inner-med-rem-div mq-remdiv" style={{width:'350px'}}>
                                            <div className="row ml-1 reminder_title mr-1 mt-3 p-1 bg-warning">
                                                <p className="ml-2 mb-0 font-weight-bold">Allergy</p>
                                                {/* className="float-right text-right exp-btn p-0" style={{marginLeft:'240px', marginTop:'-20px'}} */}
                                                <Button className="p-0 myexp-btn" style={{marginLeft:'80px'}}  onClick={()=>{this.setState({allergy_reminder:!allergy_reminder})}}><ExpandMoreIcon /></Button>
                                            </div>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Reminder Title</p>
                                            <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="AllergyTitle" onChange={this.changeHandler}/><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Next Appointment Date</p>
                                            <SkipNextIcon className="ml-1"/><input style={{backgroundColor:'white'}} type="date" className="input-pet-detail-nextdate ml-2" name="AllergyNext" onChange={this.changeHandler}/><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Remind Me</p>
                                            <AlarmIcon className="ml-1"/>
                                            <select style={{backgroundColor:'white'}} className="input-pet-detail-nextdate ml-2" name="AllergyRemind_me" value={this.state.AllergyRemind_me} onChange={this.changeHandler}>
                                                <option hidden></option>
                                                <option value="On The Same Day">On The Same Day</option>
                                                <option value="1 Day Before">1 Day Before</option>
                                                <option value="2 Days Before">2 Days Before</option>
                                                <option value="3 Days Before">3 Days Before</option>
                                                <option value="15 Days Before">15 Days Before</option>
                                                <option value="1 Month Before">1 Month Before</option>
                                            </select><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Notes</p>
                                            <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="AllergyReminderNotes" onChange={this.changeHandler}/><br/>
                                            <p className="ml-2 mt-1 label-petdata rem-cal-para">*Reminders can be viewed in calendar section.</p><br/>
                                        </div> : ""
                                    }
                                </div>
                            </div>
                            <div className="mt-3">
                                <span className="label-petdata">Report</span><br/>
                                <input type="file" onChange={this.handleChange} style={{'padding': '0px', 'height': '100%', 'width': '100%'}} className="input-pet-details-reports"/><br/>
                                <div className="text-left mt-1">{this.state.message}</div>
                            </div>
                        </div> : ""
                    }
                    {
                        Type === 'Medication' ?
                        <div className="upper-form-update" style={{width:'100%'}}>
                            <div className="row mt-3 first-line">
                                <div className="col">
                                    <span className="label-petdata">Medication Name *</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="20" name="Medication_name" required onChange={this.changeHandler} />
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Doctor's Name</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="25" name="MedicationDoctor_name" onChange={this.changeHandler}/><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="label-petdata">Medication Start Date *</span><br/>
                                    <input style={{backgroundColor:'white'}} type="date" className="input-pet-medical" name="Medication_startdate" required onChange={this.changeHandler} />
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Medication End Date *</span><br/>
                                    <input style={{backgroundColor:'white'}} type="date" className="input-pet-medical" name="Medication_enddate" onChange={this.changeHandler} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="label-petdata">Dosage</span><br/>
                                    <input type="textbox" className="input-pet-medical" name="MedicationDosage" onChange={this.changeHandler} /><br/>
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 mr-3">
                                    <span className="label-petdata">Notes</span><br/>
                                    <textarea className="txt_notes" rows="2" name="MedicationNotes" style={{width:'95%',border:'2px solid #e4e4e4'}} onChange={this.changeHandler}></textarea><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col mr-3 set-rem-div mq-set-rem-div-medcon">
                                    <Button onClick={()=>{this.setState({medication_reminder:!medication_reminder})}} className="bg-white" style={{'padding': '0px', 'font-size': '12px', 'color': '#b4b4b4', 'font-weight': '400', textTransform:'none'}}><AddAlertIcon style={{'font-size': '16px'}}/><span className="ml-2">Set Reminder</span></Button><br/>
                                    {
                                        medication_reminder ?
                                        <div className="inner-med-rem-div mq-remdiv" style={{width:'350px'}}>
                                            <div className="row ml-1 reminder_title mr-1 mt-3 p-1 bg-warning">
                                                <p className="ml-2 mb-0 font-weight-bold">Medication</p>
                                                <Button className="p-0 myexp-btn" style={{marginLeft:'80px'}}  onClick={()=>{this.setState({medication_reminder:!medication_reminder})}}><ExpandMoreIcon /></Button>
                                            </div>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Reminder Title</p>
                                            <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="MedicationTitle" onChange={this.changeHandler}/><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Next Appointment Date</p>
                                            <SkipNextIcon className="ml-1"/><input style={{backgroundColor:'white'}} type="date" className="input-pet-detail-nextdate ml-2" name="MedicationNext" onChange={this.changeHandler}/><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Remind Me</p>
                                            <AlarmIcon className="ml-1"/>
                                            <select style={{backgroundColor:'white'}} className="input-pet-detail-nextdate ml-2" name="MedicationRemind_me" value={this.state.MedicationRemind_me} onChange={this.changeHandler}>
                                                <option hidden></option>
                                                <option value="On The Same Day">On The Same Day</option>
                                                <option value="1 Day Before">1 Day Before</option>
                                                <option value="2 Days Before">2 Days Before</option>
                                                <option value="3 Days Before">3 Days Before</option>
                                                <option value="15 Days Before">15 Days Before</option>
                                                <option value="1 Month Before">1 Month Before</option>
                                            </select><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Notes</p>
                                            <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="MedicationReminderNotes" onChange={this.changeHandler}/><br/>
                                            <p className="ml-2 mt-1 label-petdata rem-cal-para">*Reminders can be viewed in calendar section.</p><br/>
                                        </div> : ""
                                    }
                                </div>
                            </div>
                            <div className="mt-3">
                                <span className="label-petdata">Report</span><br/>
                                <input type="file" onChange={this.handleChange} style={{'padding': '0px', 'height': '100%', 'width': '100%'}} className="input-pet-details-reports"/><br/>
                                <div className="text-left mt-1">{this.state.message}</div>
                            </div>
                        </div> : ""
                    }
                    {
                        Type === 'Medicalcondition' ?
                        <div className="upper-form-update" style={{width:'100%'}}>
                            <div className="row mt-3 first-line">
                                <div className="col">
                                    <span className="label-petdata">Medical Condition Type *</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="20" name="Medicalcondition_type" required onChange={this.changeHandler}/>
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Doctor's Name</span><br/>
                                    <input type="textbox" className="input-pet-medical" maxlength="25" name="MedicalconditionDoctor_name" onChange={this.changeHandler}/><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="label-petdata">Diagnosis Date *</span><br/>
                                    <input style={{backgroundColor:'white'}} type="date" className="input-pet-medical" name="MedicalconditionDiagnosed_date" required onChange={this.changeHandler} />
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Severity</span><br/>
                                    <input type="textbox" className="input-pet-medical" name="MedicalconditionSeverity" onChange={this.changeHandler} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 mr-3">
                                    <span className="label-petdata">Notes</span><br/>
                                    <textarea className="txt_notes" rows="2" name="MedicalconditionNotes" style={{width:'95%',border:'2px solid #e4e4e4'}} onChange={this.changeHandler}></textarea><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col mr-3 med-rem-div">
                                    <Button onClick={()=>{this.setState({medicalcondition_reminder:!medicalcondition_reminder})}} className="bg-white" style={{'padding': '0px', 'font-size': '12px', 'color': '#b4b4b4', 'font-weight': '400', textTransform:'none'}}><AddAlertIcon style={{'font-size': '16px'}}/><span className="ml-2">Set Reminder</span></Button><br/>
                                    {
                                        medicalcondition_reminder ?
                                        <div className="inner-med-rem-div mq-remdiv" style={{width:'350px'}}>
                                            <div className="row ml-1 mr-1 mt-3 reminder_title p-1 bg-warning">
                                                <p className="ml-2 mb-0 font-weight-bold">Medical Condition</p>
                                                <Button className="p-0 myexp-btn medicon-exp-btn" style={{marginLeft:'80px'}}  onClick={()=>{this.setState({medicalcondition_reminder:!medicalcondition_reminder})}}><ExpandMoreIcon /></Button>
                                            </div>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Reminder Title</p>
                                            <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="MedicalconditionTitle" onChange={this.changeHandler}/><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Next Appointment Date</p>
                                            <SkipNextIcon className="ml-1"/><input style={{backgroundColor:'white'}} type="date" className="input-pet-detail-nextdate ml-2" name="MedicalconditionNext" onChange={this.changeHandler}/><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Remind Me</p>
                                            <AlarmIcon className="ml-1"/>
                                            <select style={{backgroundColor:'white'}} className="input-pet-detail-nextdate ml-2" name="MedicalconditionRemind_me" value={this.state.MedicalconditionRemind_me} onChange={this.changeHandler}>
                                                <option hidden></option>
                                                <option value="On The Same Day">On The Same Day</option>
                                                <option value="1 Day Before">1 Day Before</option>
                                                <option value="2 Days Before">2 Days Before</option>
                                                <option value="3 Days Before">3 Days Before</option>
                                                <option value="15 Days Before">15 Days Before</option>
                                                <option value="1 Month Before">1 Month Before</option>
                                            </select><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Notes</p>
                                            <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="MedicalconditionReminderNotes" onChange={this.changeHandler}/><br/>
                                            <p className="ml-2 mt-1 label-petdata rem-cal-para">*Reminders can be viewed in calendar section.</p><br/>
                                        </div> : ""
                                    }
                                </div>
                            </div>
                            <div className="mt-3">
                                <span className="label-petdata">Report</span><br/>
                                <input type="file" onChange={this.handleChange} style={{'padding': '0px', 'height': '100%', 'width': '100%'}} className="input-pet-details-reports"/><br/>
                                <div className="text-left mt-1">{this.state.message}</div>
                            </div>
                        </div> : ""
                    }
                    {
                        Type === 'Measurement' ?
                        <div className="upper-form-update" style={{width:'100%'}}>
                            <div className="row mt-3 first-line">
                                <div className="col">
                                    <span className="label-petdata">Weight (kg) *</span><br/>
                                    <input type="number" className="input-pet-medical" maxlength="20" name="Weight" required onChange={this.changeHandler} />
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Height (cm)</span><br/>
                                    <input type="number" className="input-pet-medical" maxlength="25" name="Height" onChange={this.changeHandler}/><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="label-petdata">Measurement Date *</span><br/>
                                    <input style={{backgroundColor:'white'}} type="date" className="input-pet-medical" name="Measurement_date" required onChange={this.changeHandler}/>
                                </div>
                                <div className="col sever">
                                    <span className="label-petdata">Measured By</span><br/>
                                    <input type="textbox" className="input-pet-medical" name="Measuredby" onChange={this.changeHandler} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 mr-3">
                                    <span className="label-petdata">Notes</span><br/>
                                    <textarea className="inputs__notes" rows="2" name="MeasurementNotes" style={{width:'95%',border:'2px solid #e4e4e4'}} onChange={this.changeHandler}></textarea><br/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col mr-3 med-rem-div">
                                    <Button onClick={()=>{this.setState({measurement_reminder:!measurement_reminder})}} className="bg-white" style={{'padding': '0px', 'font-size': '12px', 'color': '#b4b4b4', 'font-weight': '400', textTransform:'none'}}><AddAlertIcon style={{'font-size': '16px'}}/><span className="ml-2">Set Reminder</span></Button><br/>
                                    {
                                        measurement_reminder ?
                                        <div className="inner-med-rem-div mq-remdiv" style={{width:'350px'}}>
                                            <div className="row ml-1 mr-1 mt-3 reminder_title p-1 bg-warning">
                                                <p className="ml-2 mb-0 font-weight-bold">Measurement</p>
                                                <Button className="p-0 myexp-btn measure-exp-btn" style={{marginLeft:'80px'}}  onClick={()=>{this.setState({measurement_reminder:!measurement_reminder})}}><ExpandMoreIcon /></Button>
                                            </div>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Reminder Title</p>
                                            <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="MeasurementTitle" onChange={this.changeHandler}/><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Next Appointment Date</p>
                                            <SkipNextIcon className="ml-1"/><input style={{backgroundColor:'white'}} type="date" className="input-pet-detail-nextdate ml-2" name="MeasurementNext" onChange={this.changeHandler}/><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Remind Me</p>
                                            <AlarmIcon className="ml-1"/>
                                            <select style={{backgroundColor:'white'}} className="input-pet-detail-nextdate ml-2" name="MeasurementRemind_me" value={this.state.MeasurementRemind_me} onChange={this.changeHandler}>
                                                <option hidden></option>
                                                <option value="On The Same Day">On The Same Day</option>
                                                <option value="1 Day Before">1 Day Before</option>
                                                <option value="2 Days Before">2 Days Before</option>
                                                <option value="3 Days Before">3 Days Before</option>
                                                <option value="15 Days Before">15 Days Before</option>
                                                <option value="1 Month Before">1 Month Before</option>
                                            </select><br/>
                                            <p className="mt-3 ml-2 label-petdata mb-0">Notes</p>
                                            <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="MeasurementReminderNotes" onChange={this.changeHandler}/><br/>
                                            <p className="ml-2 mt-1 label-petdata rem-cal-para">*Reminders can be viewed in calendar section.</p><br/>
                                        </div> : ""
                                    }
                                </div>
                            </div>
                            <div className="mt-3">
                                <span className="label-petdata">Report</span><br/>
                                <input type="file" onChange={this.handleChange} style={{'padding': '0px', 'height': '100%', 'width': '100%'}} className="input-pet-details-reports"/><br/>
                                <div className="text-left mt-1">{this.state.message}</div>
                            </div>
                        </div> : ""
                    }
                    <div className="mt-4 row text-center major-btn-div" style={{display:'flex',flexDirection:'row'}}>
                        <div className="col btn-add-record mq-add-rec">
                            <Button type="submit" className="bg-warning" style={{'width': '100%'}}>SUBMIT</Button>
                        </div>
                        <div className="col btn-add-record mq-add-rec">
                            <Button type="button" variant="contained" style={{'width': '100%'}} onClick={this.props.closeDialog} className="text-center">Close</Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}