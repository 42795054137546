import React, { useState, useEffect } from 'react';
import './App.css';
import {BrowserRouter as Router, HashRouter, Switch, Route} from "react-router-dom";
import Navbar from './components/header/header.component.jsx';
import Faqs from './components/faq/faq.component.jsx';
import Home from './components/home/home.component.jsx';
import Legalterms from './components/legalterms/legalterms.component.jsx';
import DolphinActivation from './components/dolphintrackeractivation/dolphintrackeractivation.component.jsx';
import EditPetDetails from '../src/components/editpetdetails/editpetdetails.component.jsx';
import EdiParentDetails from '../src/components/editparentdetails/editparentdetails.component.jsx';
import Calendar from './components/calendar/calendar.component.jsx';
import MedicalDashboard from '../src/components/medicaldashboard/medicaldashboard.component.jsx';
import ReportFound from './components/reportfound/reportfound.component.jsx';
import ReportLost from './components/reportlost/reportlost.component.jsx';
import PetPoster from '../src/components/petposter/petposter.component.jsx';
import BeaconPoster from './components/beaconposter/beaconposter.component.jsx';
import EditReportLost from '../src/components/reportlost/edit-report-lost/editreportlost.component.jsx';
import ViewBeaconDetails from '../src/components/reportfound/viewbeacondetails/viewbeacondetails.component.jsx';
import ProtectedRoute from './ProtectedRoute.js';
import PhotoGallery from '../src/components/photogallery/photogallery.jsx';

function App() {

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
    }
    let usertoken = readCookie('token');
    const uid = localStorage.getItem("uid");
    const dashboardRoute = "/dashboard/" + uid;
    const [isAuth, setIsAuth] = useState((usertoken !== null) ? true : false)
    useEffect(() => {
        setIsAuth((usertoken !== null) ? true : false);
    }, [isAuth])


  return (

    <div>
    <Router>
    <div className="App">
      <Navbar/>
          <Switch>
            
            
            
            <Route path={'/view-beacon-details/:tag_number'} render={(props) => <ViewBeaconDetails {...props} />} />
            {/* <Route path={'/QRtracker-activation/:tag_number'} render={(props) => <PetActivation {...props} />} /> */}
            <Route path={'/dolphin-activation/:tag_number'} render={(props) => <DolphinActivation {...props} />} />
            <Route path={'/edit-report-lost/:tag_number'} component={EditReportLost} isAuth={isAuth} />
            <Route path={'/beacon-poster/:tag_number'} component={BeaconPoster} isAuth={isAuth} />
            <Route path={'/reportlost/:tag_number'} component={ReportLost} isAuth={isAuth} />
            <Route path={'/scan/:tag_number'} component={ReportFound}/>
            <Route path={'/scan/:tag_number'} component={ReportFound}/>
            <Route path={'/beacon-owner-details/:tag_number'} component={EdiParentDetails} isAuth={isAuth} />
            <Route path={'/beacon-details/:tag_number'} component={EditPetDetails} isAuth={isAuth} />
            <Route path={'/legal-terms-and-conditions'}><Legalterms/></Route>
            <Route path={'/faq'}><Faqs/></Route>
            <Route path={'/'}><Home/></Route>
          </Switch> 

    </div>
    </Router>
    </div>
  );
}

export default App;
