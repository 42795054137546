import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../dolphintrackeractivation/dolphintrackeractivation.component.css';
import CatDog from '../../assets/catdog1.jpg';
import 'font-awesome/css/font-awesome.min.css';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Beacon from '../../assets/beaconPoster.png';
import { yellow } from '@material-ui/core/colors';

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
let usertoken = readCookie('token');

export default class DolphinActivation extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            imageUrl:null,
            selectedImage:null,
            tag_number: this.props.match.params.tag_number,
            tag_status:"active",
            valuable_type:"",
            pet_name:'',
            pet_breed:'',
            pet_color:'',
            pet_gender:'',
            pet_dob:'',
            pet_age:'',
            pet_ident:'',
            pet_microchip:'',
            pet_bloodgrp:'',
            pet_colorother:'',
            pet_breedother:'',
            pet_bloodgrpother:'',
            message:''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    componentDidMount(){
        $("#profile-image-uploadActi").on('click',function(){
            $("#choose-profile-imageActi").trigger('click');
        });

        $("#profile-image-uploadActi-1").on('click',function(){
            $("#choose-profile-imageActi").trigger('click');
        });
    }

    handleDropdownChange(event){
        this.setState({valuable_type: event.target.value});
    }

    handleChange(event) {
        if(event.target.files[0]){
            var sizeinmb = event.target.files[0].size/(1024*1024)
            console.log(sizeinmb)
            if(sizeinmb > 2){
                this.setState({message:'File size should be less than 2 MB'})
            }
            else{
                this.setState({message:''})
                this.setState({
                    selectedImage: event.target.files[0],
                    imageUrl: URL.createObjectURL(event.target.files[0])
                })
            }
        }
    }
    
    snackbarClose = (event) => {
        this.setState({ snackbaropen: false })
    }

    changeHandler =e=>{
        this.setState({[e.target.name]:e.target.value})
    }

    changeHandlerdate =e=>{
        this.setState({[e.target.name]:e.target.value})
        const birthDate = new Date(e.target.value); 
        const difference = Date.now() - birthDate.getTime();
        const age = new Date(difference);
        const ageyear = Math.abs(age.getUTCFullYear() - 1970);
        this.setState({pet_age : ageyear})
    }

    submitHandler = e=>{
        e.preventDefault()
        console.log(this.state)
        axios.post("/api/pet/petActivation/", this.state, {headers: {'Authorization': usertoken}})
        .then(response =>{
            console.log(response)
            if(response.data === 'Tag Already Activated'){
                this.setState({snackbaropen:true,snackbarmsg:response.data})
            }
            else if(response.data === 'Tag not Found by the specified Number'){
                this.setState({snackbaropen:true,snackbarmsg:response.data})
            }
            else if(response.data === 'Pet Details Registered'){
                window.location.href='/pet-parent-details/'+this.props.match.params.tag_number
                this.setState({snackbaropen:true,snackbarmsg:'Pet Details Registered'})
                const fd = new FormData;
                fd.append('image',this.state.selectedImage)
                fd.append('tag_number',this.props.match.params.tag_number)
                axios.post('https://dashboard.tag8.co.in/api/pet/petimage-upload',fd).then((res)=>{
                    console.log(res)
                    this.setState({snackbaropen:true,snackbarmsg:'Pet Details Registered'})
                }).catch((error)=>{
                    console.log(error)
                })
            }
        })
        .catch(error =>{
            console.log(error)
        })
    }

    render(){
        const {imageUrl, tag_number, valuable_type, pet_name, pet_breed, pet_color, pet_gender, pet_dob, pet_age, pet_ident, pet_microchip, pet_bloodgrp, pet_colorother, pet_breedother, pet_bloodgrpother} = this.state
        return(
            <div className="background py-5 bg-warning" style={{height:'100vh'}}>
                <div className="row">
                    <div className="col-xl-3 col-lg mt-3 pt-5 bg-dark dashboard__blacksidenav" style={{height:'90vh'}}>
                        <div className="ml-5" style={{height:120,width:120,textAlign:'center'}}>
                            <img alt="" src={Beacon}  className="image" />
                            <div className="overlay text-center">
                                <button className="button-image" id="profile-image-uploadActi"><i className="fa fa-edit fa-2x"></i></button>
                                <button className="text-white button-image" style={{position:'relative'}} onClick={this.deleteimage}><i className="fa fa-trash fa-2x"></i></button>
                            </div>
                            <input type="file" onChange={this.handleChange} id="choose-profile-imageActi"/>
                            <img alt="" id="uploadPreviewActi" src={imageUrl}/>
                        </div>
                        <div className="text-left mt-2">
                            <button className="button-image text-white ml-5" id="profile-image-uploadActi-1">Add Image<i className="fa fa-plus ml-3"></i></button>
                        </div>
                        <div className="text-white text-left mt-3 ml-4">{this.state.message}</div>
                        <div className="row petparentlinks">
                            <div className="col text-left ml-5" style={{margin:'auto'}}>
                                <ul className="mt-5 text-left pd-leftside-navigation">
                                    <li>
                                        <Button disabled className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Dashboard</Button><br/><br/>
                                    </li>
                                    <li>
                                        <Button disabled className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Calendar</Button><br/><br/>
                                    </li>
                                    <li>
                                        <Button disabled className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Dolphin Tracker Details</Button><br/><br/>
                                    </li>
                                    {/* <li>
                                        <Button disabled className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important'}}>Pet Medical Details</Button><br/><br/>
                                    </li> */}
                                </ul>
                            </div>
                        </div>  
                    </div>
                    <div className="col-xl-9 col-lg ">
                        <div className="container body-login bg-white mb-5">
                            <div className="row" id="pet-details-fill-2">
                                <div className="col px-3 py-4 text-left">
                                    <div className="row text-center px-4">
                                        <div className="col row-underline">
                                            <p><strong>Dolphin Tracker Activation</strong></p>
                                        </div>
                                    </div>

                                    <div className="mx-4 mt-4 mb-3">
                                        <form onSubmit={this.submitHandler} className="myform">
                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Owner ID *</span><br/>
                                                    <input type="textbox" className="input-pet-details" value={tag_number} name="tag_number" onChange={this.changeHandler} required readOnly/><br/>
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Select QR Type *</span><br/>
                                                    <select className="input-pet-details-dropdown" value={valuable_type} onChange={this.handleDropdownChange} required>
                                                        <option selected disabled hidden></option>
                                                        <option value="Cat">Dolphin Smart Padlock</option>
                                                        <option value="Dog">Dolphin Smart Tracker</option>
                                                    </select>
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">QR Name *</span><br/>
                                                    <input type="textbox" className="input-pet-details" maxLength="20" name="pet_name" value={pet_name} onChange={this.changeHandler} required/>
                                                </div><br/>

                                                <div className="col marginheight">
                                                    <span className="label-petdata">Date of Activation</span><br/>
                                                    <input type="date" className="input-pet-details" value={pet_dob} onChange={this.changeHandlerdate} name="pet_dob"/>
                                                </div>

































































































                                                {/* <div className="col marginheight">
                                                    <span className="label-petdata">Pet Breed</span><br/>
                                                    {
                                                        !valuable_type ?
                                                        <select className="input-pet-details-dropdown" >
                                                            <option selected disabled hidden></option>
                                                        </select> : <div></div>
                                                    }
                                                    {
                                                        valuable_type === 'Dog' ? 
                                                        <div>
                                                            <select  className="input-pet-details-dropdown" name="pet_breed" value={pet_breed} onChange={this.changeHandler}>
                                                                <option hidden></option>
                                                                <option value="AmericanPitbull">American Pit bull</option>
                                                                <option value="Cross Breed">Cross Breed</option>
                                                                <option value="German Shepherd">German Shepherd</option>
                                                                <option value="Golden Retriever">Golden Retriever</option>
                                                                <option value="Indian Paraigh Dog">Indian Paraigh Dog</option>
                                                                <option value="Labrador">Labrador</option>
                                                                <option value="Mongreal">Mongreal</option>
                                                                <option value="Mudhol">Mudhol</option>
                                                                <option value="Mixed Breed">Mixed Breed</option>
                                                                <option value="Pug">Pug</option>
                                                                <option value="Pomeranian">Pomeranian</option>
                                                                <option value="Rottweiler">Rottweiler</option>
                                                                <option value="Shihtzu">Shihtzu</option>
                                                                <option value="Siberian Husky">Siberian Husky</option>
                                                                <option value='Affenpinscher'>Affenpinscher</option>
                                                                <option value='Afghan Hound'>Afghan Hound</option>
                                                                <option value='Airedale Terrier'>Airedale Terrier</option>
                                                                <option value='Akita'>Akita</option>
                                                                <option value='Alaskan Malamute'>Alaskan Malamute</option>
                                                                <option value='American Staffordshire Terrier'>American Staffordshire Terrier</option>
                                                                <option value='American Water Spaniel'>American Water Spaniel</option>
                                                                <option value='Australian Cattle Dog'>Australian Cattle Dog</option>
                                                                <option value='Australian Shepherd'>Australian Shepherd</option>
                                                                <option value='Australian Terrier'>Australian Terrier</option>
                                                                <option value='Basenji'>Basenji</option>
                                                                <option value='Basset Hound'>Basset Hound</option>
                                                                <option value='Beagle'>Beagle</option>
                                                                <option value='Bearded Collie'>Bearded Collie</option>
                                                                <option value='Bedlington Terrier'>Bedlington Terrier</option>
                                                                <option value='Bernese Mountain Dog'>Bernese Mountain Dog</option>
                                                                <option value='Bichon Frise'>Bichon Frise</option>
                                                                <option value='Black And Tan Coonhound'>Black And Tan Coonhound</option>
                                                                <option value='Bloodhound'>Bloodhound</option>
                                                                <option value='Border Collie'>Border Collie</option>
                                                                <option value='Border Terrier'>Border Terrier</option>
                                                                <option value='Borzoi'>Borzoi</option>
                                                                <option value='Boston Terrier'>Boston Terrier</option>
                                                                <option value='Bouvier Des Flandres'>Bouvier Des Flandres</option>
                                                                <option value='Boxer'>Boxer</option>
                                                                <option value='Briard'>Briard</option>
                                                                <option value='Brittany'>Brittany</option>
                                                                <option value='Brussels Griffon'>Brussels Griffon</option>
                                                                <option value='Bull Terrier'>Bull Terrier</option>
                                                                <option value='Bulldog'>Bulldog</option>
                                                                <option value='Bullmastiff'>Bullmastiff</option>
                                                                <option value='Cairn Terrier'>Cairn Terrier</option>
                                                                <option value='Canaan Dog'>Canaan Dog</option>
                                                                <option value='Chesapeake Bay Retriever'>Chesapeake Bay Retriever</option>
                                                                <option value='Chihuahua'>Chihuahua</option>
                                                                <option value='Chinese Crested'>Chinese Crested</option>
                                                                <option value='Chinese Shar-Pei'>Chinese Shar-Pei</option>
                                                                <option value='Chow Chow'>Chow Chow</option>
                                                                <option value='Clumber Spaniel'>Clumber Spaniel</option>
                                                                <option value='Cocker Spaniel'>Cocker Spaniel</option>
                                                                <option value='Collie'>Collie</option>
                                                                <option value='Curly-Coated Retriever'>Curly-Coated Retriever</option>
                                                                <option value='Dachshund'>Dachshund</option>
                                                                <option value='Dalmatian'>Dalmatian</option>
                                                                <option value='Doberman Pinscher'>Doberman Pinscher</option>
                                                                <option value='English Cocker Spaniel'>English Cocker Spaniel</option>
                                                                <option value='English Setter'>English Setter</option>
                                                                <option value='English Springer Spaniel'>English Springer Spaniel</option>
                                                                <option value='English Toy Spaniel'>English Toy Spaniel</option>
                                                                <option value='Eskimo Dog'>Eskimo Dog</option>
                                                                <option value='Finnish Spitz'>Finnish Spitz</option>
                                                                <option value='Flat-Coated Retriever'>Flat-Coated Retriever</option>
                                                                <option value='Fox Terrier'>Fox Terrier</option>
                                                                <option value='Foxhound'>Foxhound</option>
                                                                <option value='French Bulldog'>French Bulldog</option>
                                                                <option value='German Shorthaired Pointer'>German Shorthaired Pointer</option>
                                                                <option value='German Wirehaired Pointer'>German Wirehaired Pointer</option>
                                                                <option value='Gordon Setter'>Gordon Setter</option>
                                                                <option value='Great Dane'>Great Dane</option>
                                                                <option value='Greyhound'>Greyhound</option>
                                                                <option value='Irish Setter'>Irish Setter</option>
                                                                <option value='Irish Water Spaniel'>Irish Water Spaniel</option>
                                                                <option value='Irish Wolfhound'>Irish Wolfhound</option>
                                                                <option value='Jack Russell Terrier'>Jack Russell Terrier</option>
                                                                <option value='Japanese Spaniel'>Japanese Spaniel</option>
                                                                <option value='Keeshond'>Keeshond</option>
                                                                <option value='Kerry Blue Terrier'>Kerry Blue Terrier</option>
                                                                <option value='Komondor'>Komondor</option>
                                                                <option value='Kuvasz'>Kuvasz</option>
                                                                <option value='Labrador Retriever'>Labrador Retriever</option>
                                                                <option value='Lakeland Terrier'>Lakeland Terrier</option>
                                                                <option value='Lhasa Apso'>Lhasa Apso</option>
                                                                <option value='Maltese'>Maltese</option>
                                                                <option value='Manchester Terrier'>Manchester Terrier</option>
                                                                <option value='Mastiff'>Mastiff</option>
                                                                <option value='Mexican Hairless'>Mexican Hairless</option>
                                                                <option value='Newfoundland'>Newfoundland</option>
                                                                <option value='Norwegian Elkhound'>Norwegian Elkhound</option>
                                                                <option value='Norwich Terrier'>Norwich Terrier</option>
                                                                <option value='Otterhound'>Otterhound</option>
                                                                <option value='Papillon'>Papillon</option>
                                                                <option value='Pekingese'>Pekingese</option>
                                                                <option value='Pointer'>Pointer</option>
                                                                <option value='Poodle'>Poodle</option>
                                                                <option value='Puli'>Puli</option>
                                                                <option value='Rhodesian Ridgeback'>Rhodesian Ridgeback</option>
                                                                <option value='Saint Bernard'>Saint Bernard</option>
                                                                <option value='Saluki'>Saluki</option>
                                                                <option value='Samoyed'>Samoyed</option>
                                                                <option value='Schipperke'>Schipperke</option>
                                                                <option value='Schnauzer'>Schnauzer</option>
                                                                <option value='Scottish Deerhound'>Scottish Deerhound</option>
                                                                <option value='Scottish Terrier'>Scottish Terrier</option>
                                                                <option value='Sealyham Terrier'>Sealyham Terrier</option>
                                                                <option value='Shetland Sheepdog'>Shetland Sheepdog</option>
                                                                <option value='Siberian Husky'>Siberian Husky</option>
                                                                <option value='Silky Terrier'>Silky Terrier</option>
                                                                <option value='Skye Terrier'>Skye Terrier</option>
                                                                <option value='Staffordshire Bull Terrier'>Staffordshire Bull Terrier</option>
                                                                <option value='Soft-Coated Wheaten Terrier'>Soft-Coated Wheaten Terrier</option>
                                                                <option value='Sussex Spaniel'>Sussex Spaniel</option>
                                                                <option value='Spitz'>Spitz</option>
                                                                <option value='Tibetan Terrier'>Tibetan Terrier</option>
                                                                <option value='Vizsla'>Vizsla</option>
                                                                <option value='Weimaraner'>Weimaraner</option>
                                                                <option value='Welsh Terrier'>Welsh Terrier</option>
                                                                <option value='West Highland White Terrier'>West Highland White Terrier</option>
                                                                <option value='Whippet'>Whippet</option>
                                                                <option value='Yorkshire Terrier'>Yorkshire Terrier</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div> : <div></div>
                                                    }
                                                    {
                                                        valuable_type === 'Cat' ?
                                                        <div>
                                                            <select  className="input-pet-details-dropdown" name="pet_breed" value={pet_breed} onChange={this.changeHandler}>
                                                                <option hidden></option>
                                                                <option value='Abyssinian'>Abyssinian</option>
                                                                <option value='American Bobtail'>American Bobtail</option>
                                                                <option value='American Curl'>American Curl</option>
                                                                <option value='American Shorthair'>American Shorthair</option>
                                                                <option value='American Wirehair'>American Wirehair</option>
                                                                <option value='Balinese'>Balinese</option>
                                                                <option value='Bengal'>Bengal</option>
                                                                <option value='Birman'>Birman</option>
                                                                <option value='Bombay'>Bombay</option>
                                                                <option value='British Shorthair'>British Shorthair</option>
                                                                <option value='Burmese'>Burmese</option>
                                                                <option value='Chartreux'>Chartreux</option>
                                                                <option value='Chausie'>Chausie</option>
                                                                <option value='Cornish Rex'>Cornish Rex</option>
                                                                <option value='Devon Rex'>Devon Rex</option>
                                                                <option value='Donskoy'>Donskoy</option>
                                                                <option value='Egyptian Mau'>Egyptian Mau</option>
                                                                <option value='Exotic Shorthair'>Exotic Shorthair</option>
                                                                <option value='Havana'>Havana</option>
                                                                <option value='Highlander'>Highlander</option>
                                                                <option value='Himalayan'>Himalayan</option>
                                                                <option value='Household Pet Cat'>Household Pet Cat</option>
                                                                <option value='Household Pet Kitten'>Household Pet Kitten</option>
                                                                <option value='Japanese Bobtail'>Japanese Bobtail</option>
                                                                <option value='Korat'>Korat</option>
                                                                <option value='Kurilian Bobtail'>Kurilian Bobtail</option>
                                                                <option value='Laperm'>Laperm</option>
                                                                <option value='Maine Coon'>Maine Coon</option>
                                                                <option value='Manx'>Manx</option>
                                                                <option value='Minskin'>Minskin</option>
                                                                <option value='Munchkin'>Munchkin</option>
                                                                <option value='Nebelung'>Nebelung</option>
                                                                <option value='Norwegian Forest Cat'>Norwegian Forest Cat</option>
                                                                <option value='Ocicat'>Ocicat</option>
                                                                <option value='Ojos Azules'>Ojos Azules</option>
                                                                <option value='Oriental'>Oriental</option>
                                                                <option value='Persian'>Persian</option>
                                                                <option value='Peterbald'>Peterbald</option>
                                                                <option value='Pixiebob'>Pixiebob</option>
                                                                <option value='Ragdoll'>Ragdoll</option>
                                                                <option value='Russian Blue'>Russian Blue</option>
                                                                <option value='Savannah'>Savannah</option>
                                                                <option value='Scottish Fold'>Scottish Fold</option>
                                                                <option value='Selkirk Rex'>Selkirk Rex</option>
                                                                <option value='Serengeti'>Serengeti</option>
                                                                <option value='Siberian'>Siberian</option>
                                                                <option value='Siamese'>Siamese</option>
                                                                <option value='Singapura'>Singapura</option>
                                                                <option value='Snowshoe'>Snowshoe</option>
                                                                <option value='Sokoke'>Sokoke</option>
                                                                <option value='Somali'>Somali</option>
                                                                <option value='Sphynx'>Sphynx</option>
                                                                <option value='Thai'>Thai</option>
                                                                <option value='Tonkinese'>Tonkinese</option>
                                                                <option value='Toyger'>Toyger</option>
                                                                <option value='Turkish Angora'>Turkish Angora</option>
                                                                <option value='Turkish Van'>Turkish Van</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div> : <div></div>
                                                    }
                                                    {
                                                        pet_breed === 'Other' ? <input type="text" className="input-pet-details mt-3 pl-1" maxlength="20" value={pet_breedother} onChange={this.changeHandler} name="pet_breedother"/> : <div></div>
                                                    }
                                                </div><br/> */}
                                            </div><br/>


                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Color *</span><br/>
                                                        <select className="input-pet-details-dropdown" name="pet_color" value={pet_color} onChange={this.changeHandler} required>
                                                            <option hidden></option>
                                                            <option value="white">White</option>
                                                            <option value="brown">Brown</option>
                                                            <option value="black">Black</option>
                                                            <option value="grey">Grey</option>
                                                            <option value="silver">Silver</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    {
                                                        pet_color === 'Other' ? <input type="text" className="input-pet-details mt-3 pl-1" maxlength="20" value={pet_colorother} onChange={this.changeHandler} name="pet_colorother"/> : <div></div>
                                                    }
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Microchip Number</span><br/>
                                                    <input type="number" className="input-pet-details" value={pet_microchip} onChange={this.changeHandler} name="pet_microchip"/>
                                                </div><br/>
                                            </div><br/>

                                            {/* <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Gender *</span><br/>
                                                        <RadioGroup aria-label="pet_gender" name="pet_gender" value={pet_gender} onChange={this.changeHandler} required row>
                                                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                                                        </RadioGroup>
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Date of Birth</span><br/>
                                                    <input type="date" className="input-pet-details" value={pet_dob} onChange={this.changeHandlerdate} name="pet_dob"/>
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Age (in years)</span><br/>
                                                    <input type="number" className="input-pet-details" value={pet_age} onChange={this.changeHandler} name="pet_age"/>
                                                </div><br/>
                                            </div><br/>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="label-petdata">Identification Mark *</span><br/>
                                                    <input type="text" className="input-pet-details" maxLength="20" value={pet_ident} onChange={this.changeHandler} required name="pet_ident"/>
                                                </div><br/>
                                                <div className="col marginheight">
                                                    <span className="label-petdata">Microchip Number</span><br/>
                                                    <input type="number" className="input-pet-details" value={pet_microchip} onChange={this.changeHandler} name="pet_microchip"/>
                                                </div><br/>
                                            </div><br/><br/> */}

                                            <div className="row">
                                                <div className="col">
                                                    <Button variant="contained" type="submit" style={{'width': '100%'}} className="submit-login bg-warning text-white mt-2">NEXT</Button>
                                                </div>
                                                <div className="col">
                                                    <Button  type="button" variant="contained" style={{'width': '100%'}} className="bg-white mt-2 cancel-btn submit-login" onClick={()=>{window.location.href="/dashboard/"+localStorage.getItem('uid')}}>My Tags</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <Snackbar open={this.state.snackbaropen} autoHideDuration={8000} onClose={this.snackbarClose} message={this.state.snackbarmsg} action={[
                    <IconButton arial-label="Close" color="inherit" onClick={this.snackbarClose}>X</IconButton>
                ]} />
            </div>
        )
    }
}
