import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './reportlost.component.css';
import 'font-awesome/css/font-awesome.min.css';
import Button from '@material-ui/core/Button';
import { ToggleButton } from '@material-ui/lab';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import axios from 'axios';
import Footer from '../footer/footer.component.jsx'
import MapContainer from '../reportlost/mapcontainer'
import 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Userimage from '../../assets/user2.png';
import { Link } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie('token');
const Name = localStorage.getItem("name")
let token = usertoken

function getSteps(){
  return ['Loss Details', 'Location', 'Date & Time of Loss', 'Beacon QR Details'];
}

const steps = getSteps();

export default class ReportLost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alignment: 'left',
      tag_number: this.props.match.params.tag_number,
      lf_status: "lost",
      valuable_type: "",
      pet_age: "",
      pet_name: '',
      pet_bloodgrp: "",
      pet_breed: "",
      pet_color: "",
      pet_dob: '',
      pet_ident: "",
      veternity: '',
      pet_gender: '',
      identification_mark: "",
      parent_firstname: "",
      parent_lastname: "",
      phone_one: "",
      phone_two: "",
      phone_three: "",
      parent_email: "",
      parent_altemail: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      dr_phone: "",
      pet_imagelocation:"",
      snackbaropen:false,
      snackbarmsg:'',
      activeStep: 0
    };

    this.getCoordinates = this.getCoordinates.bind(this)
  }

  handleAlignment = (event, alignment) => this.setState({ alignment });

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser.")
    }

    const access_token = token
    const getURL = 'https://dashboard.tag8.co.in/api/pet/getPetParentDetails/' + this.props.match.params.tag_number;
    axios.get(getURL, {
      headers: {
        'Authorization': access_token,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      console.log(response)
      console.log(response.data.pet_name)
      if(response.data.pet_bloodgrp === 'Other'){
        const bloodgroup = response.data.pet_bloodgrpother
        this.setState({pet_bloodgrp: bloodgroup})
      }
      else{
        const bloodgroup = response.data.pet_bloodgrp
        this.setState({pet_bloodgrp:bloodgroup})
      }

      if(response.data.pet_breed === 'Other'){
        const breed = response.data.pet_breedother
        this.setState({pet_breed: breed})
      }
      else{
        const breed = response.data.pet_breed
        this.setState({pet_breed:breed})
      }

      if(response.data.pet_color === 'Other'){
        const color = response.data.pet_colorother
        this.setState({pet_color: color})
      }
      else{
        const color = response.data.pet_color
        this.setState({pet_color:color})
      }
      this.setState({
        tag_number: response.data.tag_number,
        valuable_type: response.data.valuable_type,
        pet_age: response.data.pet_age,
        pet_name: response.data.pet_name,
        pet_dob: response.data.pet_dob,
        pet_ident: response.data.pet_ident,
        pet_gender: response.data.pet_gender,
        veternity: response.data.veternity,
        parent_firstname: response.data.parent_firstname,
        parent_lastname: response.data.parent_lastname,
        phone_one: response.data.phone_one,
        phone_two: response.data.phone_two,
        phone_three: response.data.phone_three,
        parent_email: response.data.parent_email,
        parent_altemail: response.data.parent_altemail,
      })
    }).catch(error => {
      console.log(error)
    })
  }

  getCoordinates(position) {
    console.log(position)
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    })
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  dateChangeHandler = Date => {
    console.log(Date.target.value)
    this.setState({ [Date.target.name]: Date.target.value })
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false })
  }

  submitHandler = e => {
    e.preventDefault()
    console.log(this.state)
    const tag_number = this.state.tag_number;
    axios.post("https://dashboard.tag8.co.in/api/pet/reportlost/" + tag_number, this.state, { headers: { 'Authorization': token } }).then(response => {
      console.log(response)
      console.log(response.data)
      if (response.data === 'Tag is already reported lost') {
        this.setState({ snackbaropen: true, snackbarmsg: response.data })
        window.location.href = '/dashboard/' + localStorage.getItem('uid')
      }
      else if (response.data === 'Tag Reported Lost') {
        this.setState({ snackbaropen: true, snackbarmsg: response.data })
        window.location.href = '/pet-poster/' + tag_number
      }
      else if (response.data === 'Lost details updated') {
        this.setState({ snackbaropen: true, snackbarmsg: response.data })
        window.location.href = '/pet-poster/' + tag_number
      }
      else if (response.data === 'Lost details saved') {
        this.setState({ snackbaropen: true, snackbarmsg: response.data })
        window.location.href = '/pet-poster/' + tag_number
      }
      else{
        this.setState({ snackbaropen: true, snackbarmsg: response.data })
        window.location.href = '/dashboard/' + localStorage.getItem('uid')
      }
    }).catch(error => {
      console.log(error)
      this.setState({ snackbaropen: true, snackbarmsg: 'Report Lost Failed' })
    })
  }

  getStepContent = (stepIndex) => {
    const { lost_text, lost_date, latitude, longitude, lost_timeone, lost_timetwo, alignment, tag_number, valuable_type, parent_firstname, parent_lastname, phone_one, phone_two, parent_email, parent_altemail, reward, rewardcurrency, pet_age, pet_name, pet_breed, pet_gender, pet_color, pet_dob, pet_bloodgrp, pet_ident, veternity, dr_phone, phone_three } = this.state;
    switch (stepIndex) {
      case 0:
        return(
          <div className="text-center">
            <textarea rows="4" className="loss-details-body text-center p-3" style={{fontSize: '16px', borderColor: 'gray', marginTop:'150px',marginLeft:'0px'}} name="lost_text" placeholder="Give a brief account of loss. [Eg] Were you in a cab?" value={lost_text} onChange={this.changeHandler}></textarea><br/><br/>
            <div className="row mt-5 mb-4">
              <div className="col">
                <Button type="button" onClick={()=>{window.location.href = "/dashboard/" + localStorage.getItem('uid')}} variant="contained" style={{ 'width': '200px' }} className="submit-login cancel-btn mt-2">CANCEL</Button>
              </div>
              <div className="col text-center">
                <Button variant="contained" className="bg-warning nextback nextbutton mt-2" onClick={this.handleNext} style={{'width': '200px'}}>Next</Button>
              </div>
            </div>
          </div>
        )
      case 1:
        return(
          <div className="mt-4">
            <div style={{'margin-left':'15%'}}>
              <div className="row">
                <MapContainer className="googlemaps"></MapContainer>
              </div>
              <div className="row mt-3 latlong">
                <div className="col">
                  <span className="label-petdata float-left">Latitude</span><br></br>
                  <input type="text" className="form-control input-pet-details" value={latitude} name="latitude" onChange={this.changeHandler}/>
                </div>
                <div className="col">
                  <span className="label-petdata float-left">Longitude</span><br></br>
                  <input type="text" className="form-control input-pet-details" value={longitude} name="longitude" onChange={this.changeHandler}/>
                </div>
              </div>
            </div>
            <div className="row mt-5 mb-4">
              <div className="col">
                <Button type="button" onClick={()=>{window.location.href = "/dashboard/" + localStorage.getItem('uid')}} variant="contained" style={{ 'width': '200px' }} className="submit-login cancel-btn mt-2">CANCEL</Button>
              </div>
              <div className="col text-center">
                <Button variant="contained"  className="mt-2"  style={{ 'width': '200px' }} onClick={this.handleBack}>Back</Button>
              </div>
              <div className="col text-center">
                <Button variant="contained" className="bg-warning nextbutton mt-2" style={{ 'width': '200px' }} value="right" onClick={this.handleNext}>Next</Button><br />
              </div>
            </div>
          </div>
        )
      case 2:
        return(
          <div>
            <div style={{ 'width': '250px', 'margin': 'auto', marginTop: '50px' }}>
              <div className="col text-center">
                <input type="date" className="pl-2" name="lost_date" value={lost_date} onChange={this.changeHandler} /><br /><br /><br />
              </div>
              <h5 className="set-time">Set indicative time range of loss</h5><br />
              <input type="time" className="pl-2" name="lost_timeone" value={lost_timeone} onChange={this.changeHandler} /><br /><br />
              to<br /><br />
              <input type="time" className="pl-2" name="lost_timetwo" value={lost_timetwo} onChange={this.changeHandler} />
            </div>
            <div className="row mt-5 mb-4">
              <div className="col">
                  <Button type="button" onClick={()=>{window.location.href = "/dashboard/" + localStorage.getItem('uid')}} variant="contained" style={{ 'width': '200px' }} className="submit-login cancel-btn mt-2">CANCEL</Button>
                </div>
              <div className="col text-center">
                <Button variant="contained"  className="mt-2"  onClick={this.handleBack} style={{ 'width': '200px' }}>Back</Button>
              </div>
              <div className="col text-center">
                <Button variant="contained" className="bg-warning nextbutton mt-2" onClick={this.handleNext} style={{ 'width': '200px' }}>Next</Button><br />
              </div>
            </div>
          </div>
        )
      case 3:
        return(
          <div className="petdetails-height text-left">
            <h4 className="ml-4" style={{ fontWeight: '350px' }}>Beacon QR Details</h4>
            <hr />
            <div className="p-4">
              <div className="my-upper-petdetails">
                <div className="row">
                  <div className="col">
                    <span className="label-petdata">Owner ID *</span><br />
                    <input type="textbox" className="input-pet-details" value={tag_number} name="tag_number" readOnly required /><br />
                  </div>
                  <div className="col marginheight-rl">
                    <span className="label-petdata">Tracker Type *</span><br />
                    <input type="textbox" className="input-pet-details" value={valuable_type} name="valuable_type" readOnly required /><br />
                  </div>
                  <div className="col marginheight-rl">
                    <span className="label-petdata">Tracker Name *</span><br /> 
                    <input type="textbox" className="input-pet-details" id="nextfieldpetname" maxLength="20" required name="pet_name" value={pet_name} onChange={this.changeHandler} /><br />
                  </div>
                </div><br />

                {/* <div className="row">
                  <div className="col">
                    <span className="label-petdata">Breed</span><br />
                    <input type="textbox" className="input-pet-details" maxLength="20" name="pet_breed" value={pet_breed || ''} onChange={this.changeHandler} /><br />
                  </div>
                  <div className="col marginheight-rl">
                    <span className="label-petdata">Gender *</span><br />
                    <input type="textbox" className="input-pet-details" maxLength="20" required name="pet_gender" value={pet_gender || ''} onChange={this.changeHandler} /><br />
                  </div>
                  <div className="col marginheight-rl">
                    <span className="label-petdata">Color</span><br />
                    <input type="textbox" className="input-pet-details" maxLength="20" name="pet_color" value={pet_color || ''} onChange={this.changeHandler} /><br />
                  </div>
                </div><br /> */}

                <div className="row">
                  <div className="col">
                    <span className="label-petdata">Date of Activation</span><br />
                    <input type="date" className="input-pet-details" maxLength="20" name="pet_dob" value={pet_dob || ''} onChange={this.changeHandler} /><br />
                  </div>
                  {/* <div className="col marginheight-rl">
                    <span className="label-petdata">Age *</span><br />
                    <input type="number" className="input-pet-details" maxLength="20" required name="pet_age" value={pet_age} onChange={this.changeHandler} /><br />
                  </div>
                  <div className="col marginheight-rl">
                    <span className="label-petdata">Blood Group</span><br />
                    <input type="textbox" className="input-pet-details" maxLength="20" name="pet_bloodgrp" value={pet_bloodgrp} onChange={this.changeHandler} /><br />
                  </div> */}
                </div><br />


                {/* <div className="row">
                  <div className="col">
                    <span className="label-petdata">Identification Mark *</span><br />
                    <input type="textbox" className="input-pet-details" maxLength="20" required name="pet_ident" value={pet_ident || ''} onChange={this.changeHandler} /><br />
                  </div>
                  <div className="col marginheight-rl">
                    <span className="label-petdata">Consulting Veterinary</span><br />
                    <input type="textbox" className="input-pet-details" maxLength="20" name="veternity" value={veternity} onChange={this.changeHandler} /><br />
                  </div>
                  <div className="col marginheight-rl">
                    <span className="label-petdata">Doctor Phone Number</span><br />
                    <input type="textbox" className="input-pet-details" maxLength="20" name="dr_phone" value={dr_phone} onChange={this.changeHandler} /><br />
                  </div>
                </div> */}
              </div>

              <h4 className="mt-5" style={{ fontWeight: '350px' }}>Owners Details</h4>
              <hr />
              <div className="row mt-4">
                <div className="col">
                  <span className="label-petdata">Owners First Name *</span><br />
                  <input type="textbox" className="input-pet-details" maxLength="20" name="parent_firstname" required value={parent_firstname} onChange={this.changeHandler} /><br />
                </div>
                <div className="col marginheight-rl">
                  <span className="label-petdata">Owners Last Name *</span><br />
                  <input type="textbox" className="input-pet-details" maxLength="20" name="parent_lastname" required value={parent_lastname} onChange={this.changeHandler} /><br />
                </div>
                <div className="col marginheight-rl">
                  <span className="label-petdata">Email ID *</span><br />
                  <input type="textbox" className="input-pet-details" maxLength="20" name="parent_email" required value={parent_email} onChange={this.changeHandler} /><br />
                </div>
              </div><br />

              <div className="row">
                <div className="col">
                  <span className="label-petdata">Emergency Phone Number 1 *</span><br />
                  <input type="textbox" className="input-pet-details" maxLength="20" name="phone_one" required value={phone_one} onChange={this.changeHandler} /><br />
                </div>
                <div className="col marginheight-rl">
                  <span className="label-petdata">Emergency Phone Number 2</span><br />
                  <input type="textbox" className="input-pet-details" maxLength="20" name="phone_two" value={phone_two} onChange={this.changeHandler} /><br />
                </div>
                <div className="col marginheight-rl">
                  <span className="label-petdata">Emergency Phone Number 3</span><br />
                  <input type="textbox" className="input-pet-details" maxLength="20" name="phone_three" value={phone_three} onChange={this.changeHandler} /><br />
                </div>
              </div>
              <br /><br />

              <i className="fa fa-gift text-gray fa-2x"><span className="ml-2 reward-body">Owner Reward</span></i>
              <div className="row rewardandcurrency mt-3">
                {/* <div className="col marginheight">
                  <span className="label-petdata">Currency type</span><br/>
                  <select className="input-pet-details-dropdown" name="rewardcurrency" value={rewardcurrency} onChange={this.changeHandler}>
                    <option hidden></option>
                    <option value='Rupees'>Rupees</option>
                    <option value='U.S. dollar'>U.S. dollar</option>
                    <option value='Euro'>Euro</option>
                    <option value='Dirham'>Dirham</option>
                    <option value='Dinar'>Dinar</option>
                    <option value='Yen'>Yen</option>
                    <option value='Afghani'>Afghani</option>
                    <option value='Lek'>Lek</option>
                    <option value='New Kwanza'>New Kwanza</option>
                    <option value='Dram'>Dram</option>
                    <option value='Bahamian dollar'>Bahamian dollar</option>
                    <option value='Bahrain dinar'>Bahrain dinar</option>
                    <option value='Taka'>Taka</option>
                    <option value='Barbados dollar'>Barbados dollar</option>
                    <option value='Belorussian ruble'>Belorussian ruble</option>
                    <option value='Belize dollar'>Belize dollar</option>
                    <option value='Ngultrum'>Ngultrum</option>
                    <option value='Boliviano'>Boliviano</option>
                    <option value='Convertible Mark'>Convertible Mark</option>
                    <option value='Pula'>Pula</option>
                    <option value='Real'>Real</option>
                    <option value='Brunei dollar'>Brunei dollar</option>
                    <option value='Lev'>Lev</option>
                    <option value='Burundi franc'>Burundi franc</option>
                    <option value='Riel'>Riel</option>
                    <option value='Canadian dollar'>Canadian dollar</option>
                    <option value='Cape Verdean escudo'>Cape Verdean escudo</option>
                    <option value='Chilean Peso'>Chilean Peso</option>
                    <option value='Chinese Yuan'>Chinese Yuan</option>
                    <option value='Colombian Peso'>Colombian Peso</option>
                    <option value='Franc'>Franc</option>
                    <option value='United States dollar'>United States dollar</option>
                    <option value='Colón'>Colón</option>
                    <option value='Croatian'>Croatian</option>
                    <option value='Cuban Peso'>Cuban Peso</option>
                    <option value='Koruna'>Koruna</option>
                    <option value='Danish Krone'>Danish Krone</option>
                    <option value='Djiboutian franc'>Djiboutian franc</option>
                    <option value='Dominican Peso'>Dominican Peso</option>
                    <option value='Egyptian pound'>Egyptian pound</option>
                    <option value='Colón; U.S. dollar'>Colón; U.S. dollar</option>
                    <option value='Nakfa'>Nakfa</option>
                    <option value='Birr'>Birr</option>
                    <option value='Fiji dollar'>Fiji dollar</option>
                    <option value='Dalasi'>Dalasi</option>
                    <option value='Lari'>Lari</option>
                    <option value='Cedi'>Cedi</option>
                    <option value='Quetzal'>Quetzal</option>
                    <option value='Guinean franc'>Guinean franc</option>
                    <option value='Guyanese dollar'>Guyanese dollar</option>
                    <option value='Gourde'>Gourde</option>
                    <option value='Lempira'>Lempira</option>
                    <option value='Forint'>Forint</option>
                    <option value='Icelandic króna'>Icelandic króna</option>
                    <option value='Rupiah'>Rupiah</option>
                    <option value='Iraqi Dinar'>Iraqi Dinar</option>
                    <option value='Shekel'>Shekel</option>
                    <option value='Jamaican dollar'>Jamaican dollar</option>
                    <option value='Jordanian dinar'>Jordanian dinar</option>
                    <option value='Tenge'>Tenge</option>
                    <option value='Kenya shilling'>Kenya shilling</option>
                    <option value='Kiribati dollar'>Kiribati dollar</option>
                    <option value='Won'>Won</option>
                    <option value='Kuwaiti Dinar'>Kuwaiti Dinar</option>
                    <option value='Som'>Som</option>
                    <option value='New Kip'>New Kip</option>
                    <option value='Lats'>Lats</option>
                    <option value='Lebanese pound'>Lebanese pound</option>
                    <option value='Maluti'>Maluti</option>
                    <option value='Liberian dollar'>Liberian dollar</option>
                    <option value='Libyan dinar'>Libyan dinar</option>
                    <option value='Litas'>Litas</option>
                    <option value='Denar'>Denar</option>
                    <option value='Malagasy Ariary'>Malagasy Ariary</option>
                    <option value='Ringgit'>Ringgit</option>
                    <option value='Rufiyaa'>Rufiyaa</option>
                    <option value='Ouguiya'>Ouguiya</option>
                    <option value='Mauritian rupee'>Mauritian rupee</option>
                    <option value='Mexican peso'>Mexican peso</option>
                    <option value='Leu'>Leu</option>
                    <option value='Togrog'>Togrog</option>
                    <option value='Metical'>Metical</option>
                    <option value='Kyat'>Kyat</option>
                    <option value='Namibian dollar'>Namibian dollar</option>
                    <option value='Australian dollar'>Australian dollar</option>
                    <option value='Nepalese rupee'>Nepalese rupee</option>
                    <option value='New Zealand dollar'>New Zealand dollar</option>
                    <option value='Gold cordoba'>Gold cordoba</option>
                    <option value='Naira'>Naira</option>
                    <option value='Norwegian krone'>Norwegian krone</option>
                    <option value='Omani rial'>Omani rial</option>
                    <option value='Pakistani rupee'>Pakistani rupee</option>
                    <option value='Palestine Pound'>Palestine Pound</option>
                    <option value='Balboa; U.S. dollar'>Balboa; U.S. dollar</option>
                    <option value='Kina'>Kina</option>
                    <option value='Guaraní'>Guaraní</option>
                    <option value='Nuevo sol (1991)'>Nuevo sol (1991)</option>
                    <option value='Peso'>Peso</option>
                    <option value='Zloty'>Zloty</option>
                    <option value='Qatari riyal'>Qatari riyal</option>
                    <option value='Romanian Rupee'>Romanian Rupee</option>
                    <option value='Ruble'>Ruble</option>
                    <option value='Rwandan franc'>Rwandan franc</option>
                    <option value='East Caribbean dollar'>East Caribbean dollar</option>
                    <option value='Tala'>Tala</option>
                    <option value='Dobra'>Dobra</option>
                    <option value='Riyal'>Riyal</option>
                    <option value='Serbian Dinar'>Serbian Dinar</option>
                    <option value='Seychelles rupee'>Seychelles rupee</option>
                    <option value='Leone'>Leone</option>
                    <option value='Singapore dollar'>Singapore dollar</option>
                    <option value='Solomon Islands dollar'>Solomon Islands dollar</option>
                    <option value='Somali shilling'>Somali shilling</option>
                    <option value='Rand'>Rand</option>
                    <option value='Sri Lankan rupee'>Sri Lankan rupee</option>
                    <option value='Sudanese Pound'>Sudanese Pound</option>
                    <option value='Surinamese dollar'>Surinamese dollar</option>
                    <option value='Lilangeni'>Lilangeni</option>
                    <option value='Krona'>Krona</option>
                    <option value='Swiss franc'>Swiss franc</option>
                    <option value='Syrian pound'>Syrian pound</option>
                    <option value='Taiwan dollar'>Taiwan dollar</option>
                    <option value='somoni'>somoni</option>
                    <option value='Tanzanian shilling'>Tanzanian shilling</option>
                    <option value='Baht'>Baht</option>
                    <option value='CFA Franc'>CFA Franc</option>
                    <option value='Pa’anga'>Pa’anga</option>
                    <option value='Trinidad and Tobago dollar'>Trinidad and Tobago dollar</option>
                    <option value='Tunisian dinar'>Tunisian dinar</option>
                    <option value='Turkish lira (YTL)'>Turkish lira (YTL)</option>
                    <option value='Manat'>Manat</option>
                    <option value='Tuvaluan Dollar'>Tuvaluan Dollar</option>
                    <option value='Ugandan new shilling'>Ugandan new shilling</option>
                    <option value='Hryvnia'>Hryvnia</option>
                    <option value='U.A.E. Dirham'>U.A.E. Dirham</option>
                    <option value='Pound sterling'>Pound sterling</option>
                    <option value='Dollar'>Dollar</option>
                    <option value='Uruguay peso'>Uruguay peso</option>
                    <option value='Uzbekistani sum'>Uzbekistani sum</option>
                    <option value='Vatu'>Vatu</option>
                    <option value='Bolivar'>Bolivar</option>
                    <option value='Dong'>Dong</option>
                    <option value='Rial'>Rial</option>
                    <option value='Kwacha'>Kwacha</option>
                  </select>
                </div>
                <div className="col marginheight">
                  <span className="label-petdata">Reward</span><br/>
                  <input type="number" className="input-pet-details" name="reward" value={reward} onChange={this.changeHandler}/><br/>
                </div>
                <div className="col marginheight"></div> */}
              </div>
              <div className="row text-center mt-5">
                <div className="col">
                  <Button type="button" onClick={()=>{window.location.href = "/dashboard/" + localStorage.getItem('uid')}} variant="contained" style={{ 'width': '200px' }} className="submit-login cancel-btn mt-2">CANCEL</Button>
                </div>
                <div className="col text-center">
                  <Button variant="contained"  className="mt-2"  onClick={this.handleBack} style={{ 'width': '200px' }}>Back</Button>
                </div>
                <div className="col">
                  <Button variant="contained" type="submit" style={{ 'width': '200px' }} className="bg-warning text-white mt-2 submit-login">Submit</Button>
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return 'Unknown stepIndex';
    }
  }
  

  handleNext = () => {
    this.setState((prevActiveStep) => {
      console.log("prev",prevActiveStep)
      return {
        activeStep: prevActiveStep.activeStep + 1
      }
    })
  };

  handleBack = () => {
    this.setState((prevActiveStep) => {
      return {
        activeStep: prevActiveStep.activeStep - 1
      }
    })
  };

  handleReset = () => {
    this.setState({activeStep: 0})
  };

  render() {
    const { alignment, tag_number, valuable_type, parent_firstname, parent_lastname, phone_one, phone_two, parent_email, parent_altemail, reward, rewardcurrency, pet_age, pet_name, pet_breed, pet_gender, pet_color, pet_dob, pet_bloodgrp, pet_ident, veternity, dr_phone, phone_three } = this.state;

    return (
      <div>
        <div className="bg-warning pb-5">
          <div className="row">
            <div className="col-xl-3 col-lg mt-5 pt-5 pl-5 bg-dark dashboard__blacksidenav" style={{height:'90vh'}}>
                <img src={Userimage} style={{ fontSize: "80px", color:'white' }} width="80px" height="80px" className="text-white user-logo ml-5 mt-0" />
                <p></p>
                <h5 style={{ letterSpacing: '0px'}}><a href="/profile" className="text-white ml-2">Hello {Name}</a></h5>
                <div className="row petparentlinks-rl">
                  <div className="col text-left" style={{margin:'auto'}}>
                      <ul className="mt-4 text-left leftside-navigation-rl" style={{listStyleType:'none'}}>
                          <li>
                              <a href={`https://dashboard.tag8.co.in/dashboard/`+localStorage.getItem('uid')}><Button className="text-white bg-dark leftside-buttons-rl py-1 px-4" style={{'fontSize': '16px !important'}}>My Tags</Button></a><br/><br/>
                          </li>
                          <li>
                              <a href={`/calendar/`+localStorage.getItem('uid')}><Button className="text-white bg-dark leftside-buttons-rl py-1 px-4" style={{'fontSize': '16px !important'}}>Calendar</Button></a><br/><br/>
                          </li>
                          {/* <li>
                              <a href={`/pet-details/`+this.props.match.params.tag_number}><Button className="text-white bg-dark leftside-buttons-rl py-1 px-4" style={{'fontSize': '16px !important'}}>Pet Details</Button></a><br/><br/>
                          </li> */}
                          <li>
                              <a href={`/pet-parent-details/`+this.props.match.params.tag_number}><Button className="text-white bg-dark leftside-buttons-rl py-1 px-4" style={{'fontSize': '16px !important'}}>Owner Details</Button></a><br/><br/>
                          </li>
                          {/* <li>
                              <a href={`/medical-dashboard/`+this.props.match.params.tag_number}><Button className="text-white bg-dark leftside-buttons-rl py-1 px-4" style={{'fontSize': '16px !important'}}>Pet Medical Details</Button></a><br/><br/>
                          </li> */}
                      </ul>
                  </div>
                </div>  
            </div>
            <div className="col-xl-9 col-lg">
              <form onSubmit={this.submitHandler}>
              <Stepper activeStep={this.state.activeStep} alternativeLabel className="bg-warning">
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className="container uppercontainer bg-white py-3 px-0 mt-4 p-info text-center reportlostheight"  style={{ 'height': this.state.activeStep === 3 ? '100%' : '570px' }}>{this.getStepContent(this.state.activeStep)}</div>
                <Snackbar open={this.state.snackbaropen} autoHideDuration={3000} onClose={this.snackbarClose} message={this.state.snackbarmsg} action={[
                  <IconButton arial-label="Close" color="inherit" onClick={this.snackbarClose}>X</IconButton>
                ]} />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
