import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from '../../assets/tag8logoblack.png';
import Keyicon from '../../assets/ic_key.png';
import Bagicon from '../../assets/ic_bag.png';
import Catdog from '../../assets/pett.png';
import Passporticon from '../../assets/passport_vextor.png';
import Othericon from '../../assets/other.png';
import Walleticon from '../../assets/wallet.png';
import Remoteicon from '../../assets/remot.png';
import Purseicon from '../../assets/purse.png';
import Laptopicon from '../../assets/laptop.png';
import Cameraicon from '../../assets/ic_camer.png';
import Toyicon from '../../assets/ic_toy.png';
import Cardholder from '../../assets/ic_card_holder.png';
import 'font-awesome/css/font-awesome.min.css';
import Card from '@material-ui/core/Card';
import './reportfound.component.css';
import axios from 'axios';

export default class FoundDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            product_category: "",
            valuable_type: "other",
            keychain_description: "",
            identification_mark: "",
            owner_phone: "",
            owner_phonecode: "",
            lost_text: "",
            lost_date: "",
            reward: "",
            rewardcurrency: "",
            lost_timeone: "",
            lost_timetwo: "",
            latitude: "",
            longitude: "",
            address: "",
            key_imagelocation: "",
            pet_imagelocation: "",
            other_noofkeys: "",
            beacon_name: "",
            identification_mark: "",
            vehicle_licensenumber: "",
            product_type: "",
            passport: "",
            placeofissue: "",
            valuable_desc: "",
            description: "",
            bag_color: "",
            bag_brand: "",
            bag_imagelocation: ""
        };
    }

    
    componentDidMount() {
        axios.get('https://dashboard.tag8.in/api/genericreportfound/' + this.props.tag_number).then(response => {
            this.setState({
                product_category: response.data.product_category || '-',
                mac_address: response.data.mac_address || '-',
                owner_firstname: response.data.owner_firstname || '-',
                owner_lastname: response.data.owner_lastname || '-',
                owner_phone: response.data.owner_phone || '-',
                owner_phonecode: response.data.owner_phonecode || '',
                owner_email: response.data.owner_email || '-',
                keychain_description: response.data.keychain_description || '-',
                latitude: response.data.latitude || '-',
                longitude: response.data.longitude || '-',
                address: response.data.address || '-',
                beacon_name: response.data.beacon_name || '-',
                valuable_type: response.data.valuable_type || '-',
                other_noofkeys: response.data.other_noofkeys || '-',
                reward: response.data.reward || '-',
                rewardcurrency: response.data.rewardcurrency || '-',
                lost_date: response.data.lost_date || '-',
                lost_text: response.data.lost_text || '-',
                identification_mark: response.data.identification_mark || '-',
                vehicle_licensenumber: response.data.vehicle_licensenumber || '-',
                chasis_number: response.data.chasisnumber || '-',
                product_type: response.data.product_type || '-',
                passport: response.data.passport || '-',
                placeofissue: response.data.placeofissue || '-',
                owner_altemail: response.data.owner_altemail || '-',
                owner_altphonecode: response.data.owner_altphonecode || '',
                owner_altphone: response.data.owner_altphone || '-',
                valuable_desc: response.data.desc || '-',
                lost_timeone: response.data.lost_timeone || '-',
                lost_timetwo: response.data.lost_timetwo || '-',
                description: response.data.description || '-',
                bag_color: response.data.bag_color || '-',
                bag_brand: response.data.bag_brand || '-',
                key_imagelocation: response.data.key_imagelocation,
                pet_imagelocation: response.data.pet_imagelocation,
                bag_imagelocation: response.data.bag_imagelocation,
            })
        }).catch(error => {
            console.log(error)
        })
    }
    render() {
        var image;
        const initial = this.props.tag_number.substring(0,3);
        if(initial === "KEY" || initial === "TGY"){
            if(this.state.key_imagelocation !== undefined){
                image = this.state.key_imagelocation;
            } else {
                console.log(this.state.key_imagelocation);
                image = Keyicon;
            }
        } else if(initial === "PET" || initial === "TGZ"){
            if(this.state.pet_imagelocation !== undefined){
                image = this.state.pet_imagelocation;
            } else {
                image = Catdog;
            }
        } else if(initial==="PAS"){
            image = Passporticon;
        } else if(initial ==="BAG" || initial === "CLB"){
            if(this.state.bag_imagelocation !== undefined){
                image = this.state.bag_imagelocation;
            } else {
                image = Bagicon;
            }
        }
        else{
            if(this.state.valuable_type.toUpperCase() === "WALLET"){
                image = Walleticon;
            } else if(this.state.valuable_type.toUpperCase() === "PURSE"){
                image = Purseicon;
            } else if(this.state.valuable_type.toUpperCase() === "KEY"){
                image = Keyicon;
            } else if(this.state.valuable_type.toUpperCase() === "PASSPORT"){
                image = Passporticon;
            } else if(this.state.valuable_type.toUpperCase() === "CARD HOLDER"){
                image = Cardholder;
            } else if(this.state.valuable_type.toUpperCase() === "CAMERA"){
                image = Cameraicon;
            } else if(this.state.valuable_type.toUpperCase() === "PET"){
                image = Catdog;
            } else if(this.state.valuable_type.toUpperCase() === "TOY"){
                image = Toyicon;
            } else if(this.state.valuable_type.toUpperCase() === "REMOTE"){
                image = Remoteicon;
            } else if(this.state.valuable_type.toUpperCase() === "LAPTOP"){
                image = Laptopicon;
            } else if(this.state.valuable_type.toUpperCase() === "BAG"){
                image = Bagicon;
            } else {
                image = Othericon;
            }
        }
        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const { owner_firstname, product_category, owner_lastname, keychain_description, other_noofkeys, owner_phone, owner_email, owner_phonecode, valuable_type, valuable_desc, address, beacon_name, reward, rewardcurrency, identification_mark, lost_date, lost_text, vehicle_licensenumber, chasis_number, product_type, passport, placeofissue, owner_altemail, owner_altphonecode, owner_altphone, lost_timeone, lost_timetwo, description, bag_color, bag_brand } = this.state;
        return (
            <div>
                <div id="contentToConvert" className="py-3">
                    <Card className="mb-5 mat-card">
                        <div className="lost-info-detail-header">
                            <p className="header-font lost-data-header"> LOST {product_category !== "-" ? product_category.toUpperCase() : valuable_type.toUpperCase()} </p>
                        </div>
                        <div className="row mt-4 text-center flex">
                            <div className="col-md-4 pl-5 pr-5 text-center">
                                <img src={image} alt="photo" className="text-center image" style={{ width: '70%' }} />
                            </div>
                            <div className="col-md-6">
                                <div className="row mt-4 ml-5 marginleftmove lost-container">
                                    <div className="row lost-detail">
                                        <p className="header-font lost-data">Tag Number: {this.props.tag_number}</p>
                                    </div>
                                    {
                                        beacon_name !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Beacon Name: {capitalize(beacon_name)}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        product_category !== "-" ?
                                        <div className="row lost-detail">
                                            <p className="header-font lost-data">Category: {capitalize(product_category)}</p>
                                        </div>
                                        : null
                                    }
                                    {
                                        valuable_type !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Valuable Type: {capitalize(valuable_type)}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        valuable_desc !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Valuable Description: {capitalize(valuable_desc)}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        keychain_description !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Keychain Description: {capitalize(keychain_description)}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        description !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Description: {capitalize(description)}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        identification_mark !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Identification Mark: {capitalize(identification_mark)}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        other_noofkeys !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Number of Keys: {other_noofkeys}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        product_type !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Product Type: {product_type}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        vehicle_licensenumber !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">License Number: {vehicle_licensenumber}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        chasis_number !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Chasis Number: {chasis_number}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        bag_color !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Bag Color: {bag_color}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        bag_brand !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Bag Brand: {bag_brand}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        passport !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Passport: {capitalize(passport)}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        placeofissue !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Place Of Issue: {placeofissue}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        reward !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Reward: {rewardcurrency} {reward}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        lost_date !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Lost Date: {lost_date}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        lost_timeone !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Lost Between: {lost_timeone} - {lost_timetwo}</p>
                                            </div>
                                            : null
                                    }
                                    {
                                        lost_text !== "-" ?
                                            <div className="row lost-detail">
                                                <p className="header-font lost-data">Lost Text: {lost_text}</p>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="lost-info-detail">
                            <p className="header-font lost-data">Please help us find {product_category !== "-" ? product_category : ''}</p>
                            <p className="header-font lost-data">Email : {owner_email ? `${owner_email}` : ''}</p>
                            <p className="header-font lost-data">Call: {owner_firstname} {owner_lastname} {owner_phone ? `( ${owner_phonecode} ${owner_phone} )` : ''}</p>
                            {
                                owner_altemail !== "-" ?
                                    <p className="header-font lost-data">Alternate Email : {owner_altemail ? `${owner_altemail}` : ''}</p>
                                    : null
                            }
                            {
                                owner_altphone !== "-" ?
                                    <p className="header-font lost-data">Alternate Phone Number: {owner_altphone ? `( ${owner_altphonecode} ${owner_altphone} )` : ''}</p> : null
                            }
                            {
                                address !== "-" ?
                                    <p className="header-font lost-data">Address: {address}</p> : null
                            }
                        </div>
                        <hr />
                        <div className="row text-center">
                            <img src={Logo} className="mb-3 text-center" width="100px" height="auto" alt="" style={{ margin: 'auto', padding: '10px' }} />
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}