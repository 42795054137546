import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from '../../assets/tag8logoblack.png';
import Bagimage from '../../assets/beaconImg.png'
import 'font-awesome/css/font-awesome.min.css';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import './beaconposter.component.css';
import axios from 'axios';
import { Link } from "react-router-dom";
import html2canvas from 'html2canvas';
import { exportComponentAsPNG } from 'react-component-export-image';
function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export default class BagPoster extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      owner_firstname: "",
      owner_lastname: "",
      valuable_type: "",
      description: "",
      beacon_type: "",
      identification_mark: "",
      owner_phone: "",
      owner_email: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      address: "",
      beacon_image: "",
    };
  }

  componentDidMount() {
    axios.get('/api/dolphin/getreportlocation/' + this.props.match.params.tag_number).then(response => {
      console.log(response)
      this.setState({
        address: response.data.data[0].address
      })
    }).catch(error => {
      console.log(error)
    })

    axios.get('/api/dolphin/getBeaconImagePoster/' + this.props.match.params.tag_number).then(response => {
      console.log(response)
      this.setState({
        beacon_image: response.data.beacon_image
      })
    }).catch(error => {
      console.log(error)
    })

    axios.get('/api/dolphin/getReportLost/' + this.props.match.params.tag_number).then(response => {
      console.log(response)
      this.setState({
        owner_firstname: response.data.owner_firstname,
        owner_lastname: response.data.owner_lastname,
        valuable_type: response.data.product_category,
        description: response.data.beacon_description,
        beacon_type: response.data.beacon_type,
        identification_mark: response.data.identification_mark,
        owner_phone: response.data.owner_phone,
        owner_email: response.data.owner_email,
        lost_text: response.data.lost_text,
        lost_date: response.data.lost_date,
        reward: response.data.reward,
        rewardcurrency: response.data.rewardcurrency,
        lost_timeone: response.data.lost_timeone,
        lost_timetwo: response.data.lost_timetwo,
        latitude: response.data.latitude,
        longitude: response.data.longitude
      })
    }).catch(error => {
      console.log(error)
    })
  }

  downloadposter() {
    let elem = document.getElementById('contentToConvert');
    html2canvas(elem, { useCORS: true, allowTaint: true }).then(function (canvas) {
      let generatedImage = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      let a = document.createElement('a');
      a.href = generatedImage;
      a.download = `bag-poster.png`;
      a.click();
    });
  }
  render() {
    const { owner_firstname, owner_lastname, beacon_type, valuable_type, description, lost_text, identification_mark, owner_phone, owner_email, reward, rewardcurrency, lost_date, address, beacon_image } = this.state;
    return (
      <div>
        <div className="row text-center pt-4" style={{ 'width': '60%', 'marginLeft': '20%' }}>
          <div className="col">
            <Button variant="contained" className="bg-warning postar-res button-styling mt-3" onClick={this.downloadposter} style={{ 'width': '80%' }}>Download</Button>
          </div>
          {/* <div className="col">
                <a href={"/edit-report-lost/"+this.props.match.params.tag_number} style={{'textDecoration':'none'}}><Button variant="contained" className="bg-warning postar-res button-styling mt-3" style={{'width':'80%'}}>Edit</Button></a>
            </div> */}
          <div className="col">
            <Button variant="contained" onClick={() => { window.location.href = "https://dahsboard.tag8.co.in/dashboard/" + localStorage.getItem('uid') }} className="bg-warning postar-res button-styling mt-3" style={{ 'width': '80%' }}>Go To My Tags</Button>
          </div>
        </div>
        <div id="contentToConvert" className="py-3">
          <Card className="mb-5 poster-mat-card">
            <div className="row text-center">
              {valuable_type ? <p className="header-font lost-valuable header-text mt-5">LOST {valuable_type.toUpperCase()} !</p> : ""}
            </div>
            <div className="row">
              <div className="col-md-5">
                {
                  beacon_image ? <img alt="" src={`data:image/png;base64,${beacon_image}`} className="mat-card-image pt-5 pl-5" width="auto" height="340px" /> : <img alt="" className="mat-card-image pt-5 pl-5" alt="" src={Bagimage} width="auto" height="340px" />
                }
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  {/* {pet_breed ?
                  <div className="col text-center">
                      <p className="header-font lost-info-header">BREED</p>
                      <p className="text-center lost-info-text ">{pet_breed}</p>
                  </div>: ""}
                  {pet_gender ?
                  <div className="col text-center">
                      <p className="header-font lost-info-header">GENDER</p>
                      <p className="text-center lost-info-text">{toTitleCase(pet_gender)}</p>
                  </div>: ""}
                  {pet_age ?
                  <div className="col text-center">
                      <p className="header-font lost-info-header">AGE</p>
                      <p className="text-center lost-info-text">{pet_age}</p>
                  </div>: ""} */}
                </div>
                <hr />

                <div className="row">
                  {lost_text ?
                    <div className="col text-center">
                      <p className="header-font lost-info-header lost-info-header-res">Lost Message</p>
                      <p className="text-center lost-info-text">{toTitleCase(lost_text)}</p>
                    </div> : ""}
                  {description ?
                    <div className="col text-center">
                      <p className="header-font lost-info-header lost-info-header-res">BEACON DESCRIPTION</p>
                      <p className="text-center lost-info-text">{toTitleCase(description)}</p>
                    </div> : ""}
                </div>
                <hr />

                {lost_date || address ?
                  <div className="row">
                    <div className="col text-center">
                      <p className="header-font lost-info-header">LAST SEEN</p>
                      <p className="text-center lost-info-text">{lost_date}</p>
                      <p className="text-center lost-info-text-address">{address}</p>
                    </div>
                  </div> : ""}
                {beacon_type ?
                  <div className="row">
                    <div className="col text-center">
                      <p className="header-font lost-info-header">BEACON TYPE</p>
                      <p className="text-center lost-info-text">{beacon_type}</p>
                    </div>
                  </div> : ""}
              </div>
            </div>

            <div className="p-3 text-center answers-to-name mt-3 mb-3">
              <h1 className="answers-to-name-header" style={{ 'wordWrap': 'break-word' }}>ANSWER TO THE NAME OF</h1>
              <h1 className="answers-to-name-header mt-3" style={{ 'wordWrap': 'break-word' }}>{valuable_type}</h1>
            </div>

            <div className="row text-center mb-4">
              <div className="col-md">
                <p className="bottom-text-1 mt-4">PLEASE</p>
                <p className="bottom-text-1"><span className="bottom-help-text">HELP US &nbsp; </span>FIND</p>
                <p className="bottom-text-1">Beacon</p>
                {
                  owner_phone ?
                    <div>
                      <p className="answers-to-name-text-1 header-font text-center">Call : {owner_firstname} {owner_lastname}</p>
                      <p className="answers-to-name-text-1 header-font text-center">{owner_phone}</p>
                    </div> : ""
                }
              </div>
              {
                rewardcurrency && reward ?
                  <div className="col-md mt-4">
                    <p className="header-font reward-header bottom-text-res">REWARD</p>
                    <p className="bottom-text bottom-text-res mt-5">{rewardcurrency}<br />{reward}</p>
                  </div> : ""
              }
            </div>
            <hr />
            <div className="row text-center">
              <img src={Logo} className="mb-3 text-center" width="100px" height="50px" alt="" style={{ margin: 'auto' }} />
            </div>
          </Card>
        </div>
      </div>
    )
  }
}