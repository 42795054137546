import React, { useState, useEffect } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import 'font-awesome/css/font-awesome.min.css'
import Logo from "../../assets/genielogofinal.png"
import './header.component.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import jwt_decode from "jwt-decode";
 
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
let usertoken = readCookie('token');

function delete_cookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function Navbar() {
    const token = usertoken;
    const [isAuth, setIsAuth] = useState((usertoken !== null) ? true : false);
    const [passwordType, setPasswordType] = useState("password");
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)
    const [form, setForm] = useState('none');
    const [form1, setForm1] = useState('none');
    const [firstName, setFirstName] = useState(' ');
    const [lastName, setLastName] = useState(' ');
    const [uid, setUID] = useState('');
    const [navbar, setNavbar] = useState(null)
    const [ownerID, setOwnerID] = useState('')
    const [tagNotFound, setTagNotFound] = useState(false);
    const [alreadyActivated, setAlreadyActivated] = useState(false);
    const handleNotFound = () => setTagNotFound(false);
    const handleActivated = () => setAlreadyActivated(false);
    const [changePassword, setChangePassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [characterLength, setCharacterLength] = useState('none');
    const [validPassword, setValidPassword] = useState('none')
    const [passwordValid, setPasswordValid] = useState(false);
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState('none');
    const passwordRE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
    function activateTag() {
        const state = {
            tag_number: ownerID
        }
        axios.post("https://dashboard.tag8.co.in/api/checkTagNumberWithoutHeader", state, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res);
            if (res.data === "Tag not Found by the specified Number") {
                setTagNotFound(true)
            }
            else if (res.data === "Tag is already activated") {
                setAlreadyActivated(true)
            }
            else {
                const initial = ownerID.substring(0, 3)
                if (initial === 'PET') {
                    window.location.href = '/pet-activation/' + ownerID;
                }
                else if (initial === 'KEY') {
                    window.location.href = "/key-activation/" + ownerID;
                }
                if (initial === 'TGZ') {
                    window.location.href = '/pet-activation/' + ownerID;
                }
                else if (initial === 'TGY') {
                    window.location.href = "/key-activation/" + ownerID;
                }
            }
        }).catch(error => {
            console.log(error);
        })
    }
    function reportFound() {
        window.location.href = "/scan/" + ownerID;
    }
    function logout(event) {
        delete_cookie('token')
        setIsAuth(false)
    }
    function updatePassword() {
        const state = {
            password: password,
            email: email
        }
        axios.post("https://dashboard.tag8.co.in/api/updatePassword", state, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res.data)
            if (res.data.data === "Password updated successfully") {
                setChangePassword(false);
                setMessage("Password updated succesfully")
                setAlert('flex')
                setPassword('')
            }
        }).catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        console.log(isAuth)
        if(isAuth){
            axios.get("https://dashboard.tag8.co.in/api/getUserProfile", {
                headers: {
                    'Authorization': usertoken,
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log(res)
                localStorage.setItem("uid", res.data.uid);
                setUID(res.data.uid);
                localStorage.setItem('name', res.data.first_name + " " + res.data.last_name);
                setFirstName(res.data.first_name);
                setLastName(res.data.last_name);
                setEmail(res.data.email);
            }).catch(error => {
                console.log(error);
            })
        }
    })
    function formValid() {
        return passwordValid;
    }
    useEffect(() => {
        if (password.length > 7 || password === '') {
            setCharacterLength('none');
            if (passwordRE.test(password)) {
                setPasswordValid(true);
                setValidPassword('none')
            }
            else if (password !== '') {
                setPasswordValid(false);
                setValidPassword('block')
            }
            else {
                setPasswordValid(false);
                setValidPassword('none');
            }
        }
        else {
            setValidPassword('block');
            setCharacterLength('block');
            setPasswordValid(false);
        }
        setSubmitDisabled(!formValid());
    })
    const valid = (item) => {
        let password = document.querySelector(`#${item}`);
        password.style.display = 'none';
    }
    const invalid = (item) => {
        let password = document.querySelector(`#${item}`);
        password.style.display = 'block';
    }
    useEffect(() => {
        if (alert === 'flex') {
            setTimeout(() => setAlert('none'), 8000)
        }
    })

    useEffect(()=>{
        if(usertoken){
            setInterval(()=>{
                var decoded = jwt_decode(usertoken);
                const expiry = (decoded.exp * 1000) - 60000;
                if(Date.now() >= expiry){
                    delete_cookie('token')
                    window.location.href="/login"
                }
            },1000)
        }
    })

    return (
        <nav className="navbar navbar-expand-xl navbar-dark bg-dark py-2 pl-3 pr-0" style={{ height: navbar, borderRadius:'0px' }}>
            <img src={Logo} height='50px' width="90px" className="mr-5"/>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item mr-4">
                        <a href="/" className="nav-link navbar__options">Home</a>
                    </li>
                    <li className="nav-item mr-4">
                        <a href="/faq" className="nav-link navbar__options">FAQ</a>
                    </li>
                    <li className="nav-item mr-3">
                        <div className="dropdown hide-on-responsive topmargin-responsive mt-2">
                            <Link to="#" className="dropbtn navbar__options">Our Services<i className="fa fa-caret-down ml-2" aria-hidden="true"></i></Link>
                            <div className="dropdown-content">
                                <a href="/key" className="dropdown-item" ><center>Key Tag</center></a>
                               
                                <a href="/genie" className="dropdown-item" ><center>Genie Tag</center></a>
                            </div>
                            
                        </div>
                    </li>
                    <li className="nav-item mr-4">
                        <a className="nav-link navbar__options" target="_blank" href="https://tag8.in/tag8-store-new/public/products/genieSmartTag.php">Store</a>
                    </li>
                    <li className="nav-item mr-4">
                        {
                            !isAuth || firstName === '' ?  <a className="nav-link navbar__options" href="/login">Login</a> : <a className="nav-link navbar__options" href={`/dashboard/`+uid}>My Tags</a>
                        }
                    </li>
                    <li className="nav-item mr-4">
                        {
                            !isAuth || firstName === '' ?  <a className="nav-link navbar__options" href="/signup">Signup</a> : 
                            <div className="dropdown hide-on-responsive topmargin-responsive mt-2">
                                <Link to="#" className="dropbtn navbar__options">Welcome {firstName} {lastName}<i className="fa fa-caret-down ml-2" aria-hidden="true"></i></Link>
                                <div className="dropdown-content-1">
                                    <a href="/profile" className="dropdown-item"><center>Profile</center></a>
                                    {localStorage.getItem('logintype') === 'manual' ? <Link to="#" onClick={() => setChangePassword(true)} className="dropdown-item"><center>Change Password</center></Link> : ''}
                                    <a onClick={logout} href="/login" className="dropdown-item"><center>Logout</center></a>
                                </div>
                            </div>
                        }
                    </li>
                </ul>
                <div className="got-genie-div">
                    <div className="got-genie-form" style={{ display: form }}>
                        <input style={{ padding: '-5px' }} placeholder="Owner ID" onKeyUp={(e)=>{e.target.value=e.target.value.toUpperCase()}} onChange={(e) => setOwnerID(e.target.value)}></input>
                        <div className="genie-form-buttons">
                            <button className="no-hover" title={!isAuth ? "Please login to activate tag" : "Activate Tag"} onClick={activateTag} disabled={!isAuth}>Activate Tag</button><button className="no-hover" onClick={reportFound}>Report Found</button>
                        </div>
                    </div>
                    <button className="got-genie1" onClick={() => { setForm(form === 'none' ? 'flex' : 'none'); setNavbar(navbar === null ? '115px' : null) }}><span className="got-genie-top font-weight-bold">Got Dolphin Tracker?</span><br></br> Register or Report</button>
                </div>
                <div className="got-genie-div-1">
                    <button className="got-genie1" onClick={() => { setForm(form === 'none' ? 'flex' : 'none'); }}><span className="got-genie-top font-weight-bold">Got tag8?</span><br></br> Register or Report</button>
                    <div className="got-genie-form" style={{ display: form }}>
                        <input style={{ padding: '-5px' }} placeholder="Owner ID" onKeyUp={(e)=>{e.target.value=e.target.value.toUpperCase()}} onChange={(e) => setOwnerID(e.target.value)}></input>
                        <div className="genie-form-buttons">
                            <button className="no-hover" title={!isAuth ? "Please login to activate tag" : "Activate Tag"} onClick={activateTag} disabled={!isAuth}>Activate Tag</button><button className="no-hover" onClick={reportFound}>Report Found</button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog className="act__dialog" disableBackdropClick open={tagNotFound} onClose={handleNotFound} aria-labelledby="form-dialog-title">
                <DialogContent className="activetag__PopUp">
                    <h1 style={{ margin: "20px", font: "500 20px/32px Roboto,Helvetica Neue,sans-serif", letterSpacing: "normal" }}>Tag Number Not Found</h1>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <button className="active__btns" style={{ margin: "10px 20px", marginBottom: '50px' }} onClick={handleNotFound}>
                            OK
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog className="act__dialog" disableBackdropClick open={alreadyActivated} onClose={handleActivated} aria-labelledby="form-dialog-title">
                <DialogContent className="activetag__PopUp">
                    <h1 style={{ margin: "20px", font: "500 20px/32px Roboto,Helvetica Neue,sans-serif", letterSpacing: "normal" }}>Tag is already activated</h1>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <button className="active__btns" style={{ margin: "10px 20px", marginBottom: '50px'}} onClick={handleActivated}>
                            OK
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog className="dialog_profile__change__pwd" disableBackdropClick maxWidth='xs' open={changePassword} onClose={() => setChangePassword(false)} aria-labelledby="form-dialog-title">
                <DialogContent className="profile__change__pwd">
                    <h1 style={{ margin: "20px", font: "500 20px/32px Roboto,Helvetica Neue,sans-serif", letterSpacing: "normal" }}>Change Password</h1>
                    <div className="mb-4" style={{ display: "flex", flexDirection: "column" }}>
                        <input  className="pl-3 change__pwd_email" style={{ margin: "20px", height: '35px', width:'350px' }} value={email} readOnly></input>
                        <input className="pl-3 change__pwd_pwd" style={{ margin: "20px", height: '35px', width:'350px' }} type={passwordType} placeholder="Password" value={password} onChange={e => {
                            setPassword(e.target.value)
                            const txt = e.target.value;
                            if (txt.length >= 8) {
                                valid('islen');
                            }
                            else {
                                invalid('islen')
                            }

                            if (txt.match(/[A-Z]/) != null) {
                                valid('capital');
                            } else {
                                invalid('capital')
                            }

                            if (txt.match(/[a-z]/) != null) {
                                valid('small');
                            } else {
                                invalid('small')
                            }

                            if (txt.match(/[0-9]/) != null) {
                                valid('isnum');
                            } else {
                                invalid('isnum')
                            }
                        }} required />
                        <div style={{ paddingLeft: '22px', fontSize:'14px' }}>
                            <span style={{ display: characterLength }} id="islen">Password must be atleat 8 characters</span>
                            <span style={{ display: validPassword }} id="issp">Password must contain - </span>
                            <span style={{ display: validPassword }} id="isnum">Atleast one number</span>
                            <span style={{ display: validPassword }} id="capital">Atleast one uppercase letter</span>
                            <span style={{ display: validPassword }} id="small">Atleast one lowercase letter</span>
                        </div>   
                        <div className="ml-3" style={{ display: "flex", alignItems: 'center' }}>
                        <input type="checkbox" style={{ fontSize: "16px", fontWeight: '500', marginTop: "-11px", marginRight: "10px", marginLeft: "10px" }} onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></input><p style={{ fontSize: "16px", fontWeight: '500', paddingTop: "5px" }}>Show Password</p></div>
                        <Button variant="contained" className="verifyOtp-button bg-warning" disabled={submitDisabled} style={{ margin: "10px 80px" }} onClick={updatePassword} >
                            Submit
                        </Button>
                        <Button variant="contained" className="otp-buttons" style={{ margin: "10px 80px" }} onClick={() => {setChangePassword(false); setEmail(''); setPassword('')}}>
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className="AlertSignup" style={{ display: alert }} ><p>{message}</p><button onClick={() => setAlert('none')}>Cancel</button></div>
        </nav>
    );
}

export default Navbar