import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import './updatecards.component.css';
import Button from '@material-ui/core/Button';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import AlarmIcon from '@material-ui/icons/Alarm';
import axios from 'axios';
import File from '../../../assets/attached-file.png';
import Tooltip from '@material-ui/core/Tooltip';

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
let usertoken = readCookie('token');

export default class AllergyUpdate extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            imageUrl:null,
            selectedImage:null,
            allergy_reminder:false,
            tag_number: localStorage.getItem('tag_number'),
            Type:'Allergy',
            allergy_id:this.props.allergy_id,
            Allergy_name : '',
            AllergyDoctor_name : '',
            AllergyDiagnosis_date: '',
            AllergySeverity : '', 
            AllergyNotes: '',
            AllergyTitle : '', 
            AllergyEverynumber:'',
            AllergyEvery : '', 
            AllergyNext : '',
            AllergyRemind_me : '',
            AllergyReminderNotes: '',
            Allergy_Reportname : '',
            Allergy_Reportlocation : '',
            message:'',
            
        }
    }

    join=(t, a, s)=>{
        function format(m) {
           let f = new Intl.DateTimeFormat('en', m);
           return f.format(t);
        }
        return a.map(format).join(s);
      }
    
    month = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}]

    componentDidMount(){
        axios.get('https://dashboard.tag8.co.in/api/pet/getMedicalDetails/'+this.state.tag_number).then((response)=>{
            response.data[0].Allergy.map((elm)=>{
                if(this.state.allergy_id === elm.allergy_id){
                    console.log(elm)
                    this.setState({
                        Allergy_name:elm.Allergy_name,
                        AllergyDoctor_name : elm.AllergyDoctor_name,
                        AllergyDiagnosis_date: this.join(new Date(elm.AllergyDiagnosis_date), this.month, '-'),
                        AllergySeverity: elm.AllergySeverity,
                        AllergyNotes: elm.AllergyNotes,
                        AllergyTitle : elm.AllergyTitle,
                        AllergyNext : elm.AllergyNext ? this.join(new Date(elm.AllergyNext), this.month, '-') : '',
                        AllergyRemind_me : elm.AllergyRemind_me, 
                        AllergyReminderNotes: elm.AllergyReminderNotes,
                        Allergy_Reportname : elm.Allergy_Reportname,
                        Allergy_Reportlocation : elm.Allergy_Reportlocation,
                    })
                }
            })
        }).catch((error)=>{
            console.log(error)
        })
    }

    changeHandler =e=>{
        this.setState({[e.target.name]:e.target.value})
    }

    handleChange=event=> {
        if(event.target.files[0]){
            var sizeinmb = event.target.files[0].size/(1024*1024)
            console.log(sizeinmb)
            if(sizeinmb > 2){
                this.setState({message:'File size should be less than 2 MB'})
            }
            else{
                this.setState({message:''})
                this.setState({
                    selectedImage: event.target.files[0],
                    imageUrl: URL.createObjectURL(event.target.files[0])
                })
            }
        }
    }

    submitHandler=e=>{
        e.preventDefault()
        console.log(this.state)
        axios.post('https://dashboard.tag8.co.in/api/pet/updateMedicalDetails', this.state, {headers: {'Authorization': usertoken}}).then((res)=>{
            console.log(res)
            window.location.reload()
            const fd = new FormData;
            fd.append('image', this.state.selectedImage);   
            fd.append('tag_number', this.state.tag_number);
            fd.append('Type', this.state.Type);
            fd.append('allergy_id', this.state.allergy_id);
            console.log(fd)
            axios.post('https://dashboard.tag8.co.in/api/pet/report-upload',fd,{
                'Accept': 'application/json'
            }).then((response)=>{
                console.log(response)
            }).catch((error)=>{
                console.log(error)
            })
        }).catch((error)=>{
            console.log(error)
            this.props.closeDialog()
        })
    }

    render(){
        const {allergy_reminder, Allergy_name, AllergyDoctor_name, AllergyDiagnosis_date, AllergySeverity,  AllergyNotes, AllergyTitle,  AllergyEverynumber, AllergyEvery,  AllergyNext, AllergyRemind_me, AllergyReminderNotes, Allergy_Reportname, Allergy_Reportlocation} = this.state
        return(
            <div style={{width:'550px', border:'2px solid black'}} className="p-4 outer-med-add-record-div">
                <form onSubmit={this.submitHandler}>
                    <h4>Allergy</h4>
                    <div className="upper-form-update" style={{width:'100%'}}>
                        <div className="row mt-3 first-line-update" style={{display:'flex',flexDirection:'row'}}>
                            <div className="col">
                                <span className="label-petdata">Allergy Name *</span><br/>
                                <input type="textbox" className="input-pet-medical" maxlength="20" name="Allergy_name" required value={Allergy_name} onChange={this.changeHandler} />
                            </div>
                            <div className="col">
                                <span className="label-petdata">Doctor's Name</span><br/>
                                <input type="textbox" className="input-pet-medical" maxlength="25" name="AllergyDoctor_name" value={AllergyDoctor_name} onChange={this.changeHandler}/><br/>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <span className="label-petdata">Diagnosis Date *</span><br/>
                                <input style={{backgroundColor:'white'}} type="date" className="input-pet-medical" name="AllergyDiagnosis_date" required value={AllergyDiagnosis_date} onChange={this.changeHandler} />
                            </div>
                            <div className="col sever">
                                <span className="label-petdata">Severity</span><br/>
                                <select style={{backgroundColor:'white'}} className="input-pet-medical-dropdown" name="AllergySeverity" value={AllergySeverity} onChange={this.changeHandler}>
                                    <option hidden></option>
                                    <option value="RAST 0 - Undetectable">RAST 0 - Undetectable</option>
                                    <option value="RAST 1 - Low">RAST 1 - Low</option>
                                    <option value="RAST 2- Moderate">RAST 2- Moderate</option>
                                    <option value="RAST 3 - High">RAST 3 - High</option>
                                    <option value="RAST 4 - Very High">RAST 4 - Very High</option>
                                    <option value="RAST 5 - Ultra High">RAST 5 - Ultra High</option>
                                    <option value="RAST 6 - Extremely High">RAST 6 - Extremely High</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 mr-3">
                                <span className="label-petdata">Notes</span><br/>
                                <textarea className="txt_notes" rows="2" name="AllergyNotes" style={{width:'95%',border:'2px solid #e4e4e4'}} value={AllergyNotes} onChange={this.changeHandler}></textarea><br/>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col mr-3 med-rem-div">
                                <Button onClick={()=>{this.setState({allergy_reminder:!allergy_reminder})}} className="bg-white" style={{'padding': '0px', 'font-size': '12px', 'color': '#b4b4b4', 'font-weight': '400', textTransform:'none'}}><AddAlertIcon style={{'font-size': '16px'}}/><span className="ml-2">Set Reminder</span></Button><br/>
                                {
                                    allergy_reminder ?
                                    <div className="inner-med-rem-div" style={{width:'350px'}}>
                                        <div className="row ml-1 mr-1 mt-3 p-1 bg-warning">
                                            <p className="ml-2 mb-0 font-weight-bold">Allergy</p>
                                            <Button className="p-0 myexp-btn" style={{marginLeft:'80px'}} onClick={()=>{this.setState({allergy_reminder:!allergy_reminder})}}><ExpandMoreIcon /></Button>
                                        </div>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Reminder Title</p>
                                        <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="AllergyTitle" value={AllergyTitle} onChange={this.changeHandler}/><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Next Appointment Date</p>
                                        <SkipNextIcon className="ml-1"/><input style={{backgroundColor:'white'}} type="date" className="input-pet-detail-nextdate ml-2" name="AllergyNext" value={AllergyNext} onChange={this.changeHandler}/><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Remind Me</p>
                                        <AlarmIcon className="ml-1"/>
                                        <select style={{backgroundColor:'white'}} className="input-pet-detail-nextdate ml-2" name="AllergyRemind_me" value={AllergyRemind_me} onChange={this.changeHandler}>
                                            <option hidden></option>
                                            <option value="On The Same Day">On The Same Day</option>
                                            <option value="1 Day Before">1 Day Before</option>
                                            <option value="2 Days Before">2 Days Before</option>
                                            <option value="3 Days Before">3 Days Before</option>
                                            <option value="15 Days Before">15 Days Before</option>
                                            <option value="1 Month Before">1 Month Before</option>
                                        </select><br/>
                                        <p className="mt-3 ml-2 label-petdata mb-0">Notes</p>
                                        <input type="textbox" className="ml-2 input-pet-detail-notes" maxlength="30" name="AllergyReminderNotes" value={AllergyReminderNotes} onChange={this.changeHandler}/><br/>
                                        <p className="ml-2 mt-1 label-petdata rem-cal-para">*Reminders can be viewed in calendar section.</p><br/>
                                    </div> : ""
                                }
                            </div>
                        </div>
                        <div className="mt-3">
                            <span className="label-petdata">Report</span><br/>
                            <input type="file" onChange={this.handleChange} style={{'padding': '0px', 'height': '100%', 'width': '100%'}} className="input-pet-details-reports"/><br/>
                            <div className="text-left mt-1">{this.state.message}</div>
                            {Allergy_Reportlocation ? <Tooltip title={(Allergy_Reportname)}><a href={Allergy_Reportlocation} style={{'font-size': '14px'}}><img src={File} width="25px" height="25px"/></a></Tooltip> : <span className="darkgray" style={{'font-size': '14px'}}>No documents uploaded</span>}
                        </div>
                    </div>
                    <div className="mt-4 row text-center">
                        <div className="col">
                            <Button type="submit" className="bg-warning" style={{'width': '100%'}}>SUBMIT</Button>
                        </div>
                        <div className="col">
                            <Button type="button" variant="contained" style={{'width': '100%'}} onClick={this.props.closeDialog} className="text-center">Close</Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}